import { atom } from "recoil";

export const toaster = atom({
  key: "toaster",
  default: {
    status: false,
    title: "",
    text: "",
  },
});
