import { atom } from "recoil";

export const aggregatorToggle = atom({
  key: "aggregatorToggle",
  default: false,
});

export const updateAggregatorList = atom({
  key: "updateAggregatorList",
  default: false,
});