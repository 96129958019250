import { Link } from "react-router-dom";
import "../../assets/css/main.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import {
  GetNotificationMessages,
  getNotificationType,
} from "../../utility/notification";
import { requestToggle } from "../../state/atom/request";
import { useSetRecoilState } from "recoil";
import { landDetailsToggle, landSummaryToggle } from "../../state/atom/gis";

interface Notifier {
  firstName: string;
  lastName: string;
}

interface Assignee {
  firstName: string;
  lastName: string;
}

interface ProposalDetails {
  proposalName: string;
}

interface RequestDetails {
  requestName: string;
}

interface Notification {
  notificationType: string;
  createdDateTime: string;
  isRead: boolean;
  notifier: Notifier;
  assignee?: Assignee | undefined;
  proposalDetails?: ProposalDetails | undefined;
  requestDetails: RequestDetails;
}

export const NotificationBox = () => {
  const [notificationData, setNotificationData] = useState<Notification[]>([]);
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  const [notificationId, setNotificatinoID] = useState("");
  const setToogleRequest = useSetRecoilState(requestToggle);
  const setLandSummaryToggle = useSetRecoilState(landSummaryToggle);
  const setLandDetailsToggle = useSetRecoilState(landDetailsToggle);

  const readNotification = (id: any, index: number) => {
    setNotificatinoID(id);
    Http.request(
      "patch",
      `${process.env.REACT_APP_API_URL}notification/${id}`,
      null,
      apiHeaders()
    )
      .then((result: any) => {
        notificationData[index].isRead = true;
        setNotificationData(notificationData);
      })
      .catch((error: any) => {});
  };

  const getNotificationMessage = () => {
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}notification`,
      null,
      apiHeaders()
    )
      .then((result: any) => {
        setNotificationData(result.data.notification);
        const unreadCount: number = result?.data?.notification?.filter(
          (notification: any) => !notification?.isRead
        ).length;
        setNewNotificationsCount(unreadCount);
      })
      .catch((error: any) => {});
  };

  useEffect(() => {
    getNotificationMessage();

    // Call the getNotificationMessage function every 60 seconds
    const intervalId = setInterval(getNotificationMessage, 60000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <Link
        to=""
        className="nav-link  d-flex text-white"
        role="button"
        data-bs-toggle="dropdown"
      >
        <span
          onClick={() => {
            setToogleRequest(false);
            setLandSummaryToggle(false);
            setLandDetailsToggle(false);
          }}
        >
          <i className="material-icons opacity-10 pe-1">notifications</i>
          {newNotificationsCount > 0 && (
            <span
              className="badge bg-danger rounded-circle"
              style={{
                position: "absolute",
                top: "10px",
                right: "95px",
              }}
            >
              {newNotificationsCount}
            </span>
          )}
        </span>
      </Link>

      <ul
        className="dropdown-menu dropdown-menu-end"
        style={{
          width: "400px",
          marginRight: "50px",
        }}
      >
        <Container>
          <Row className=" py-3 notification-header">
            <Col className="notification-heading">
              <li className="menu-title">
                You Have Total <strong>{newNotificationsCount}</strong> Unread
                Notification
              </li>
            </Col>
          </Row>

          <Row>
            <Col className="notification-body">
              {notificationData.map((notification: any, index: number) => (
                <div
                  className="notification-item p-2 pl-2 mb-2 notification-message-box"
                  style={{
                    backgroundColor: notification.isRead
                      ? "transparent"
                      : "#f1f1f1",
                  }}
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <h5>
                    <strong>
                      {getNotificationType(notification.notificationType)}
                    </strong>
                    <p className="text-muted notifier-name">
                      {`by ${notification.notifier.firstName} ${notification.notifier.lastName}`}
                    </p>
                  </h5>
                  <GetNotificationMessages
                    type={notification.notificationType}
                    resourceId={notification.resourceId}
                    requestName={notification.requestDetails?.requestName}
                    proposalID={notification.engReportDetails?.proposalId ?? ""}
                    proposalName={
                      notification.proposalDetails?.proposalName ?? ""
                    }
                    assigneeName={
                      `${notification.assignee?.firstName} ${notification.assignee?.lastName}` ??
                      ""
                    }
                  ></GetNotificationMessages>

                  <h5 className="read-notification-text">
                    {!notification.isRead &&
                      (notification.id === notificationId ? null : (
                        <span
                          className="mark-as-read-button"
                          onClick={() =>
                            readNotification(notification.id, index)
                          }
                        >
                          <i className="fas fa-check mr-2 "></i> Mark as Read
                        </span>
                      ))}
                    <span
                      className="text-muted "
                    >
                      {`🕒 ${moment(notification.createdDateTime)
                        .startOf("minute")
                        .fromNow()}`}
                    </span>
                  </h5>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </ul>
    </>
  );
};
