export const LoginFooter = () => {
  return (
    <footer className="bottom-2 py-2 w-100">
      <div className="container">
        <div className="row align-items-center justify-content-lg-between">
          <div className="col-12 col-md-6 my-auto">
            <div className="copyright text-center text-sm text-white text-lg-start">
              © <script>document.write(new Date().getFullYear())</script>,
              Powered by
              <a
                href="/"
                className="font-weight-bold text-white"
                target="_blank"
              >
                LGEOM
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <ul className="nav nav-footer justify-content-center justify-content-lg-end">
              <li className="nav-item">
                <a href="/" className="nav-link text-white" target="_blank">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a href="/" className="nav-link text-white">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a href="/" className="nav-link text-white">
                  Blog
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/"
                  className="nav-link pe-0 text-white"
                  target="_blank"
                >
                  License
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
