// @ts-nocheck
import React, { useState, useMemo, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { editUserToggle, userId, usersListToggle } from "../../state/atom/user";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { toaster } from "../../state/atom/toaster";
import { updateUserList } from "../../state/atom/user";
import { UserStatus } from "../../shared/user.constant";
import { IUserStatus } from "../../shared/user.interface";
import { listHighlighter } from "../../state/atom/listhighlighter";
import * as Yup from "yup";
import { InfoBox } from "../info-hover";
import { ROLESNAME, ROLESNAME1 } from "../../shared/role.constant";
import { ROLES_TYPE } from "../../shared/role.interface";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { customStyles } from "../../shared/landTypeStyle";
/**
Component displaying edit user form.
*/
export const UserEdit = () => {
  // state and hooks
  const setUserListToggle = useSetRecoilState(usersListToggle);
  const [userEditToggle, setUserEditToggle] = useRecoilState(editUserToggle);
  const userID = useRecoilValue(userId);
  const setToasterStatus = useSetRecoilState(toaster);
  const setUpdateUsersList = useSetRecoilState(updateUserList);
  const [businessUnit, setBusinessUnit] = useState([]);
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const setListHighlighter = useSetRecoilState(listHighlighter);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    employeeId: "",
    businessName: "",
    phoneNumber: "",
    role: "",
    businessUnit: [],
    verificationStatus: "",
  });

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    phoneNumber: Yup.string().test(
      "is-10-digits",
      "Phone Number must be 10 digits",
      (value) => !value || value.toString().length === 10
    ),
    lastName: Yup.string().required("Last Name is required"),
    employeeId: Yup.string().required("Employee ID is required"),
    businessUnit: Yup.array()
      .min(1, "Business Name is required")
      .required("Business Name is required"),
    role: Yup.string().required("Role is required"),
    verificationStatus: Yup.string().required(
      "Verification Status is required"
    ),
  });

  /* const fetchData = useMemo(
    () => async () => {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        employeeId: "",
        phoneNumber: "",
        businessName: "",
        role: "",
        businessUnit: [],
        verificationStatus: "",
      });

      // Make the GET request to get the user details
      Http.request(
        "get",
        `${process.env.REACT_APP_API_URL}users/${userID}`,
        null,
        apiHeaders()
      )
        .then((data) => {
          // Set all user details in input fields
          setFormData({
            firstName: data?.data?.user?.firstName,
            lastName: data?.data?.user?.lastName,
            email: data?.data?.user?.email,
            phoneNumber: data?.data?.user?.phoneNumber,
            employeeId: data?.data?.user?.employeeId,
            businessName: data?.data?.user?.businessName,
            //businessUnit: data?.data?.user?.businessUnitName?.id,
            businessUnit: data?.data?.user?.businessUnitName.map((unit) => {
              return { label: unit.name, value: unit.id };
            }),
            role: data?.data?.user?.role,
            verificationStatus: data?.data?.user?.verificationStatus,
          });
        })
        .catch((err) => {});
    },
    [userID]
  ); */

  /* useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await Http.request(
          "get",
          `${process.env.REACT_APP_API_URL}generic/business-unit`,
          null
        );
        const businessUnit = result.data.businessUnit.map((unit: any) => {
          return {
            value: unit.id,
            label: unit.name,
          };
        });
        setBusinessUnit(businessUnit);
      } catch (error) {
        // Handle or log the error here
      }
    };

    fetchData();
  }, []); */

  useEffect(() => {
    if (userID) {
      Http.request(
        "get",
        `${process.env.REACT_APP_API_URL}generic/business-unit`,
        null
      ).then((result) => {
        const businessUnit = result.data.businessUnit.map((unit: any) => {
          return {
            value: unit.id,
            label: unit.name,
          };
        });
        setBusinessUnit(businessUnit);

        Http.request(
          "get",
          `${process.env.REACT_APP_API_URL}users/${userID}`,
          null,
          apiHeaders()
        )
          .then((data) => {
            // Set all user details in input fields
            setFormData({
              firstName: data?.data?.user?.firstName,
              lastName: data?.data?.user?.lastName,
              email: data?.data?.user?.email,
              phoneNumber: data?.data?.user?.phoneNumber,
              employeeId: data?.data?.user?.employeeId,
              businessName: data?.data?.user?.businessName,
              //businessUnit: data?.data?.user?.businessUnitName?.id,
              businessUnit: data?.data?.user?.businessUnitName.map((unit) => {
                return { label: unit.name, value: unit.id };
              }),
              role: data?.data?.user?.role,
              verificationStatus: data?.data?.user?.verificationStatus,
            });
          })
          .catch((err) => {});
      });
    }
  }, [userID]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSelectChange = (selected) => {
    console.log(selected);
    setFormData((prevData) => ({
      ...prevData,
      businessUnit: selected,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUpdateErrorMessage("");
    //return false;
    const newFormData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      employeeId: formData.employeeId,
      phoneNumber: formData.phoneNumber,
      //businessName: formData.businessName,
      role: formData.role,
      businessUnit: formData.businessUnit.map((unit: any) => {
        return unit.value;
      }),
      verificationStatus: formData.verificationStatus,
    };

    setErrors({});

    // Validate the form data using Yup schema
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        // If validation succeeds, make the PUT request to update the user
        Http.request(
          "PUT",
          `${process.env.REACT_APP_API_URL}users/${userID}`,
          newFormData,
          apiHeaders()
        )
          .then((data: any) => {
            // Show a success notification to the user
            setToasterStatus({
              status: true,
              title: "User",
              text: "User update successfully",
            });
            setUserEditToggle(false);
            setUpdateUsersList(true);
            setUserListToggle(false);
            setListHighlighter(false);
          })
          .catch((err) => {
            // Set an error message if the API request fails
            setUpdateErrorMessage(err.response.data.message);
          });
      })
      .catch((validationErrors: Yup.ValidationError) => {
        // If validation fails, set the error messages in the form
        const errorMessages: { [key: string]: string } = {};
        validationErrors.inner.forEach((error: any) => {
          errorMessages[error.path] = error.message;
        });
        setErrors(errorMessages);
      });
  };

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // prevent the onwheel change
  const numberInputOnWheelPreventChange = (e: any) => {
    e.target.blur();
  };
  return (
    <div className="box">
      <div
        className={
          "new-request-init align-self-end " +
          (!userEditToggle ? "user-mgt-detail-init--collapsed" : "")
        }
      >
        {/* Heading panel */}
        <div
          className="text-left panel-title sticky-heading"
          style={{ background: "#375db1" }}
        >
          <span
            style={{ color: "white", fontSize: "24px", paddingLeft: "40px" }}
          >
            USER DETAILS
          </span>
          <span
            className="close-btn"
            id="user-mgt-detail-close"
            onClick={() => {
              setUserEditToggle(false);
              setListHighlighter(false);
            }}
          >
            &times;
          </span>
        </div>

        <div style={{ overflowY: "auto", maxHeight: "80vh" }}>
          {updateErrorMessage && (
            <div className="text-center mx-2">
              <span className="text-warning">{updateErrorMessage}</span>
            </div>
          )}
          <form className="text-white" onSubmit={handleSubmit}>
            {/* First Name */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputuemail">First Name</label>
              <input
                type="text"
                className={`form-control ${
                  errors.firstName ? "is-invalid" : ""
                }`}
                id="firstName"
                placeholder="for example Jon"
                value={formData.firstName}
                onChange={handleInputChange}
              />
              {errors.firstName && (
                <div className="text-warning">{errors.firstName}</div>
              )}
            </div>

            {/* Last Name */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputuemail">Last Name</label>
              <input
                type="text"
                className={`form-control ${
                  errors.lastName ? "is-invalid" : ""
                }`}
                id="lastName"
                placeholder="for example Doe"
                value={formData.lastName}
                onChange={handleInputChange}
              />
              {errors.lastName && (
                <div className="text-warning">{errors.lastName}</div>
              )}
            </div>

            <div className="form-group px-3 py-2">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="number"
                className={`form-control ${
                  errors.lastName ? "is-invalid" : ""
                }`}
                onChange={handleInputChange}
                id="phoneNumber"
                value={formData.phoneNumber}
                onWheel={numberInputOnWheelPreventChange}
                placeholder="for example 9588956898"
              />

              {errors.phoneNumber && (
                <div className="text-warning">{errors.phoneNumber}</div>
              )}
            </div>

            {/* User Email */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputuemail">User Email</label>&nbsp;
              <InfoBox data={"You can't change email."}></InfoBox>
              <input
                disabled
                type="text"
                className="form-control"
                id="email"
                placeholder="for example jon@gmail.com"
                value={formData.email}
                readOnly
              />
            </div>

            {/* Employee Id */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputUiDtype">Employee Id</label>
              <input
                type="text"
                className={`form-control ${
                  errors.employeeId ? "is-invalid" : ""
                }`}
                id="employeeId"
                placeholder="for example AEL-GM-1234"
                value={formData.employeeId}
                onChange={handleInputChange}
              />
              {errors.employeeId && (
                <div className="text-warning">{errors.employeeId}</div>
              )}
            </div>

            {/* Business */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputcId">Business</label>
              {/* <select
                className={`form-select ${
                  errors.businessUnit ? "is-invalid" : ""
                }`}
                id="businessUnit"
                value={formData.businessUnit}
                onChange={handleInputChange}
              >
                <option value="">---Please Select Business---</option>
                {businessUnit.map((unit: Business, index) => {
                  return (
                    <option key={unit.id} value={unit.id}>
                      {unit.name}
                    </option>
                  );
                })}
              </select> */}

              <Select
                isMulti
                options={businessUnit}
                value={formData.businessUnit}
                //defaultValue={[{value:1, label:"Port"}]}
                className="basic-multi-select"
                placeholder="Select Business Name"
                classNamePrefix="select"
                styles={customStyles}
                onChange={handleSelectChange}
              />
              {errors.businessUnit && (
                <div className="text-warning">{errors.businessUnit}</div>
              )}
            </div>

            {/* Role */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputcId">Role</label>
              <select
                className={`form-select ${errors.role ? "is-invalid" : ""}`}
                id="role"
                value={formData.role}
                onChange={handleInputChange}
              >
                <option value="">---Please Select Role---</option>
                {Object.keys(ROLESNAME1).map((unit, index) => {
                  return (
                    <option key={unit} value={unit}>
                      {ROLESNAME1[unit as keyof ROLES_TYPE]}
                    </option>
                  );
                })}
              </select>
              {errors.role && <div className="text-warning">{errors.role}</div>}
            </div>

            {/* Status */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputcId">Status</label>
              <select
                className={`form-select ${
                  errors.verificationStatus ? "is-invalid" : ""
                }`}
                id="verificationStatus"
                value={formData.verificationStatus}
                onChange={handleInputChange}
              >
                {Object.keys(UserStatus).map((status) => {
                  return (
                    <option key={status} value={status}>
                      {UserStatus[status as keyof IUserStatus]}
                    </option>
                  );
                })}
              </select>
              {errors.verificationStatus && (
                <div className="text-warning">{errors.verificationStatus}</div>
              )}
            </div>

            {/* Update button */}
            <div className="form-group px-3 py-2 text-white">
              <button
                type="submit"
                className="btn bg-gradient-primary-login btn1 w-100"
              >
                UPDATE
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
