import { useRecoilState, useSetRecoilState } from "recoil";
import { Business, Company, State } from "../../shared/generic.interface";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { mapCenter } from "../../state/atom/generic";
import {
  forceRender,
  misReport,
  msiFilterToggle,
} from "../../state/atom/report";
import { gisFeatureLayerFilter, misSummaryFilter } from "../../state/atom/gis";
import { JwtPayLoad } from "../../shared/jwt.interface";
import { getUserDetails } from "../../utility/jwt";
import { ROLES } from "../../shared/role.constant";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { capitalizeString } from "../../shared/generic.constant";
interface MisFilterFormInputs {
  businessName: string;
  companyName: string;
  state: string;
  district: string;
  requestId: string;
  proposalId: string;
  city: string;
  village: string;
  site: string;
  surveyNo: string;
}
export const MisFilter = () => {
  const { user }: JwtPayLoad = getUserDetails();
  let [gisFeatureLayerFilterData, setGisFeatureLayerFilter] = useRecoilState(
    gisFeatureLayerFilter
  );

  let [misSummaryFilterData, setMisSummaryFilter] =
    useRecoilState(misSummaryFilter);
  const [misReportData, setMisReportData] = useRecoilState(misReport);
  const [misReportDataClone, setMisReportDataClone] = useState<any>([]);
  const [misReportFilter, setMisReportFilter] = useState<any>([]);
  const setForceRender = useSetRecoilState(forceRender);
  const [states, setState] = useState<State[]>([]);
  const [surveys, setSurvey] = useState<State[]>([]);
  const [sites, setSite] = useState<State[]>([]);
  const [business, setBusiness] = useState<Business[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [requests, setRequest] = useState<any>([]);
  const [proposals, setProposals] = useState<any>([]);
  const isStateUpdated = useRef(false);
  const [msiFiltersToggle, setMSIFiltersToggle] =
    useRecoilState(msiFilterToggle);
  const validationSchema = Yup.object().shape({
    businessName: Yup.string().notRequired(),
    companyName: Yup.string().notRequired(),
    state: Yup.string().notRequired(),
    village: Yup.string().notRequired(),
    requestId: Yup.string().notRequired(),
    proposalId: Yup.string().notRequired(),
    site: Yup.string().notRequired(),
    surveyNo: Yup.string().notRequired(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<MisFilterFormInputs>({
    resolver: yupResolver(validationSchema),
  });
  const businessName = watch("businessName");
  const companyName = watch("companyName");
  const state = watch("state");
  const site = watch("site");
  const surveyNo = watch("surveyNo");
  const requestId = watch("requestId");
  const proposalId = watch("proposalId");
  const [gisFilterData, setGisFilterData] = useState<any>([]);
  const [gisFilterDataClone, setGisFilterDataClone] = useState<any>([]);
  useEffect(() => {
    if (!isStateUpdated.current && misReportData?.data?.landRequest.length) {
      setMisReportDataClone({ ...misReportData });
      setMisReportFilter({ ...misReportData });
      //createFilterData(misReportData?.data?.landRequest);
      //@ts-ignore
      isStateUpdated.current = true;
    }
  }, [isStateUpdated, misReportData]);

  useEffect(() => {
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}land-parcel/filter-data`,
      null,
      apiHeaders()
    )
      .then((result: any) => {
        setGisFilterData(result.data.request);
        setGisFilterDataClone(result.data.request);
        createFilterData(result.data.request);
      })
      .catch((error: any) => {});
  }, [setGisFilterData]);

  const onSubmit = (data: MisFilterFormInputs) => {
    /* let filterRequest: any = JSON.parse(
      JSON.stringify(misReportDataClone.data.landRequest)
    );
    

    
    if (data.businessName) {
      let parcel = [];
      for (const request of filterRequest) {
        parcel = request.landParcel.filter((parcel: any) => {
          return Number(parcel.businessName) === Number(data.businessName);
        });
        if (parcel.length) {
          request.landParcel = [...parcel];
        } else {
          request.landParcel = [];
        }
      }
    }

    if (data.state) {
      let parcel = [];
      for (const request of filterRequest) {
        parcel = request.landParcel.filter((parcel: any) => {
          return Number(parcel.state) === Number(data.state);
        });
        if (parcel.length) {
          request.landParcel = [...parcel];
        } else {
          request.landParcel = [];
        }
      }
    }

    if (data.companyName) {
      let parcel = [];
      for (const request of filterRequest) {
        parcel = request.landParcel.filter((parcel: any) => {
          return Number(parcel.companyName) === Number(data.companyName);
        });
        if (parcel.length) {
          request.landParcel = [...parcel];
        } else {
          request.landParcel = [];
        }
      }
    }

    if (data.site) {
      let parcel = [];
      for (const request of filterRequest) {
        parcel = request.landParcel.filter((parcel: any) => {
          return parcel.site === data.site;
        });
        if (parcel.length) {
          request.landParcel = [...parcel];
        } else {
          request.landParcel = [];
        }
      }
    }

    if (data.surveyNo) {
      let parcel = [];
      for (const request of filterRequest) {
        parcel = request.landParcel.filter((parcel: any) => {
          return parcel.surveyNo === data.surveyNo;
        });
        if (parcel.length) {
          request.landParcel = [...parcel];
        } else {
          request.landParcel = [];
        }
      }
    } */

    setForceRender(new Date().toString());
    setGisFeatureLayerFilter(gisFeatureLayerFilterData);
    /* setMisReportData({
      data: {
        landRequest: filterRequest,
      },
    }); */
  };

  const [appliedFilters, setAppliedFilters] = useState({
    business: "",
    state: "",
    site: "",
    company: "",
  });

  const filterData = (e: any, field: string) => {
    let filterData: any = JSON.parse(JSON.stringify(gisFilterDataClone));
    if (e.target.value) {
      let filters = { ...appliedFilters };
      if (field === "business") {
        filters = { ...appliedFilters, business: e.target.value };
        setAppliedFilters(filters);
      }
      if (field === "state") {
        filters = { ...appliedFilters, state: e.target.value };
        setAppliedFilters(filters);
      }
      if (field === "site") {
        filters = { ...appliedFilters, site: e.target.value };
        setAppliedFilters(filters);
      }

      if (field === "company") {
        filters = { ...appliedFilters, company: e.target.value };
        setAppliedFilters(filters);
      }

      if (filters.business) {
        filterData = filterData.filter((request: any) => {
          return Number(request?.business?.id) === Number(filters.business);
        });
        gisFeatureLayerFilterData.BusinessName = filters.business;
        createFilterData(filterData, "business");
        setValue("businessName", filters.business);
      }

      if (filters.company) {
        filterData = filterData.filter((request: any) => {
          return Number(request?.company?.id) === Number(filters.company);
        });
        gisFeatureLayerFilterData.CompanyName = filters.company;
        createFilterData(filterData, "company");
        setValue("companyName", filters.company);
      }

      if (filters.state) {
        filterData = filterData.filter((request: any) => {
          return Number(request?.state?.id) === Number(filters.state);
        });
        gisFeatureLayerFilterData.State = filters.state;
        createFilterData(filterData, "state");
        setValue("state", filters.state);
      }

      if (filters.site) {
        filterData = filterData.filter((request: any) => {
          return request.site === filters.site;
        });
        gisFeatureLayerFilterData.Site = filters.site;
        createFilterData(filterData, "site");
        setValue("site", filters.site);
      }

      if (filters.business) {
        setMisSummaryFilter((prev: any) => {
          return {
            ...prev,
            BusinessName: filters.business,
          };
        });
      }
      if (filters.site) {
        setMisSummaryFilter((prev: any) => {
          return {
            ...prev,
            Site: filters.site,
          };
        });
      }
      setGisFilterData(filterData);
    }
  };

  const createFilterData = (data: any, skip: string = "") => {
    const businessData = [];
    if (skip !== "business") {
      for (const parcel of data) {
        if (parcel?.business) {
          const index = businessData.findIndex(
            (business) => business?.id === parcel?.business?.id
          );
          if (index === -1) {
            businessData.push(parcel.business);
          }
        }
      }
      setBusiness(businessData);
    }
    if (skip !== "company") {
      const companies = [];
      for (const parcel of data) {
        if (parcel?.company) {
          const index = companies.findIndex(
            (company) => company?.id === parcel?.company?.id
          );
          if (index === -1) {
            companies.push(parcel.company);
          }
        }
      }
      //console.log("companies", companies);
      setCompanies(companies);
    }

    if (skip !== "state") {
      const states = [];
      for (const parcel of data) {
        if (parcel?.state) {
          const index = states.findIndex(
            (state) => state.id === parcel?.state?.id
          );
          if (index === -1) {
            states.push(parcel.state);
          }
        }
      }
      setState(states);
    }

    if (skip !== "site") {
      const sites = [];
      for (const parcel of data) {
        const index = sites.findIndex((site) => site === parcel.site);
        if (index === -1) {
          sites.push(parcel.site);
        }
      }
      setSite(sites);
    }
  };

  useEffect(() => {
    return () => {
      setAppliedFilters({
        business: "",
        state: "",
        site: "",
        company: "",
      });
    };
  }, []);

  return (
    <>
      <div className="box">
        <div
          className={
            "new-request-corner-btn gis-corner-btn align-self-end " +
            (!msiFiltersToggle ? "new-request-corner-btn--collapsed" : "")
          }
          onClick={() => {
            setMSIFiltersToggle(true);
          }}
          id="new-request-corner-btn"
        >
          <Link
            to=""
            className="nav-link font-weight-bold d-flex text-white corner-btn"
          >
            <i className="material-icons opacity-10 pe-1">checklist</i>
            <span className="d-sm-inline d-none dashboard-icons p-1">
              MIS Filter
            </span>
          </Link>
        </div>
        <div
          className={
            "mis-filter-popup " +
            (!msiFiltersToggle ? "request-detail-init--collapsed" : "")
          }
        >
          <div
            className="text-right panel-title sticky-heading"
            style={{ background: "#375db1" }}
          >
            <span style={{ color: "white", fontSize: "24px" }}>
              MIS Filters
            </span>
            <span
              className="close-btn"
              id="request-detail-close"
              onClick={() => {
                setMSIFiltersToggle(false);
              }}
            >
              &times;
            </span>
          </div>
          <div style={{ overflowY: "auto", maxHeight: "75vh" }}>
            <h6 className="mb-0 panel-header">
              Select the data for apply filter
            </h6>
            <form className="text-white" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group px-3 py-2">
                <label htmlFor="inputBusiness">Business Name</label>
                <select
                  className="form-select"
                  aria-label="Default Business Name"
                  {...register("businessName")}
                  onChange={(event) => filterData(event, "business")}
                  value={businessName}
                >
                  <option value="">---Business Name ---</option>
                  {business
                    .sort((a: Business, b: Business) =>
                      a.name.localeCompare(b.name)
                    )
                    .map((unit: any, index: any) => {
                      return <option value={unit.id}>{unit.name}</option>;
                    })}
                </select>
              </div>
              <div className="form-group px-3 py-2">
                <label htmlFor="inputBusiness">Company Name</label>
                <select
                  className="form-select"
                  aria-label="Default Company Name"
                  {...register("companyName")}
                  onChange={(event) => filterData(event, "company")}
                  value={companyName}
                >
                  <option value="">---Select Company Name---</option>
                  {companies
                    .sort((a: Company, b: Company) =>
                      a.name.localeCompare(b.name)
                    )
                    .map((company: any, index: any) => {
                      return <option value={company.id}>{company.name}</option>;
                    })}
                </select>
              </div>
              <div className="form-group px-3 py-2">
                <label htmlFor="select-state">Select State</label>
                <select
                  className="form-select"
                  {...register("state")}
                  onChange={(event) => filterData(event, "state")}
                  value={state}
                >
                  <option value="">---Please Select State---</option>
                  {states
                    .sort((a: State, b: State) =>
                      a.stateName.localeCompare(b.stateName)
                    )
                    .map((state: any, index: any) => {
                      return (
                        <option value={state.id}>
                          {capitalizeString(state.stateName)}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="form-group px-3 py-2">
                <label htmlFor="select-city">Select Site</label>
                <select
                  className="form-control"
                  aria-label="Default City"
                  {...register("site")}
                  onChange={(event) => filterData(event, "site")}
                  value={site}
                >
                  <option value="">---Select Site---</option>
                  {sites
                    .sort((a: any, b: any) => a.localeCompare(b))
                    .map((site: any, index: any) => {
                      return <option value={site}>{site}</option>;
                    })}
                </select>
              </div>

              <div
                className="form-group d-flex  mt-3 mb-3"
                style={{ justifyContent: "space-evenly" }}
              >
                <button
                  type="button"
                  onClick={() => {
                    reset();
                    setMisReportData(misReportDataClone);
                    setMisReportFilter(misReportDataClone);
                    createFilterData(gisFilterDataClone);
                    setForceRender(new Date().toString());
                    setGisFeatureLayerFilter({
                      SurveyNo: "",
                      Village: "",
                      BusinessName: "",
                      CompanyName: "",
                      Site: "",
                      State: "",
                      Request_Id: "",
                      abu_or_lem_user_id:
                        user.role === ROLES.abu_spoc ? user.userId : "",
                      le_spoc_user_id:
                        user.role === ROLES.le_spoc ? user.userId : "",
                      le_dh_user_id:
                        user.role === ROLES.le_dh ? user.userId : "",
                    });
                    setMisSummaryFilter({
                      BusinessName: "",
                      Site: "",
                    });
                    setAppliedFilters({
                      business: "",
                      state: "",
                      site: "",
                      company: "",
                    });
                  }}
                  className="btn bg-gradient-primary-login  btn1"
                >
                  Reset FILTER
                </button>
                <button
                  type="submit"
                  className="btn bg-gradient-primary-login btn1"
                >
                  APPLY FILTER
                </button>
                {/* <ExportPdf key="pdf1"></ExportPdf> */}
              </div>
            </form>
          </div>
        </div>
        {/* <div className="box-inner" style={{ bottom: "246px", right: "394px" }}>
          <a
            href="/#"
            className="nav-link font-weight-bold d-flex text-white corner-btn"
            id="request-detail-btn"
          >
            <i className="material-icons opacity-10 pe-1">checklist</i>
            <span className="d-sm-inline d-none dashboard-icons">
              GIS Filters
            </span>
          </a>
        </div> */}
      </div>
    </>
  );
};
