// imports
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  kmlData,
  proposalDetailsToggle,
  proposalId,
  updateProposalList,
} from "../../state/atom/proposal";
import { useEffect, useMemo, useState } from "react";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { LandType1, Proposal_Type } from "../../shared/generic.constant";
import { ProposalType } from "../../shared/generic.interface";
import { checkPermission } from "../../utility/permission";
import { getUserDetails } from "../../utility/jwt";
import { JwtPayLoad } from "../../shared/jwt.interface";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { User } from "../../shared/user.interface";
import Select from "react-select";
import { toaster } from "../../state/atom/toaster";
import { mapCenter } from "../../state/atom/generic";
import { listHighlighter } from "../../state/atom/listhighlighter";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";

// interfaces
interface AssignProposalFormInputs {
  userId: {
    label: string;
    value: string;
    firstName: string;
    lastName: string;
  };
  comment: string;
  status: string;
}

// Component displaying the proposal details.
export const ProposalDetails = () => {
  // state and hooks
  const [loading, setLoading] = useState(false);
  const [options, setUserOption] = useState([]);
  const setMapCenter = useSetRecoilState(mapCenter);

  // validation schema
  const validationSchema = Yup.object().shape({
    userId: Yup.object({
      label: Yup.string(),
      value: Yup.string(),
    }).when("status", {
      is: (val: string) => val === "forwarded",
      then: (validationSchema) => validationSchema.required("Select user"),
      otherwise: (validationSchema) => validationSchema.notRequired(),
    }),
    status: Yup.string().required("Select Status"),
    comment: Yup.string().when("status", {
      is: (val: string) => val === "accepted" || val === "rejected",
      then: (validationSchema) => validationSchema.required("Enter comment"),
      otherwise: (validationSchema) => validationSchema.notRequired(),
    }),
  });

  const {
    control,
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<AssignProposalFormInputs>({
    resolver: yupResolver(validationSchema),
  });

  const [proDetailsToggle, setProposalDetailsToggle] = useRecoilState(
    proposalDetailsToggle
  );
  const { user }: JwtPayLoad = getUserDetails();
  const status = watch("status");
  const [propId, setProposalId] = useRecoilState(proposalId);
  const setToasterStatus = useSetRecoilState(toaster);
  const [kmlDataCollection, setKmlData] = useRecoilState(kmlData);
  const [proposalData, setProposalData] = useState({
    proposalId: "",
    proposalName: "",
    updatedDateTime: "",
    proposalBy: "",
    proposalType: "",
    offeredArea: 0,
    landType: [],
    offeredRate: 0,
    otherDetails: "",
    proposalStatus: "",
    locationFile: "",
    location: "",
    locationLat: 0,
    locationLong: 0,
    noOfPropertyOwner: 0,
    isDeviated: false,
    isInline: false,
    distanceFromCity: 0,
    distanceFromRail: 0,
    circleRatePerAcre: 0,
    distanceFromAirport: 0,
    distanceFromRoad: 0,
    inLineComment: "",
    request: {
      businessUnitName: {
        id: 0,
      },
    },
    deviatedComment: "",
    assignedTo2: {
      id: "",
      firstName: "",
      lastName: "",
    },
    proposalAssignedComment: "",
    aggregatorId2: {
      id: "",
      firstName: "",
      lastName: "",
    },
    circleRateFiles: []
  });
  const [showFileList, setshowFileList] = useState(false);
  const [fileTypeName, setFileTypeName] = useState("");
  const setListHighLight = useSetRecoilState(listHighlighter);
  const setUpdateProposalList = useSetRecoilState(updateProposalList);

  const fetchData = useMemo(
    () => async () => {
      // Make the GET request for getting the proposal details
      Http.request(
        "get",
        `${process.env.REACT_APP_API_URL}proposal/${propId}/details`,
        null,
        apiHeaders()
      )
        .then((res) => {
          setProposalData(res.data.proposal);
          if (res.data.proposal.kmlData) {
            const kmlData = JSON.parse(res.data.proposal.kmlData);
            const lon =
              kmlData.features[0].geometry.coordinates.length === 1
                ? kmlData.features[0].geometry.coordinates[0][0][0]
                : kmlData.features[0].geometry.coordinates[0];
            const lat =
              kmlData.features[0].geometry.coordinates.length === 1
                ? kmlData.features[0].geometry.coordinates[0][0][1]
                : kmlData.features[0].geometry.coordinates[1];
            setMapCenter({
              zoom: 10,
              isShow: false,
              center: [lon, lat],
            });

            setKmlData(kmlData);
          } else {
            setKmlData({});
          }
        })
        .catch((err) => {});

      // Make the Get request for get the user
      Http.request(
        "get",
        `${process.env.REACT_APP_API_URL}users/${proposalData?.request?.businessUnitName?.id}/get-user-abu_eng`,
        null,
        apiHeaders()
      )
        .then((result) => {
          const option = result.data.user.map((user: User) => ({
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
          }));
          setUserOption(option);
        })
        .catch((error) => {
          setUserOption([]);
        });
    },
    [
      propId,
      proposalData?.request?.businessUnitName?.id,
      setKmlData,
      setMapCenter,
    ]
  );

  // The effect runs whenever the propId dependency changes
  useEffect(() => {
    if (propId) {
      fetchData();
    }
  }, [fetchData, propId, proposalData?.request?.businessUnitName?.id]);

  /**
  Handles the form submission when the assign proposal request clicks the accept button.
  This function performs an API call to assign proposal.
  @param {AssignProposalFormInputs} data - The data representing the all the value of the inputs fields.
*/
  const onSubmit = (data: AssignProposalFormInputs) => {
    const request = {
      proposalAssignmentComment: data.comment || "",
      userId: data.status === "forwarded" ? data?.userId?.value : "",
      proposalStatus: data.status,
    };
    setLoading(true);
    Http.request(
      "patch",
      `${process.env.REACT_APP_API_URL}proposal/${propId}`,
      request,
      apiHeaders()
    )
      .then((res) => {
        setUpdateProposalList(true);
        // Show a success notification to the user
        setToasterStatus({
          status: true,
          title: "Proposal",
          text:
            data.status === "accepted"
              ? "Proposal assigned successfully"
              : data.status === "rejected"
              ? "Proposal rejected successfully"
              : "Proposal forwarded successfully",
        });
        proposalData.assignedTo2 = {
          id: data?.userId?.value,
          firstName: data?.userId?.firstName,
          lastName: data?.userId?.lastName,
        };
        proposalData.proposalAssignedComment = data.comment || "";
        proposalData.proposalStatus = data.status;
        setProposalData(proposalData);
        setLoading(false);
        reset();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const customStyles = {
    option: (base: any, state: any) => {
      return {
        ...base,
        backgroundColor: state.isFocused
          ? "rgba(71, 160, 231, 0.85)"
          : "rgb(55, 93, 177)",
      };
    },
  };

  const getStatus = (status: string) => {
    if (status === "pending") {
      return "Pending";
    } else if (status === "accepted") {
      return "Accepted";
    } else if (status === "rejected") {
      return "Rejected";
    } else {
      return "Forward";
    }
  };

  const viewFile = (fileType: string) => {
    setshowFileList(true);
    setFileTypeName(fileType);
  };
  return (
    <><div
    className="modal show"
    style={{ display: "block", position: "initial" }}
  >
    <Modal
      show={showFileList}
      onHide={() => {
        setshowFileList(false);
      }}
      style={{ zIndex: 10000 }}
    >
      <Modal.Header closeButton className="toaster-header ">
        <Modal.Title>
          {fileTypeName === "rate" && "Circle Rate Files"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="file-list-view">
          {fileTypeName === "rate" &&
            proposalData.circleRateFiles.length > 0 &&
            proposalData.circleRateFiles.map((file: any, index: number) => {
              return (
                <div className="file-list-item">
                  {file.url && (
                    <>
                      <Link
                        target="_blank"
                        title="View File"
                        to={file.url}
                        role="button"
                        className="view-file"
                      >
                        {file.url.split("/").filter(Boolean).pop()}
                      </Link>
                    </>
                  )}
                </div>
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setshowFileList(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
      <div className="box">
        <div
          className={
            "proposal-detail-init align-self-end " +
            (!proDetailsToggle ? "proposal-detail-init--collapsed" : "")
          }
        >
          {/* Panel Heading */}
          <div
            className="text-left panel-title"
            style={{ background: "#375db1" }}
          >
            <span
              style={{ color: "white", fontSize: "24px", paddingLeft: "40px" }}
            >
              Proposal Detail
            </span>
            <span
              className="close-btn"
              id="proposal-detail-close"
              onClick={() => {
                setProposalDetailsToggle(false);
                setProposalId("");
                setListHighLight(false);
                setKmlData({});
              }}
            >
              &times;
            </span>
          </div>
          <div style={{ overflowY: "auto", maxHeight: "85vh" }}>
            <h6 className="mb-0 panel-header">
              Proposal Status: {getStatus(proposalData?.proposalStatus)}
            </h6>

            {/* Whole information of the component */}
            <table className="table">
              <tbody>
                <tr>
                  <th>Last Update Date Time</th>
                  <td>
                    {moment(proposalData?.updatedDateTime).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Proposal ID</th>
                  <td>{proposalData?.proposalId}</td>
                </tr>
                <tr>
                  <th>Proposal Name</th>
                  <td>{proposalData?.proposalName}</td>
                </tr>

                <tr>
                  <th>Aggregator/Landbank</th>
                  <td>
                    {
                      Proposal_Type[
                        proposalData?.proposalType as keyof ProposalType
                      ]
                    }
                  </td>
                </tr>

                {proposalData?.proposalType === "aggregator" && (
                  <tr>
                    <th>Aggregator Name</th>
                    <td>
                      {proposalData?.aggregatorId2?.firstName}{" "}
                      {proposalData?.aggregatorId2?.lastName}
                    </td>
                  </tr>
                )}

                <tr>
                  <th>Area Offered (Acre)</th>
                  <td>{proposalData?.offeredArea}</td>
                </tr>

                <tr>
                  <th>Type of Land</th>
                  <td>
                    {proposalData?.landType
                      ?.map(
                        (value) =>
                          LandType1.find((entry) => entry.value === value)
                            ?.label
                      )
                      .filter((label) => label)
                      .join(", ")}
                  </td>
                </tr>

                <tr>
                  <th>Nos of Owners in Property</th>
                  <td>{proposalData?.noOfPropertyOwner}</td>
                </tr>

                <tr>
                  <th>Offered rate (Lakhs per acre)</th>
                  <td>{proposalData?.offeredRate}</td>
                </tr>
                <tr>
                  <th>Circle Rate (Lakhs per acre)</th>
                  <td>{proposalData?.circleRatePerAcre}</td>
                </tr>
                <tr>
                  <th>Circle Rate DOC</th>
                  <td>{proposalData?.circleRateFiles?.length > 0 ? (
                      <span
                      className="a-link-with-underline"
                      role="button"
                      onClick={() => viewFile("rate")}
                    >
                      View Files
                    </span>
                    ) : (
                      ""
                    )}</td>
                </tr>

                <tr>
                  <th>In line with requirement</th>
                  <td>{proposalData?.isInline ? "Yes" : "No"}</td>
                </tr>

                {proposalData?.inLineComment && (
                  <tr>
                    <th>In line with requirement comment</th>
                    <td>{proposalData?.inLineComment}</td>
                  </tr>
                )}

                <tr>
                  <th>Deviation from requirement </th>
                  <td>{proposalData?.isDeviated ? "Yes" : "No"}</td>
                </tr>

                {proposalData?.deviatedComment && (
                  <tr>
                    <th>Deviation from requirement comment</th>
                    <td>{proposalData?.deviatedComment}</td>
                  </tr>
                )}

                <tr>
                  <th>Distance From Road (In KM)</th>
                  <td>{proposalData?.distanceFromRoad}</td>
                </tr>

                <tr>
                  <th>Distance From Rail (In KM)</th>
                  <td>{proposalData?.distanceFromRail}</td>
                </tr>

                <tr>
                  <th>Distance From City (In KM)</th>
                  <td>{proposalData?.distanceFromCity}</td>
                </tr>

                <tr>
                  <th>Distance From Airport (In KM)</th>
                  <td>{proposalData?.distanceFromAirport}</td>
                </tr>

                <tr>
                  <th>Other Details</th>
                  <td>{proposalData?.otherDetails}</td>
                </tr>

                <tr>
                  <th>KML File</th>
                  <td>
                    {proposalData?.locationFile ? (
                      <Link
                        target="_blank"
                        style={{ color: "#fff" }}
                        to={proposalData?.locationFile}
                      >
                        View
                      </Link>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Condition for if the proposal status is not 
            pending then show this part */}
            {
              <>
                <h6 className="text-uppercase text-white font-weight-bolder mt-1 ps-2 panel-sub-title">
                  Proposal Status
                </h6>
                <table className="table">
                  <tbody>
                    <tr>
                      <th>Status</th>
                      <td>{getStatus(proposalData?.proposalStatus)}</td>
                    </tr>
                    <tr>
                      <th>Engineer Name</th>
                      <td>
                        {proposalData?.assignedTo2?.firstName}{" "}
                        {proposalData?.assignedTo2?.lastName}
                      </td>
                    </tr>
                    <tr>
                      <th>Comment</th>
                      <td>{proposalData?.proposalAssignedComment}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            }

            {/* Condition for if the proposal status is pending then show this part */}
            {checkPermission(user?.role, "proposal", "can_assign") &&
              (proposalData?.proposalStatus === "pending" ||
                proposalData?.proposalStatus === "forwarded") && (
                <>
                  <h6 className="text-uppercase text-white font-weight-bolder mt-1 ps-2 panel-sub-title">
                    Manage Proposal Status
                  </h6>

                  {/* Form for assign the user */}
                  <form
                    className="text-white"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="tab-content px-2" id="myTabContent">
                      <div
                        className="tab-pane active"
                        id="accept"
                        role="tabpanel"
                        aria-labelledby="accept-tab"
                      >
                        <div className="form-group px-3 py-2">
                          <label htmlFor="inputSRequest">Select Status*</label>
                          <select
                            className="form-select"
                            aria-label="Default select status"
                            {...register("status")}
                          >
                            <option value="">
                              ---Please Select Status Name---
                            </option>
                            {!proposalData?.assignedTo2?.id && (
                              <option value="forwarded">Forward</option>
                            )}

                            <option value="accepted">Accept</option>
                            <option value="rejected">Reject</option>
                          </select>

                          <br></br>
                          {errors.status && (
                            <span className="text-warning">
                              {errors.status.message}
                            </span>
                          )}
                        </div>

                        {status === "forwarded" && (
                          <div className="form-group px-3 py-2">
                            <label htmlFor="inputuemail">
                              Assignment to User*
                            </label>
                            <Controller
                              name={"userId"}
                              control={control}
                              render={({ field }) => {
                                return (
                                  <Select
                                    isClearable
                                    {...field}
                                    options={options}
                                    styles={customStyles}
                                  />
                                );
                              }}
                            />

                            <div>
                              {errors.userId && (
                                <span className="text-warning">
                                  {errors.userId?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        )}

                        {status !== "forwarded" && (
                          <div className="form-group px-3 py-2 text-white">
                            <label htmlFor="commentAccpt">
                              Comment on Accept or Reject
                            </label>
                            <textarea
                              className="form-control"
                              id="commentProp"
                              {...register("comment")}
                            ></textarea>
                            <div>
                              {errors.comment && (
                                <span className="text-warning">
                                  {errors.comment.message}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group px-3 py-2 text-white">
                      <button
                        type="submit"
                        className="btn bg-gradient-primary-login w-100  btn1"
                        disabled={loading}
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                </>
              )}
          </div>
        </div>
      </div>
    </>
  );
};
