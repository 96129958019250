// imports
import { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  requestDetailsToggle,
  requestId,
  requestStatus,
  updateRequestList,
} from "../../state/atom/request";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import {
  LandType,
  LandType1,
  RoleName,
  RoleNameForFindUser,
} from "../../shared/generic.constant";
import { Land, Role, RoleForFindUsers } from "../../shared/generic.interface";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getUserDetails } from "../../utility/jwt";
import moment from "moment";
import { JwtPayLoad } from "../../shared/jwt.interface";
import { checkPermission } from "../../utility/permission";
import { User } from "../../shared/user.interface";
import Select from "react-select";
import { toaster } from "../../state/atom/toaster";
import { listHighlighter } from "../../state/atom/listhighlighter";
import { ROLES } from "../../shared/role.constant";
import { LoadinSkeleton } from "../loading-skeleton";
import { RequestModifiedStatus } from "../../shared/request.constant";
import { IRequestModifiedStatus } from "../../shared/request.interface";
import { Button, Modal } from "react-bootstrap";

// interfaces
interface AssignRequestFormInputs {
  userId: {
    label: string;
    value: string;
    firstName: string;
    lastName: string;
  };
  comment: string;
}

// Component displaying the land request details.
export const LandRequestDetails = () => {
  // state and hooks
  const [reqDetailsToggle, setRequestDetailsToggle] =
    useRecoilState(requestDetailsToggle);
  const { user }: JwtPayLoad = getUserDetails();
  const [loading, setLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [options, setUserOption] = useState([]);
  const setToasterStatus = useSetRecoilState(toaster);
  const setListHighLight = useSetRecoilState(listHighlighter);
  const updateRequestListValue = useRecoilValue(updateRequestList);
  const [requestData, setRequestData] = useState({
    businessName: "",
    approvedBy: "",
    specificRequest: "",
    commentOnRequesByLeDh: "",
    commentOnRequesByLeM: "",
    requestStatus: "",
    projectType: "",
    nfaDocument: "",
    requestName: "",
    location: "",
    locationLat: 0,
    locationLon: 0,
    status: "",
    village: "",
    area: 0,
    landType: "",
    budget: 0,
    proposedBy: "",
    updatedDateTime: "",
    purchaseType: "",
    createdDateTime: "",
    closeDate: "",
    state: {
      stateName: "",
    },
    district: {
      districtName: "",
    },
    city: {
      city: "",
    },
    requestAssignToLeDh2: {
      id: "",
      firstName: "",
      lastName: "",
    },
    requestAssignToLeSpoc2: {
      id: "",
      firstName: "",
      lastName: "",
    },
    commentOnRequest: "",
    optionsExploredByBusiness: false,
    optionsExploredFile: "",
    optionsExploredByBusinessComment: "",
    businessUnitName: {
      id: 0,
      name: "",
    },
    companyName: {
      id: 0,
      name: "",
    },
    locationFiles: [],
    nfaDocumentFiles: [],
    optionsExploredFiles: []
  });
  const reqId = useRecoilValue(requestId);
  const reqStatus = useRecoilValue(requestStatus);
  const [businessUnit, setBusinessUnit] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectBusinessUnit, setSelectedBussinessUnit] = useState("");
  const [showFileList, setshowFileList] = useState(false);
  const [fileTypeName, setFileTypeName] = useState("");

  // The effect runs whenever the reqId dependency changes
  useEffect(() => {
    // Make the GET request for get the particular land request details
    if (reqId) {
      setLoadingDetails(true);
      Http.request(
        "get",
        `${process.env.REACT_APP_API_URL}land-request/${reqId}`,
        null,
        apiHeaders()
      )
        .then((result) => {
          setLoadingDetails(false);
          setRequestData(result.data.landRequest);
        })
        .catch((error) => {
          setLoadingDetails(false);
          console.log(error);
        });
    }
  }, [reqId, updateRequestListValue]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await Http.request(
          "get",
          `${process.env.REACT_APP_API_URL}generic/business-unit`,
          null
        );
        setBusinessUnit(result.data.businessUnit);
      } catch (error) {
        // Handle or log the error here
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Make the GET request for get the users
    if (user?.role === "le_dh") {
      setSelectedRole("le_spoc");
    }
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}users/get-user-le/${selectedRole}/${requestData?.businessUnitName?.id}`,
      null,
      apiHeaders()
    )
      .then((result) => {
        const option = result.data.user.map((user: User) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
        }));
        setUserOption(option);
      })
      .catch((error) => {
        setUserOption([]);
      });
  }, [
    selectedRole,
    selectBusinessUnit,
    user?.role,
    requestData?.businessUnitName?.id,
  ]);

  // validation schema
  const validationSchema = Yup.object().shape({
    userId: Yup.object({
      label: Yup.string(),
      value: Yup.string().required("User is required"),
    }),
    comment: Yup.string().required("Enter comment"),
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<AssignRequestFormInputs>({
    resolver: yupResolver(validationSchema),
  });

  /**
  Handles the form submission when the assign land request clicks the accept button.
  This function performs an API call to assign land request.
  @param {AssignRequestFormInputs} data - The data representing the all the value of the inputs fields.
*/
  const onSubmit = (data: AssignRequestFormInputs) => {
    const request = {
      commentOnRequest: data.comment,
      userId: data.userId.value,
    };
    setLoading(true);

    // Make a POST request to the assign land request API endpoint
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}land-request/${reqId}/assign-land-request`,
      request,
      apiHeaders()
    )
      .then((res) => {
        // Show a success notification to the user
        setToasterStatus({
          status: true,
          title: "Request",
          text: "Land request assigned successfully",
        });
        if (selectedRole === ROLES.le_dh) {
          requestData.requestAssignToLeDh2 = {
            id: data.userId.value,
            firstName: data.userId.firstName,
            lastName: data.userId.lastName,
          };
          requestData.commentOnRequesByLeM = data.comment;
        }
        if (selectedRole === ROLES.le_spoc) {
          requestData.requestAssignToLeSpoc2 = {
            id: data.userId.value,
            firstName: data.userId.firstName,
            lastName: data.userId.lastName,
          };
          requestData.commentOnRequesByLeDh = data.comment;
        }

        setRequestData(requestData);
        setLoading(false);
        reset();
        setValue("userId", {
          label: "",
          value: "",
          firstName: "",
          lastName: "",
        });
      })
      .catch((err) => {
        // Set an error message if the API request fails
        setLoading(false);
      });
  };

  const customStyles = {
    option: (base: any, state: any) => {
      return {
        ...base,
        backgroundColor: state.isFocused
          ? "rgba(71, 160, 231, 0.85)"
          : "rgb(55, 93, 177)",
      };
    },
  };
  const viewFile = (fileType: string) => {
    setshowFileList(true);
    setFileTypeName(fileType);
  };

  return (
    <>
    <div
        className="modal show"
        style={{display: "block", position: "initial"}}
      >
        <Modal
          show={showFileList}
          onHide={() => {
            setshowFileList(false);
          }}
          style={{zIndex: 10000}}
        >
          <Modal.Header closeButton className="toaster-header ">
            <Modal.Title>
              {fileTypeName === "location" && "Location DOC Files"}
              {fileTypeName === "nfa" && "NFA DOC Files"}
              {fileTypeName === "business" && "Business Explored DOC Files"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="file-list-view">
              {fileTypeName === "location" &&
                requestData.locationFiles.length > 0 &&
                requestData.locationFiles.map((file: any, index: number) => {
                  return (
                    <div className="file-list-item">
                      {file.url && (
                        <>
                          <Link
                            target="_blank"
                            title="View File"
                            to={file.url}
                            role="button"
                            className="view-file"
                          >
                            {file.url.split("/").filter(Boolean).pop()}
                          </Link>
                          &nbsp;
                        </>
                      )}
                    </div>
                  );
                })}
              {fileTypeName === "nfa" &&
                requestData.nfaDocumentFiles.length > 0 &&
                requestData.nfaDocumentFiles.map((file: any, index: number) => {
                  return (
                    <div className="file-list-item">
                      {file.url && (
                        <>
                          <Link
                            target="_blank"
                            title="View File"
                            to={file.url}
                            role="button"
                            className="view-file"
                          >
                            {file.url.split("/").filter(Boolean).pop()}
                          </Link>
                          &nbsp;
                        </>
                      )}
                    </div>
                  );
                })}
              {fileTypeName === "business" &&
                requestData.optionsExploredFiles.length > 0 &&
                requestData.optionsExploredFiles.map((file: any, index: number) => {
                  return (
                    <div className="file-list-item">
                      {file.url && (
                        <>
                          <Link
                            target="_blank"
                            title="View File"
                            to={file.url}
                            role="button"
                            className="view-file"
                          >
                            {file.url.split("/").filter(Boolean).pop()}
                          </Link>
                          &nbsp;
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setshowFileList(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div className="box">
        <div
          className={
            "request-detail-init " +
            (!reqDetailsToggle ? "request-detail-init--collapsed" : "")
          }
        >
          {loadingDetails && (
            <LoadinSkeleton width="370px" line={20}></LoadinSkeleton>
          )}

          {/* panel heading */}
          <div
            className="text-right panel-title"
            style={{ background: "#375db1" }}
          >
            <span
              style={{ color: "white", fontSize: "24px", paddingLeft: "20px" }}
            >
              Request Details
            </span>
            <span
              className="close-btn"
              id="request-detail-close"
              onClick={() => {
                setRequestDetailsToggle(false);
                setListHighLight(false);
              }}
            >
              &times;
            </span>
          </div>
          <div style={{ overflowY: "auto", maxHeight: "85vh" }}>
            <h6 className="mb-0 panel-header">
              Request Status:{" "}
              {
                RequestModifiedStatus[
                  requestData.requestStatus as keyof IRequestModifiedStatus
                ]
              }{" "}
            </h6>
            <table className="table">
              {/* Whole information of the component */}
              <tbody>
                <tr>
                  <th>Last Update Date Time</th>
                  <td>
                    {requestData?.updatedDateTime
                      ? moment(requestData?.updatedDateTime).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>Business Name</th>
                  <td>{requestData?.businessUnitName?.name}</td>
                </tr>
                <tr>
                  <th>Request Name</th>
                  <td>{requestData?.requestName}</td>
                </tr>

                <tr>
                  <th>Project Type</th>
                  <td>{requestData?.projectType}</td>
                </tr>

                <tr>
                  <th>Status</th>
                  <td>
                    {
                      RequestModifiedStatus[
                        requestData.requestStatus as keyof IRequestModifiedStatus
                      ]
                    }
                  </td>
                </tr>
                <tr>
                  <th>Start Date</th>
                  <td>
                    {moment(requestData?.createdDateTime).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </td>
                </tr>

                <tr>
                  <th>Close Date</th>
                  <td>
                    {requestData?.closeDate
                      ? moment(requestData?.closeDate).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )
                      : ""}
                  </td>
                </tr>
              </tbody>
            </table>
            <h6 className="text-uppercase text-white font-weight-bolder mt-1 ps-2 panel-sub-title">
              LOCATION
            </h6>
            <table className="table">
              <tbody>
                <tr>
                  <th>State</th>
                  <td>{requestData?.state?.stateName}</td>
                </tr>
                <tr>
                  <th>District</th>
                  <td>{requestData?.district?.districtName}</td>
                </tr>
                <tr>
                  <th>City</th>
                  <td>{requestData?.city?.city}</td>
                </tr>
                <tr>
                  <th>Village</th>
                  <td>{requestData?.village}</td>
                </tr>
                <tr>
                  <th>Lat/Long</th>
                  <td>
                    {requestData?.locationLat
                      ? `${requestData?.locationLat}, ${requestData?.locationLon}`
                      : ""}
                  </td>
                </tr>
              </tbody>
            </table>
            <h6 className="text-uppercase text-white font-weight-bolder mt-1 ps-2 panel-sub-title">
              LAND DETAILS
            </h6>
            <table className="table">
              <tbody>
                <tr>
                  <th>Location DOC</th>
                  <td>
                    {requestData?.locationFiles?.length > 0 ? (
                      <span
                      className="a-link-with-underline"
                      role="button"
                      onClick={() => viewFile("location")}
                    >
                      View Files
                    </span>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Option Explored DOC</th>
                  <td>
                    {requestData?.optionsExploredFiles?.length > 0 ? (
                       <span
                       className="a-link-with-underline"
                       role="button"
                       onClick={() => viewFile("business")}
                     >
                       View Files
                     </span>
                     ) : (
                       ""
                     )}
                  </td>
                </tr>
                <tr>
                  <th>NFA DOC</th>
                  <td>
                    {requestData?.nfaDocumentFiles?.length > 0 ? (
                        <span
                        className="a-link-with-underline"
                        role="button"
                        onClick={() => viewFile("nfa")}
                      >
                        View Files
                      </span>
                      ) : (
                        ""
                      )}
                  </td>
                </tr>

                <tr>
                  <th>Area (in Acre)</th>
                  <td>{requestData?.area ? requestData?.area : ""}</td>
                </tr>
                <tr>
                  <th>Type Of Land</th>
                  <td>
                    {requestData?.landType &&
                      JSON.parse(requestData?.landType)
                        ?.map(
                          (value: any) =>
                            LandType1.find((entry) => entry.value === value)
                              ?.label
                        )
                        .filter((label: any) => label)
                        .join(", ")}
                  </td>
                </tr>

                <tr>
                  <th>Estimated Budget (in Lakhs)</th>
                  <td>{requestData?.budget ? requestData?.budget : ""}</td>
                </tr>

                <tr>
                  <th>Acquisition Type</th>
                  <td>{requestData?.purchaseType}</td>
                </tr>

                <tr>
                  <th>Proposed By</th>
                  <td>{requestData?.proposedBy}</td>
                </tr>

                <tr>
                  <th>Approved By</th>
                  <td>{requestData?.approvedBy}</td>
                </tr>

                <tr>
                  <th>Specific Request</th>
                  <td>{requestData?.specificRequest}</td>
                </tr>

                <tr>
                  <th>Company Name</th>
                  <td>{requestData?.companyName?.name}</td>
                </tr>
                <tr>
                  <th>Explored By Business</th>
                  {requestData?.optionsExploredByBusiness === true ? (
                    <td>Yes</td>
                  ) : (
                    <td>No</td>
                  )}
                </tr>
                {requestData?.optionsExploredByBusinessComment && (
                  <tr>
                    <th>Explored By Business (Comment)</th>
                    <td>{requestData?.optionsExploredByBusinessComment}</td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Condition for if the request is already assign the show the information of the assigned request */}
            {requestData?.requestAssignToLeDh2?.id && (
              <>
                <h6 className="text-uppercase text-white font-weight-bolder mt-1 ps-2 panel-sub-title">
                  ASSIGN L & E - DH
                </h6>

                <table className="table">
                  <tbody>
                    <tr>
                      <th>L & E - DH</th>
                      <td>
                        {requestData?.requestAssignToLeDh2?.firstName}{" "}
                        {requestData?.requestAssignToLeDh2?.lastName}
                      </td>
                    </tr>
                    <tr>
                      <th>Comment on Accept</th>
                      <td>{requestData?.commentOnRequesByLeM}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}

            {requestData?.requestAssignToLeSpoc2?.id && (
              <>
                <h6 className="text-uppercase text-white font-weight-bolder mt-1 ps-2 panel-sub-title">
                  ASSIGN L & E - SPOC
                </h6>

                <table className="table">
                  <tbody>
                    <tr>
                      <th>L & E - SPOC</th>
                      <td>
                        {requestData?.requestAssignToLeSpoc2?.firstName}{" "}
                        {requestData?.requestAssignToLeSpoc2?.lastName}
                      </td>
                    </tr>
                    <tr>
                      <th>Comment on Accept</th>
                      <td>{requestData?.commentOnRequesByLeDh}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}

            {/* Condition for if the request is closed or on hold then not show assign form */}
            {checkPermission(user?.role, "request", "can_assign") && (
              // Form for the assign land request
              <form className="text-white" onSubmit={handleSubmit(onSubmit)}>
                <h6 className="text-uppercase text-white font-weight-bolder mt-1 ps-2 panel-sub-title">
                  ASSIGN REQUEST
                </h6>
                {/* {user?.role==='le.dh'? setSelectedRole():} */}
                {user?.role !== "le_dh" && (
                  <div className="form-group px-3 py-2">
                    <label htmlFor="inputPtype">User Type</label>
                    <select
                      className="form-select"
                      aria-label="Default Select Business Name"
                      onChange={(event) => {
                        setUserOption([]);
                        setValue("userId", {
                          label: "",
                          value: "",
                          firstName: "",
                          lastName: "",
                        });
                        setSelectedRole(event.target.value);
                      }}
                    >
                      <option value="">---Please Select User Type---</option>

                      <option value="le_dh">LE DH</option>

                      <option value="le_spoc">LE SPOC</option>
                    </select>
                  </div>
                )}

                {/* Business Name */}
                {/* <div className="form-group px-3 py-2">
                    <label htmlFor="inputPtype">Business Name</label>
                    <select
                      className="form-select"
                      aria-label="Default Select Business Name"
                      onChange={(event: any) =>
                        setSelectedBussinessUnit(event.target.value)
                      }
                    >
                      <option value="">
                        ---Please Select Business Name---
                      </option>
                      {businessUnit.map((unit: Business, index) => {
                        return (
                          <option key={unit.id} value={unit.id}>
                            {unit.name}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}

                <div className="form-group px-3 py-2">
                  <label htmlFor="inputuemail">Assignment to User</label>
                  <Controller
                    name={"userId"}
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          options={options}
                          styles={customStyles}
                        />
                      );
                    }}
                  />
                  <small id="uemailHelp" className="form-text text-white">
                    Assignment User Name.
                  </small>
                  <div>
                    {errors.userId && (
                      <span className="text-danger">
                        {errors.userId?.value?.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group px-3 py-2 text-white">
                  <label htmlFor="commentAccpt">Comment</label>
                  <textarea
                    className="form-control"
                    id="commentProp"
                    {...register("comment")}
                  ></textarea>
                  <div>
                    {errors.comment && (
                      <span className="text-danger">
                        {errors.comment.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group px-3 py-2 text-white">
                  <button
                    type="submit"
                    className="btn bg-gradient-primary-login btn1 w-100"
                    disabled={loading}
                  >
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "ASSIGN"
                    )}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
