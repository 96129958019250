//imports
import { ChangeEvent, useEffect, useState } from "react";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  AcquisitionType,
  capitalizeString,
} from "../../shared/generic.constant";
import {
  State,
  District,
  City,
  Business,
  Company,
  Acquisition,
} from "../../shared/generic.interface";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { toaster } from "../../state/atom/toaster";
import { requestToggle, updateRequestList } from "../../state/atom/request";
import {
  businessUnit,
  companies,
  locationLatLon,
  mapCellClass,
  mapCenter,
} from "../../state/atom/generic";
import { InfoBox } from "../info-hover";
import { customStyles } from "../../shared/landTypeStyle";
import { Link } from "react-router-dom";
import { error } from "console";

// interfaces
interface Props {
  toggleRequest: boolean;
}

interface LandRequestFormInputs {
  businessunit: number;
  projectType?: string;
  location: string;
  state: string;
  district: string;
  city: string;
  village: string;
  longitude: number;
  latitude: number;
  locationFile?: [];
  area: number;
  /* landType: Array<{ value: string; label: string }>; */
  requestName: string;
  budget?: number;
  aquisitionType?: string;
  proposedBy?: string;
  approvedBy?: string;
  nfaDocument?: [];
  specificRequest?: string;
  company: number;
  optionsExploredByBusiness: boolean;
  optionsExploredByBusinessComment?: string;
  optionsExploredFile?: [];
}

/**
Component displaying the request form.
@param {boolean} toggleRequest - Indicates whether the request form should be displayed.
*/
export const LandRequestForm = ({ toggleRequest }: Props) => {
  // validation schema for Land request form
  const validationSchema = Yup.object().shape({
    businessunit: Yup.string().required("Business Name is required"),
    location: Yup.string().required("Location is required"),
    state: Yup.number()
      .required("State is required")
      .typeError("State is required"),
    district: Yup.number()
      .required("District is required")
      .typeError("District is required"),
    city: Yup.number()
      .required("City is required")
      .typeError("City is required"),
    village: Yup.string()
      // .matches(/^[a-zA-Z\s]+$/, "Only string values are allowed")
      .required("Village Name is required"),
    requestName: Yup.string().required("Request Name is required"),

    longitude: Yup.number()
      .typeError("Longitude is required, must be a number")
      .test("valid-Longitude", "Invalid Longitude", (value) => {
        if (typeof value === "number") {
          const stringValue = String(value);
          return !stringValue.includes("e") && !stringValue.includes("E");
        }
        return true;
      }),
    latitude: Yup.number()
      .typeError("Latitude is required, must be a number")
      .test("valid-latitude", "Invalid latitude", (value) => {
        if (typeof value === "number") {
          const stringValue = String(value);
          return !stringValue.includes("e") && !stringValue.includes("E");
        }
        return true;
      })
      .required("Latitude must be a number"),
    area: Yup.number()
      .required("Area is required")
      .typeError("Area is required, must be a number")
      .test("valid-area", "Invalid are", (value) => {
        if (typeof value === "number") {
          const stringValue = String(value);
          return !stringValue.includes("e") && !stringValue.includes("E");
        }
        return true;
      }),
    /* landType: Yup.array()
      .min(1, "Please select at least one land type")
      .required("Type Of Land is required"), */
    company: Yup.string().required("Company Name is required"),
    optionsExploredByBusiness: Yup.boolean().optional(),
    optionsExploredByBusinessComment: Yup.string().test(
      "optionsExplored",
      "This field is required when options are explored by business.",
      function (value) {
        return !this.parent.optionsExploredByBusiness || !!value;
      }
    ),
    optionsExploredFile: Yup.mixed().test(
      "optionsExplored",
      "This field is required when options are explored by business.",
      function (value: any) {
        if (!!exploreByBusinessValue) {
          return value && value.length > 0;
        } else {
          return !this.parent.optionsExploredByBusiness || !!value;
        }
      }
    ),
  });

  const {
    register,
    getValues,
    control,
    setValue,
    watch,
    handleSubmit,
    reset,
    clearErrors,
    resetField,
    formState: { errors },
  } = useForm<LandRequestFormInputs>({
    resolver: yupResolver(validationSchema),
  });

  //state (hooks)
  const [submitErrorMessage, setSubmitErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [states, setState] = useState<State[]>([]);
  const [districts, setDistricts] = useState<District[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  // const [locationFile, setLocationFile] = useState("");
  // const [viewLocationFile, setViewLocationFile] = useState("");
  const [viewNFAFile, setViewNFAFile] = useState("");
  const [viewExploreBusinessFile, setViewExploreBusinessFile] = useState("");
  const [nfaDocument, setNfaDocument] = useState("");
  const [optionsExploredFileName, setOptionsExploredFileName] = useState("");
  const businessUnitData = useRecoilValue(businessUnit);
  const [companiesList, setCompanies] = useRecoilState(companies);
  const [locationFileUploadProgress, setLocationFileUploadProgress] =
    useState(false);
  const [nfaFileUploadProgress, setNFAFileUploadProgress] = useState(false);
  const [optionsExploredFileProgress, setOptionsExploredFileProgress] =
    useState(false);
  const setUpdateRequestList = useSetRecoilState(updateRequestList);
  const [mapCellCls, setMapCellClass] = useRecoilState(mapCellClass);
  const [locLatLon, setlocationLatLon] = useRecoilState(locationLatLon);
  const [showFileList, setshowFileList] = useState(false);
  const [fileTypeName, setFileTypeName] = useState("");
  const [locationDocFiles, setLocationDocFiles] = useState<any>([]);
  const [nfaDocFiles, setNfaDocFiles] = useState<any[]>([]);
  const [optionsExploredDocFiles, setOptionsExploredDocFiles] = useState<any[]>(
    []
  );

  const viewFile = (fileType: string) => {
    setshowFileList(true);
    setFileTypeName(fileType);
  };

  //state (recoil)
  const setToasterStatus = useSetRecoilState(toaster);
  const setMapCenter = useSetRecoilState(mapCenter);
  const [reqToggle, setToogleRequest] = useRecoilState(requestToggle);

  /**
  Handles the form submission when the user clicks the submit button.
  This function performs an API call to create a land request.
  @param {LandRequestFormInputs} data - The data representing the all the value of the inputs fields.
*/
  const onSubmit = async (data: any) => {
    setSubmitErrorMessage("");
    data.locationFile = (await getFileNames(locationDocFiles)) || [];
    data.nfaDocument = (await getFileNames(nfaDocFiles)) || [];
    data.optionsExploredFile =
      (await getFileNames(optionsExploredDocFiles)) || [];
    data.businessunit = Number(data.businessunit);
    data.company = Number(data.company);
    /* const modifiedTypeOfLand = data?.landType?.map((item: any) => {
      return item.value;
    });
    data.landType = modifiedTypeOfLand; */
    const cleanedData = removeEmptyValues(data);

    if (cleanedData.budget) {
      cleanedData.budget = Number(cleanedData.budget);
    }

    if (!data.hasOwnProperty("optionsExploredByBusiness")) {
      data.optionsExploredByBusiness = false;
    }

    if (cleanedData.optionsExploredByBusiness === false) {
      delete cleanedData["optionsExploredByBusinessComment"];
      delete cleanedData["optionsExploredFile"];
    }

    // Make a POST request to the land request API endpoint
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}land-request`,
      cleanedData,
      apiHeaders()
    )
      .then((result: any) => {
        // Reset the form fields
        reset();
        //reset({ landType: [] });
        // setViewLocationFile("");
        setViewExploreBusinessFile("");
        setViewNFAFile("");
        // Show a success notification to the user
        setToasterStatus({
          status: true,
          title: "Request",
          text: "Land request created successfully",
        });

        // Hide the Request form
        setToogleRequest(false);

        // Update the request list
        setUpdateRequestList(true);
      })
      .catch((error: any) => {
        // Set an error message if the API request fails
        setSubmitErrorMessage(error.response.data.message);
      });

    // Set loading state to false
    setLoading(false);
  };

  const getFileNames = async (data: any) => {
    const fileNameData = data.map((file: any) =>
      file.url.split("/").filter(Boolean).pop()
    );
    return fileNameData;
  };

  const removeEmptyValues = (obj: any) => {
    const newObj = { ...obj };
    for (const key in newObj) {
      if (
        newObj[key] === "" ||
        newObj[key] === null ||
        newObj[key] === undefined
      ) {
        delete newObj[key];
      }
    }
    return newObj;
  };
  useEffect(() => {
    // Make a GET request to the get the all state
    Http.request("get", `${process.env.REACT_APP_API_URL}generic/state`)
      .then((result: any) => {
        // Set all the state
        setState(result.data.state);
      })
      .catch((error: any) => {});
  }, []);

  useEffect(() => {
    if (locLatLon.lat) {
      setValue("latitude", locLatLon.lat);
      setValue("longitude", locLatLon.lon);
      setlocationLatLon({ lat: 0, lon: 0 });
    }
  }, [locLatLon, setValue, mapCellCls]);

  const changeState = (e: ChangeEvent<HTMLSelectElement>) => {
    setDistricts([]);
    setCities([]);
    getDistrict(Number(e.target.value));
  };

  const changeDistrict = (e: ChangeEvent<HTMLSelectElement>) => {
    setCities([]);
    getCities(Number(e.target.value));
  };

  const getDistrict = (stateId: number) => {
    // Make a GET request to get district
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}generic/district/${stateId}`
    )
      .then((result: any) => {
        setDistricts(result.data.district);
      })
      .catch((error: any) => {});
  };

  const getCities = (districtId: number) => {
    // Make a GET request to get city
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}generic/locality/${districtId}`
    )
      .then((result: any) => {
        setCities(result.data.locality);
      })
      .catch((error: any) => {});
  };

  // Handle location file change
  const onLocationFileChange = (event: any) => {
    const formData = new FormData();
    formData.append("document", event.target.files[0]);
    setLocationFileUploadProgress(true);

    // Make a POST request to the Location File API endpoint
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}generic/request/location`,
      formData
    )
      .then((result: any) => {
        // setLocationFile(result.fileName);
        // setViewLocationFile(result.url);
        setLocationFileUploadProgress(false);
        const newFilesArray = [...locationDocFiles];
        newFilesArray.push({ url: result.url });
        setLocationDocFiles(newFilesArray);
        resetField("locationFile");
      })
      .catch((error: any) => {
        setLocationFileUploadProgress(false);
        setLocationDocFiles([]);
      });
  };

  // Handle NFA file change
  const onNfaFileChange = (event: any) => {
    const formData = new FormData();
    formData.append("document", event.target.files[0]);
    setNFAFileUploadProgress(true);

    // Make a POST request to the NFA File API endpoint
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}generic/request/nfa`,
      formData
    )
      .then((result: any) => {
        // setNfaDocument(result.fileName);
        // setViewNFAFile(result.url);
        const newFilesArray = [...nfaDocFiles];
        newFilesArray.push({ url: result.url });
        setNfaDocFiles(newFilesArray);
        setNFAFileUploadProgress(false);
        resetField("nfaDocument");
      })
      .catch((error: any) => {
        setNFAFileUploadProgress(false);
      });
  };
  // Handle NFA file change
  const onOptionsExploredFileChange = (event: any) => {
    const formData = new FormData();
    formData.append("document", event.target.files[0]);
    setOptionsExploredFileProgress(true);

    // Make a POST request to the NFA File API endpoint
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}generic/request/options`,
      formData
    )
      .then((result: any) => {
        // setOptionsExploredFileName(result.fileName);
        // setViewExploreBusinessFile(result.url);
        const newFilesArray = [...optionsExploredDocFiles];
        newFilesArray.push({ url: result.url });
        setOptionsExploredDocFiles(newFilesArray);
        setOptionsExploredFileProgress(false);
        resetField("optionsExploredFile");
      })
      .catch((error: any) => {
        setOptionsExploredFileProgress(false);
      });
  };

  // Handle the on wheel change
  const numberInputOnWheelPreventChange = (e: any) => {
    e.target.blur();
  };

  const getCompany = (e: ChangeEvent<HTMLSelectElement>) => {
    setCompanies([]);
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}generic/${e.target.value}/company`,
      null
    )
      .then((result) => {
        setCompanies(result.data.company);
      })
      .catch((error) => {});
  };

  const exploreByBusinessValue = getValues("optionsExploredByBusiness");
  const optionsExploredByBusiness = watch("optionsExploredByBusiness");
  const optionsExploredByBusinessFileUpload = watch(
    "optionsExploredByBusiness"
  );

  // Handle Location DOC file
  const handleLocationDOCFiles = (action: string, index: number) => {
    if (action === "delete") {
      const newFilesArray = [...locationDocFiles];
      newFilesArray.splice(index, 1);
      setLocationDocFiles(newFilesArray);
      resetField("locationFile");
    } else {
      const newFilesArray = [...locationDocFiles];
      newFilesArray.push({ url: "" });
      setLocationDocFiles(newFilesArray);
    }
  };

  // Handle NFA file
  const handleNfaFiles = (action: string, index: number) => {
    if (action === "delete") {
      const newFilesArray = [...nfaDocFiles];
      newFilesArray.splice(index, 1);
      setNfaDocFiles(newFilesArray);
      resetField("nfaDocument");
    } else {
      const newFilesArray = [...nfaDocFiles];
      newFilesArray.push({ url: "" });
      setNfaDocFiles(newFilesArray);
    }
  };
  // Handle Optioned Explored  file
  const handleOptionedExporedFiles = (action: string, index: number) => {
    if (action === "delete") {
      const newFilesArray = [...optionsExploredDocFiles];
      newFilesArray.splice(index, 1);
      setOptionsExploredDocFiles(newFilesArray);
      resetField("optionsExploredFile");
    } else {
      const newFilesArray = [...optionsExploredDocFiles];
      newFilesArray.push({ url: "" });
      setOptionsExploredDocFiles(newFilesArray);
    }
  };

  const handleUploadFileInput = (id: string) => {
    const inputTag = document.getElementById(id);
    if (inputTag) {
      inputTag.click();
    }
  };
  return (
    <>
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal
          show={showFileList}
          onHide={() => {
            setshowFileList(false);
          }}
          style={{ zIndex: 10000 }}
        >
          <Modal.Header closeButton className="toaster-header ">
            <Modal.Title>
              {fileTypeName === "location" && "Location DOC Files"}
              {fileTypeName === "nfa" && "NFA DOC Files"}
              {fileTypeName === "business" && "Business Explored DOC Files"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="file-list-view">
              {fileTypeName === "location" &&
                locationDocFiles.length > 0 &&
                locationDocFiles.map((file: any, index: number) => {
                  return (
                    <div className="file-list-item">
                      {file.url && (
                        <>
                          <Link
                            target="_blank"
                            title="View File"
                            to={file.url}
                            role="button"
                            className="view-file"
                          >
                            {file.url.split("/").filter(Boolean).pop()}
                          </Link>
                          &nbsp;
                          <button
                            className="btn btn-secondary btn-xs"
                            type="button"
                            onClick={() =>
                              handleLocationDOCFiles("delete", index)
                            }
                          >
                            <i className="bi-trash-fill "></i>
                          </button>
                        </>
                      )}
                    </div>
                  );
                })}
              {fileTypeName === "nfa" &&
                nfaDocFiles.length > 0 &&
                nfaDocFiles.map((file: any, index: number) => {
                  return (
                    <div className="file-list-item">
                      {file.url && (
                        <>
                          <Link
                            target="_blank"
                            title="View File"
                            to={file.url}
                            role="button"
                            className="view-file"
                          >
                            {file.url.split("/").filter(Boolean).pop()}
                          </Link>
                          &nbsp;
                          <button
                            className="btn btn-secondary btn-xs"
                            type="button"
                            onClick={() => handleNfaFiles("delete", index)}
                          >
                            <i className="bi-trash-fill "></i>
                          </button>
                        </>
                      )}
                    </div>
                  );
                })}
              {fileTypeName === "business" &&
                optionsExploredDocFiles.length > 0 &&
                optionsExploredDocFiles.map((file: any, index: number) => {
                  return (
                    <div className="file-list-item">
                      {file.url && (
                        <>
                          <Link
                            target="_blank"
                            title="View File"
                            to={file.url}
                            role="button"
                            className="view-file"
                          >
                            {file.url.split("/").filter(Boolean).pop()}
                          </Link>
                          &nbsp;
                          <button
                            className="btn btn-secondary btn-xs"
                            type="button"
                            onClick={() =>
                              handleOptionedExporedFiles("delete", index)
                            }
                          >
                            <i className="bi-trash-fill "></i>
                          </button>
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setshowFileList(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div
        className={
          "new-request-init new-request-popup align-self-end " +
          (!reqToggle ? "new-request-init--collapsed" : "")
        }
      >
        <div
          className="text-left panel-title sticky-heading"
          style={{ background: "#375db1", position: "sticky", zIndex: "1" }}
        >
          <span
            style={{ color: "white", fontSize: "24px", paddingLeft: "40px" }}
          >
            New Request{" "}
          </span>
          <span
            className="close-btn"
            id="new-request-close"
            onClick={() => {
              setToogleRequest(false);
              clearErrors();
            }}
          >
            &times;
          </span>
        </div>
        <div style={{ overflowY: "auto" }}>
          <h6 className="mb-0 panel-header">
            Fill all the details for new request.
          </h6>
          {submitErrorMessage && (
            <div className="text-center">
              <span className="text-warning">{submitErrorMessage}</span>
            </div>
          )}
          <form className="text-white" onSubmit={handleSubmit(onSubmit)}>
            {/* Business Name */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputBusiness">Business Name*</label>
              <select
                className="form-select"
                aria-label="Default Select Business Name"
                {...register("businessunit")}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => getCompany(e)}
              >
                <option value="">---Please Select Business Name---</option>
                {businessUnitData.map((unit: Business, index) => {
                  return (
                    <option key={unit.id} value={unit.id}>
                      {unit.name}
                    </option>
                  );
                })}
              </select>

              {errors.businessunit && (
                <span className="text-warning">
                  {errors.businessunit.message}
                </span>
              )}
            </div>

            {/* Company Name    */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputSRequest">Company Name* </label>
              <select
                className="form-select"
                aria-label="Default Select Company Name"
                {...register("company")}
              >
                <option value="">---Please Select Company Name---</option>
                {companiesList.map((company: Company, index) => {
                  return (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  );
                })}
              </select>
              {errors.company && (
                <span className="text-warning">{errors.company.message}</span>
              )}
            </div>

            {/* Request Name */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Request Name*</label>
              <input
                type="text"
                className="form-control"
                id="requestName"
                placeholder="for example ATGL-GJ-2023"
                {...register("requestName")}
              />

              {errors.requestName && (
                <span className="text-warning">
                  {errors.requestName.message}
                </span>
              )}
            </div>

            {/* Project Type */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputPtype">Project Type</label>
              <input
                type="text"
                className="form-control"
                id="projectType"
                placeholder="for example Solar Power Plant"
                {...register("projectType")}
              />
            </div>

            {/* Site Name */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputLocation">Site Name*</label>
              <input
                type="text"
                className="form-control"
                id="locationNewReq"
                placeholder="for example Ahmedabad, Gujarat 382421"
                {...register("location")}
              />

              {errors.location && (
                <span className="text-warning">{errors.location.message}</span>
              )}
            </div>

            {/* State */}
            <div className="form-group px-3 py-2">
              <label htmlFor="select-state">Select State*</label>
              <select
                className="form-select"
                aria-label="Default select state"
                {...register("state")}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => changeState(e)}
              >
                <option value="">---Please Select State---</option>
                {states.map((state, index) => {
                  return (
                    <option key={state.id} value={state.id}>
                      {capitalizeString(state.stateName)}
                    </option>
                  );
                })}
              </select>

              {errors.state && (
                <span className="text-warning">{errors.state.message}</span>
              )}
            </div>

            {/* District */}
            <div className="form-group px-3 py-2">
              <label htmlFor="select-district">Select District*</label>
              <select
                className="form-select"
                aria-label="Default select state"
                {...register("district")}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  changeDistrict(e)
                }
              >
                <option value="">---Please Select District---</option>
                {districts.map((district, index) => {
                  return (
                    <option key={district.id} value={district.id}>
                      {capitalizeString(district.districtName)}
                    </option>
                  );
                })}
              </select>

              {errors.district && (
                <span className="text-warning">{errors.district.message}</span>
              )}
            </div>

            {/* City/Taluk */}
            <div className="form-group px-3 py-2">
              <label htmlFor="select-city">Select City/Taluka*</label>
              <select
                className="form-select"
                aria-label="Default Select City/Taluka"
                {...register("city")}
              >
                <option value="">---Please Select City/Taluka---</option>
                {cities.map((city, index) => {
                  return (
                    <option key={city.id} value={city.id}>
                      {capitalizeString(city.city)}
                    </option>
                  );
                })}
              </select>

              {errors.city && (
                <span className="text-warning">{errors.city.message}</span>
              )}
            </div>

            {/* Village */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputVillage">Village*</label>
              <input
                type="text"
                className="form-control"
                id="village"
                placeholder="for example Gota"
                {...register("village")}
              />

              {errors.village && (
                <span className="text-warning">{errors.village.message}</span>
              )}
            </div>

            {/* Latitude */}
            <div className="form-group px-3 py-2 lat-lon">
              <div className="col-6">
                <label htmlFor="inputLatitude">Latitude*</label>
                <input
                  type="number"
                  step="any"
                  className="form-control"
                  id="latitude"
                  placeholder="Ex. 23.0225"
                  onWheel={numberInputOnWheelPreventChange}
                  {...register("latitude")}
                />

                {errors.latitude && (
                  <span className="text-warning">
                    {errors.latitude.message}
                  </span>
                )}
              </div>
              &nbsp;
              <div className="col-6">
                <label htmlFor="longitude">Longitude*</label>
                <input
                  type="number"
                  step="any"
                  className="form-control"
                  id="longitude"
                  placeholder="Ex. 72.5714"
                  onWheel={numberInputOnWheelPreventChange}
                  {...register("longitude")}
                />

                {errors.longitude && (
                  <span className="text-warning">
                    {errors.longitude.message}
                  </span>
                )}
              </div>
            </div>

            <div className="form-group px-3 py-2" style={{ display: "flex" }}>
              <div className="col-6">
                <span
                  className="btn btn-dark mb-0 me-2"
                  onClick={() => {
                    setMapCellClass(true);
                  }}
                >
                  <i className="material-icons text-lg align-middle">
                    add_location
                  </i>
                  Select On Map
                </span>
              </div>
              <div className="col-6 text-end">
                <span
                  className="btn btn-dark mb-0 me-2"
                  onClick={() => {
                    if (getValues("longitude") && getValues("latitude")) {
                      setMapCenter({
                        zoom: 17,
                        center: [getValues("longitude"), getValues("latitude")],
                        isShow: true,
                      });
                    }
                  }}
                >
                  <i className="material-icons text-lg align-middle">
                    add_location
                  </i>
                  Zoom To
                </span>
              </div>
            </div>

            {/* Location DOC  */}
            <div className="form-group px-3 py-2">
              <label htmlFor="locationDoc" className="form-label">
                Location DOC (PDF)
              </label>
              &nbsp;
              <InfoBox data={"Upload location document (PDF) here"}></InfoBox>
              {locationDocFiles.length > 0 && (
                <>
                  <br></br>
                  <span
                    title="View File"
                    style={{ color: "#fff", textDecoration: "none" }}
                    role="button"
                    className="view-file"
                    onClick={() => viewFile("location")}
                  >
                    <i className="bi bi-pencil-square"></i> &nbsp;View Files
                  </span>
                  &nbsp;
                </>
              )}
              <input
                className="form-control"
                type="file"
                accept="application/pdf"
                id="locationFileRequest"
                {...register("locationFile")}
                onChange={onLocationFileChange}
                style={{ display: "none" }}
              />
              <br />
              <Button
                variant="light"
                onClick={() => handleUploadFileInput("locationFileRequest")}
                style={{ width: "100%" }}
              >
                Upload File
              </Button>
              {errors.locationFile && (
                <span className="text-warning">
                  {errors.locationFile.message}
                </span>
              )}
              {locationFileUploadProgress && (
                <ProgressBar
                  animated
                  now={95}
                  label="uploading..."
                  style={{ height: "12px" }}
                />
              )}
            </div>

            {/* Area  */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputArea">Required Area (in Acre)*</label>
              <input
                type="number"
                step="any"
                className="form-control"
                id="area"
                placeholder="for example 263.20"
                onWheel={numberInputOnWheelPreventChange}
                {...register("area")}
              />

              {errors.area && (
                <span className="text-warning">{errors.area.message}</span>
              )}
            </div>

            {/* Type Of Land */}
            {/* <div className="form-group px-3 py-2">
              <label htmlFor="inputTLand">Type Of Land*</label>

              <Controller
                name="landType"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti
                    options={LandType1}
                    className="basic-multi-select"
                    placeholder="Select Land Type"
                    classNamePrefix="select"
                    styles={customStyles}
                  />
                )}
              />

              {errors.landType && (
                <span className="text-warning">
                  {errors.landType.message}
                </span>
              )}
            </div> */}

            {/* Budget */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputBudget">Estimated Budget (in Lakhs)</label>
              <input
                type="number"
                step="any"
                className="form-control"
                id="budget"
                placeholder="for example 50"
                onWheel={numberInputOnWheelPreventChange}
                {...register("budget")}
              />
            </div>

            {/* Lease/Purchase */}
            <div className="form-group px-3 py-2">
              <label htmlFor="select-lease">Select Acquisition Type</label>
              <select
                className="form-select"
                aria-label="Default SelectAcquisitionType"
                {...register("aquisitionType")}
              >
                <option value="">---Please Select Acquisition Type---</option>
                {Object.keys(AcquisitionType).map((unit, index) => {
                  return (
                    <option key={unit} value={unit}>
                      {AcquisitionType[unit as keyof Acquisition]}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* Proposed By */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputProposed">Proposed By</label>
              <input
                type="text"
                className="form-control"
                id="proposedBy"
                placeholder="for example Ramesh"
                {...register("proposedBy")}
              />
            </div>

            {/* Approved By */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputApproved">Approved By</label>
              <input
                type="text"
                className="form-control"
                id="approvedBy"
                placeholder="for example Rohit"
                {...register("approvedBy")}
              />
            </div>

            {/* NFA DOC */}
            <div className="form-group px-3 py-2">
              <label htmlFor="locationDoc" className="form-label">
                NFA DOC (PDF)
              </label>
              &nbsp;
              <InfoBox data={"Upload NFA document (PDF) here"}></InfoBox>
              {nfaDocFiles.length > 0 && (
                <>
                  <br></br>
                  <span
                    title="View File"
                    style={{ color: "#fff", textDecoration: "none" }}
                    role="button"
                    className="view-file"
                    onClick={() => viewFile("nfa")}
                  >
                    <i className="bi bi-pencil-square"></i> &nbsp;View Files
                  </span>
                  &nbsp;
                </>
              )}
              <input
                className="form-control"
                type="file"
                accept="application/pdf"
                id="nfaDocument"
                {...register("nfaDocument")}
                onChange={onNfaFileChange}
                style={{ display: "none" }}
              />
              <br />
              <Button
                variant="light"
                onClick={() => handleUploadFileInput("nfaDocument")}
                style={{ width: "100%" }}
              >
                Upload File
              </Button>
              {nfaFileUploadProgress && (
                <ProgressBar
                  animated
                  now={95}
                  label="uploading..."
                  style={{ height: "12px" }}
                />
              )}
            </div>

            {/* Specific Request */}
            <div className="form-group px-3 py-2">
              <label htmlFor="inputSRequest">Specific Request</label>
              <input
                type="text"
                className="form-control"
                id="specificRequest"
                placeholder="Mention specific request if any"
                {...register("specificRequest")}
              />

              {errors.specificRequest && (
                <span className="text-warning">
                  {errors.specificRequest.message}
                </span>
              )}
            </div>

            {/* Is Options Explored By Business */}
            <div className="form-group px-3 py-2">
              <label htmlFor="checkboxExplored">
                <Controller
                  name="optionsExploredByBusiness"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="checkbox"
                      id="optionsExploredByBusiness"
                      className="form-check-input"
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
                &nbsp; Is Options Explored By Business
              </label>
              &nbsp;
              {errors.optionsExploredByBusiness && (
                <span className="text-warning">
                  {errors.optionsExploredByBusiness.message}
                </span>
              )}
            </div>

            {optionsExploredByBusinessFileUpload && (
              <div className="form-group px-3 py-2">
                <label htmlFor="locationDoc" className="form-label">
                  Explored By Business File
                </label>
                &nbsp;
                <InfoBox
                  data={"Upload  Explored By Business File document (PDF) here"}
                ></InfoBox>
                {optionsExploredDocFiles.length > 0 && (
                  <>
                    <br></br>
                    <span
                      title="View File"
                      style={{ color: "#fff", textDecoration: "none" }}
                      role="button"
                      className="view-file"
                      onClick={() => viewFile("business")}
                    >
                      <i className="bi bi-pencil-square"></i> &nbsp;View Files
                    </span>
                    &nbsp;
                  </>
                )}
                <input
                  className="form-control"
                  type="file"
                  accept="application/pdf"
                  id="optionsExploredFile"
                  {...register("optionsExploredFile")}
                  onChange={onOptionsExploredFileChange}
                  style={{ display: "none" }}
                />
                <br />
                <Button
                  variant="light"
                  onClick={() => handleUploadFileInput("optionsExploredFile")}
                  style={{ width: "100%" }}
                >
                  Upload File
                </Button>
                {optionsExploredFileProgress && (
                  <ProgressBar
                    animated
                    now={95}
                    label="uploading..."
                    style={{ height: "12px" }}
                  />
                )}
                {errors.optionsExploredFile && (
                  <span className="text-warning">
                    {errors.optionsExploredFile.message}
                  </span>
                )}
              </div>
            )}

            {/* Explored By Business Comment */}
            {optionsExploredByBusiness && (
              <div className="form-group px-3 py-2">
                <label htmlFor="inputSRequest">
                  Explored By Business Comment
                </label>
                <Controller
                  name="optionsExploredByBusinessComment"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text"
                      className="form-control"
                      id="optionsExploredByBusinessComment"
                      placeholder="Give details of option explored by business"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />

                {errors.optionsExploredByBusinessComment && (
                  <span className="text-warning">
                    {errors.optionsExploredByBusinessComment.message}
                  </span>
                )}
              </div>
            )}

            {/* Submit Button */}
            <div className="form-group px-3 py-2">
              <button
                type="submit"
                className="btn bg-gradient-primary-login btn1 w-100"
                disabled={loading}
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "SUBMIT REQUEST"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
