import { atom } from "recoil";
import { RequestProposal } from "../../shared/request.interface";

export const requestToggle = atom({
  key: "requestToggle",
  default: false,
});

export const requestListToggle = atom({
  key: "requestListToggle",
  default: false,
});
export const editRequestListToggle = atom({
  key: "editRequestListToggle",
  default: false,
});
export const editRequestListID = atom({
  key: "editRequestListID",
  default: '',
});

export const updateRequestList = atom({
  key: "updateRequestList",
  default: false,
});

export const requestList = atom({
  key: "requestList",
  dangerouslyAllowMutability: true,
  default: {
    data: {
      landRequest: [],
    },
  },
});

export const requestListClone = atom({
  key: "requestListClone",
  dangerouslyAllowMutability: true,
  default: {
    data: {
      landRequest: [],
    },
  },
});

export const requestDetailsToggle = atom({
  key: "requestDetailsToggle",
  default: false,
  effects: [
    () => {
      return () => {
      };
    },
  ],
});

export const requestId = atom({
  key: "requestId",
  default: "",
});

export const requestStatus = atom({
  key: "requestStatus",
  default: "",
});

export const holdRequestToggle = atom({
  key: "holdRequestToggle",
  default: false,
});

export const unholdRequestToggle = atom({
  key: "unholdRequestToggle",
  default: false,
});

export const closeRequestToggle = atom({
  key: "closeRequestToggle",
  default: false,
});

export const requestProposalKmlData = atom({
  key: "requestProposalKmlData",
  default: [] as RequestProposal[],
});
