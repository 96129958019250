import { useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  addDistrictModalToggle,
  stateListState,
} from "../../../../state/atom/settings";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Http } from "../../../../utility/http";
import { apiHeaders } from "../../../../utility/generic";
import Select from "react-select";
import { toaster } from "../../../../state/atom/toaster";

type State = {
  id: number;
  stateName: string;
};

type AddDistrictForm = {
  stateId: number;
  districtName: string;
  lat: number;
  lon: number;
};

export const AddDistrictModal = () => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<number | undefined>();
  const [showModal, setShowModal] = useRecoilState(addDistrictModalToggle);
  const setToasterStatus = useSetRecoilState(toaster);
  const statesList = useRecoilValue(stateListState);

  //select options
  const options = statesList.map((item: State) => ({
    value: item.id,
    label: item.stateName,
  }));

  const addDistrictValidationSchema = Yup.object().shape({
    stateId: Yup.number().required("Please select the state"),
    districtName: Yup.string().required("District name is required."),
    lat: Yup.number()
      .typeError("Latitude must be a number.")
      .required("Latitude is required."),
    lon: Yup.number()
      .typeError("Longitude must be a number.")
      .required("Longitude is required."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<AddDistrictForm>({
    resolver: yupResolver(addDistrictValidationSchema),
  });

  const onSubmitHandler = async (data: AddDistrictForm) => {
    setLoading(true);
    await Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}district/state?state=${selectedValue}`,
      data,
      apiHeaders()
    )
      .then((result: any) => {
        console.log(result.message);
        setToasterStatus({
          status: true,
          title: "District Added",
          text: result.message,
        });
        setLoading(false);
        handleClose();
      })
      .catch((error: any) => {
        setSubmitErrorMessage(error.response.data.message);
        setLoading(false);
      });
  };

  // handle onChange event of the dropdown
  const handleChange = (event: any) => {
    console.log(event);
    if (event) {
      setSelectedValue(event.value);
      setValue("stateId", event.value);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleNumericInput = (event: any) => {
    event.target.value = event.target.value.replace(/[^0-9.-]/g, "");
  };
  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header
          className="panel-title text-white"
          style={{ marginBottom: 0 }}
          closeButton
          closeVariant="white"
        >
          <Modal.Title>Add District</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            {submitErrorMessage && (
              <div className="text-center py-1">
                <span className="text-danger">{submitErrorMessage}</span>
              </div>
            )}
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="d-flex flex-column">
                {/* Select State  */}
                <div className="form-group px-3 py-2">
                  <div style={{ color: "red" }}>
                    &#42;
                    <label
                      className="form-label"
                      htmlFor="selectState"
                      style={{ color: "black" }}
                    >
                      State
                    </label>
                  </div>
                  <Select
                    closeMenuOnSelect={true}
                    isSearchable
                    options={options}
                    // value={options.find((item) => item.value === selectedValue)}
                    // {...register("stateId")}
                    onChange={handleChange}
                    placeholder="Select state"
                  />
                  {errors.stateId && (
                    <span className="text-danger">
                      {errors.stateId.message}
                    </span>
                  )}
                </div>
                <div className="form-group px-3 py-2">
                  <div style={{ color: "red" }}>
                    &#42;
                    <label
                      className="form-label"
                      htmlFor="inputStype"
                      style={{ color: "black" }}
                    >
                      District Name
                    </label>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.districtName ? "is-invalid" : ""
                    }`}
                    id="stateName"
                    placeholder="TAPI"
                    {...register("districtName")}
                  />
                  {errors.districtName && (
                    <span className="text-danger">
                      {errors.districtName.message}
                    </span>
                  )}
                </div>
                {/* Latitude */}
                <div className="form-group px-3 py-2">
                  <div style={{ color: "red" }}>
                    &#42;
                    <label
                      className="form-label"
                      htmlFor="inputStype"
                      style={{ color: "black" }}
                    >
                      Latitude
                    </label>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${errors.lat ? "is-invalid" : ""}`}
                    id="latitude"
                    placeholder="34.56789"
                    onInput={handleNumericInput}
                    {...register("lat")}
                  />

                  {errors.lat && (
                    <span className="text-danger">{errors.lat.message}</span>
                  )}
                </div>
                {/* Longitude */}
                <div className="form-group px-3 py-2">
                  <div style={{ color: "red" }}>
                    &#42;
                    <label
                      className="form-label"
                      htmlFor="inputStype"
                      style={{ color: "black" }}
                    >
                      Longitude
                    </label>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${errors.lon ? "is-invalid" : ""}`}
                    id="longitude"
                    placeholder="98.45678"
                    onInput={handleNumericInput}
                    {...register("lon")}
                  />

                  {errors.lon && (
                    <span className="text-danger">{errors.lon.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group px-3 py-2">
                <button
                  type="submit"
                  className="btn bg-gradient-primary-login btn1 w-100"
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Add State
        </Button>
      </Modal.Footer> */}
      </Modal>
    </>
  );
};
