import { useEffect, useState } from "react";
import { District } from "../../../../shared/generic.interface";
import { Http } from "../../../../utility/http";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { addCityModalToggle } from "../../../../state/atom/settings";
import { toaster } from "../../../../state/atom/toaster";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Modal } from "react-bootstrap";
import { apiHeaders } from "../../../../utility/generic";
import Select from "react-select";

type DistrictType = {
  id: number;
  districtName: string;
};

type AddCityForm = {
  districtId: number;
  city: string;
};
export const AddCityModal = () => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>("");
  const [districtList, setDistrictList] = useState<Array<District>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<number | undefined>();
  const [showModal, setShowModal] = useRecoilState(addCityModalToggle);
  const setToasterStatus = useSetRecoilState(toaster);

  const options = districtList.map((item: DistrictType) => ({
    value: item.id,
    label: item.districtName,
  }));

  useEffect(() => {
    const getDistricts = async () => {
      Http.request("get", `${process.env.REACT_APP_API_URL}district`)
        .then((result: any) => {
          setDistrictList(result.data.items);
        })
        .catch((error: any) => {
          console.error(error);
        });
    };
    getDistricts();
  }, []);

  const addCityValidationSchema = Yup.object().shape({
    districtId: Yup.number().required("Please select the district"),
    city: Yup.string().required("City name is required."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<AddCityForm>({
    resolver: yupResolver(addCityValidationSchema),
  });

  const onSubmitHandler = async (data: AddCityForm) => {
    setLoading(true);
    await Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}city/district?district=${selectedValue}`,
      data,
      apiHeaders()
    )
      .then((result: any) => {
        console.log(result.message);
        setToasterStatus({
          status: true,
          title: "City Added",
          text: result.message,
        });
        setLoading(false);
        handleClose();
      })
      .catch((error: any) => {
        setSubmitErrorMessage(error.response.data.message);
        setLoading(false);
      });
  };

  // handle onChange event of the dropdown
  const handleChange = (event: any) => {
    console.log(event);
    if (event) {
      setSelectedValue(event.value);
      setValue("districtId", event.value);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header
          className="panel-title"
          style={{ marginBottom: 0 }}
          closeButton
          closeVariant="white"
        >
          <Modal.Title className="text-white">Add City</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
          {submitErrorMessage && (
            <div className="text-center py-1">
              <span className="text-danger">{submitErrorMessage}</span>
            </div>
          )}
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="d-flex flex-column">
                {/* Select District  */}
                <div className="form-group px-3 py-2">
                  <div style={{ color: "red" }}>
                    &#42;
                    <label
                      className="form-label"
                      htmlFor="selectDistrict"
                      style={{ color: "black" }}
                    >
                      District
                    </label>
                  </div>
                  <Select
                    closeMenuOnSelect={true}
                    isSearchable
                    options={options}
                    // value={options.find((item) => item.value === selectedValue)}
                    // {...register("districtId")}
                    onChange={handleChange}
                    placeholder="Select Distrcit"
                  />
                  {errors.districtId && (
                    <span className="text-danger">
                      {errors.districtId.message}
                    </span>
                  )}
                </div>
                <div className="form-group px-3 py-2">
                  <div style={{ color: "red" }}>
                    &#42;
                    <label
                      className="form-label"
                      htmlFor="inputStype"
                      style={{ color: "black" }}
                    >
                      City
                    </label>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.city ? "is-invalid" : ""
                    }`}
                    id="stateName"
                    placeholder="Enter city"
                    {...register("city")}
                  />
                  {errors.city && (
                    <span className="text-danger">{errors.city.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group px-3 py-2">
                <button
                  type="submit"
                  className="btn bg-gradient-primary-login btn1 w-100"
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
