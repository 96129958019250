var moment = require("moment");

export const getLandSummary = (
  filterRequest: any,
  originalRequest: any = [],
  startDate = "",
  endDate = "",
  filterDataSet: any = {}
) => {
  let landSummary = {
    totalRequest: 0,
    totalAreaRequired: 0,
    totalAreaAccquired: 0,
    totalSalesDeed: 0,
    totalPendingSalesDeed: 0,
    totalMutationStatus: 0,
    totalPendingMutationStatus: 0,
    totalNAStatus: 0,
    totalPendingNAStatus: 0,
    totalTSRStatus: 0,
    totalFreeHoldArea: 0,
    totalLeaseArea: 0,
    activeBusiness: [],
    totalState: [],
    totalSite: [],
    landType: [],
    activeBusinesssDashboard: [],
    totalStatesDashboard: [],
    totalSitesDashboard: [],
    landTypesDashboard: [],
    saleDeedGrapData: [],
    totalCompletedSite: [],
  };
  landSummary.totalRequest = totalRequest(filterRequest);
  landSummary.totalAreaRequired = totalAreaRequired(filterRequest);
  landSummary.totalAreaAccquired = totalAreaAccquired(filterRequest);
  landSummary.totalSalesDeed = totalSalesDeed(filterRequest);
  landSummary.totalPendingSalesDeed = totalPendingSalesDeed(filterRequest);
  landSummary.totalMutationStatus = totalMutationStatus(filterRequest);
  landSummary.totalPendingMutationStatus =
    totalPendingMutationStatus(filterRequest);
  landSummary.totalNAStatus = totalNAStatus(filterRequest);
  landSummary.totalPendingNAStatus = totalPendingNAStatus(filterRequest);
  landSummary.totalTSRStatus = totalTSRStatus(filterRequest);
  landSummary.totalFreeHoldArea = totalFreeHoldArea(filterRequest);
  landSummary.totalLeaseArea = totalLeaseArea(filterRequest);
  landSummary.activeBusiness = activeBusiness(filterRequest);
  landSummary.totalState = totalState(filterRequest);
  landSummary.totalSite = totalSite(filterRequest);
  landSummary.landType = landType(filterRequest);
  landSummary.activeBusinesssDashboard = activeBusinessDashboard(filterRequest);
  landSummary.totalStatesDashboard = totalStateDashboard(filterRequest);
  landSummary.totalSitesDashboard = totalSiteDashboard(filterRequest);
  landSummary.totalCompletedSite = totalCompletedSite(filterRequest);
  landSummary.landTypesDashboard = landTypeDashBoard(filterRequest);
  landSummary.saleDeedGrapData = saleDeedGrapData(
    filterRequest,
    originalRequest,
    startDate,
    endDate,
    filterDataSet
  );
  return landSummary;
};

export const activeBusiness = (filterRequest: any) => {
  const activeBusinessArray: any = [];
  filterRequest.forEach((request: any) => {
    if (request.landParcel.length) {
      request.landParcel.forEach((parcel: any) => {
        const index = activeBusinessArray.findIndex(
          (activeBusiness: any) =>
            activeBusiness.name === request.businessUnit.name
        );
        if (index !== -1) {
          if (
            parcel.saleDeedStatus &&
            parcel.saleDeedStatus.toUpperCase() === "YES"
          ) {
            activeBusinessArray[index].area += parcel.parcelArea;
          }
          activeBusinessArray[index].count += 1;
        } else {
          activeBusinessArray.push({
            name: request.businessUnit.name,
            area:
              parcel.saleDeedStatus &&
              parcel.saleDeedStatus.toUpperCase() === "YES"
                ? parcel.parcelArea
                : 0,
            count: 1,
          });
        }
      });
    }
  });
  return activeBusinessArray;
};

export const activeBusinessDashboard = (filterRequest: any) => {
  const activeBusinessArray: any = [];
  filterRequest.forEach((request: any) => {
    if (request.landParcel.length) {
      /* request.landParcel.forEach((parcel: any) => {
        const index = activeBusinessArray.findIndex(
          (activeBusiness: any) =>
            activeBusiness.name === request.businessUnit.name
        );
        if (index !== -1) {
          activeBusinessArray[index].area += parcel.parcelArea;
          activeBusinessArray[index].count += 1;
        } else {
          activeBusinessArray.push({
            name: request.businessUnit.name,
            area: parcel.parcelArea,
            count: 1,
          });
        }
      }); */
      const index = activeBusinessArray.findIndex(
        (activeBusiness: any) =>
          activeBusiness.name === request.businessUnit.name
      );
      if (index !== -1) {
        activeBusinessArray[index].count += 1;
      } else {
        activeBusinessArray.push({
          name: request.businessUnit.name,
          count: 1,
        });
      }
    }
  });
  return activeBusinessArray.sort((a: any, b: any) => {
    const lowerA = a.name.toLowerCase();
    const lowerB = b.name.toLowerCase();

    if (lowerA < lowerB) return -1;
    if (lowerA > lowerB) return 1;
    return a.localeCompare(b);
  });
};

export const totalState = (filterRequest: any) => {
  const totalStateArray: any = [];
  filterRequest.forEach((request: any) => {
    if (request.landParcel.length) {
      request.landParcel.forEach((parcel: any) => {
        const index = totalStateArray.findIndex(
          (state: any) => state.name === request.state.stateName
        );
        if (index !== -1) {
          if (
            parcel.saleDeedStatus &&
            parcel.saleDeedStatus.toUpperCase() === "YES"
          ) {
            totalStateArray[index].area += parcel.parcelArea;
          }
        } else {
          totalStateArray.push({
            name: request.state.stateName,
            area:
              parcel.saleDeedStatus &&
              parcel.saleDeedStatus.toUpperCase() === "YES"
                ? parcel.parcelArea
                : 0,
          });
        }
      });
    }
  });
  return totalStateArray;
};

export const totalStateDashboard = (filterRequest: any) => {
  const totalStateArray: any = [];
  filterRequest.forEach((request: any) => {
    if (request.landParcel.length) {
      request.landParcel.forEach((parcel: any) => {
        const index = totalStateArray.findIndex(
          (state: any) => state.name === request.state.stateName
        );
        if (index !== -1) {
          totalStateArray[index].area += parcel.parcelArea;
        } else {
          totalStateArray.push({
            name: request.state.stateName,
            area: parcel.parcelArea,
          });
        }
      });
    }
  });
  return totalStateArray
    .sort((a: any, b: any) => a.name.localeCompare(b.name))
    .sort((a: any, b: any) => a.name.localeCompare(b.name));
  //return totalStateArray;
};

export const totalSite = (filterRequest: any) => {
  const totalSiteArray: any = [];
  filterRequest.forEach((request: any) => {
    if (request.landParcel.length) {
      request.landParcel.forEach((parcel: any) => {
        const index = totalSiteArray.findIndex(
          (state: any) => state.name === request.location
        );
        if (index !== -1) {
          if (
            parcel.saleDeedStatus &&
            parcel.saleDeedStatus.toUpperCase() === "YES"
          ) {
            totalSiteArray[index].area += parcel.parcelArea;
          }
        } else {
          totalSiteArray.push({
            name: request.location,
            area:
              parcel.saleDeedStatus &&
              parcel.saleDeedStatus.toUpperCase() === "YES"
                ? parcel.parcelArea
                : 0,
          });
        }
      });
    }
  });
  return totalSiteArray;
};

export const totalSiteDashboard = (filterRequest: any) => {
  const totalSiteArray: any = [];
  filterRequest.forEach((request: any) => {
    if (request.landParcel.length) {
      request.landParcel.forEach((parcel: any) => {
        const index = totalSiteArray.findIndex(
          (state: any) => state.name === request.location
        );
        if (index !== -1) {
          totalSiteArray[index].area += parcel.parcelArea;
        } else {
          totalSiteArray.push({
            name: request.location,
            area: parcel.parcelArea,
          });
        }
      });
    }
  });
  return totalSiteArray.sort((a: any, b: any) => {
    const lowerA = a.name.toLowerCase();
    const lowerB = b.name.toLowerCase();

    if (lowerA < lowerB) return -1;
    if (lowerA > lowerB) return 1;
    return a.localeCompare(b);
  });
};

export const totalCompletedSite = (filterRequest: any) => {
  const totalCompletedSites: any = [];
  filterRequest.forEach((request: any) => {
    if (request.status === "completed")
      if (request.landParcel.length) {
        totalCompletedSites.push({
          name: request.landParcel[0].site,
        });
      }
  });
  return totalCompletedSites.sort((a: any, b: any) => {
    const lowerA = a.name.toLowerCase();
    const lowerB = b.name.toLowerCase();

    if (lowerA < lowerB) return -1;
    if (lowerA > lowerB) return 1;
    return a.localeCompare(b);
  });
};

export const landType = (filterRequest: any) => {
  const totalLandTypeArray: any = [];
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        const index = totalLandTypeArray.findIndex(
          (land: any) => land.name === parcel.landType
        );
        if (index !== -1) {
          if (
            parcel.saleDeedStatus &&
            parcel.saleDeedStatus.toUpperCase() === "YES"
          ) {
            totalLandTypeArray[index].area += parcel.parcelArea;
          }
        } else {
          if (parcel.landType && parcel.landType != " ") {
            totalLandTypeArray.push({
              name: parcel.landType,
              area:
                parcel.saleDeedStatus &&
                parcel.saleDeedStatus.toUpperCase() === "YES"
                  ? parcel.parcelArea
                  : 0,
            });
          }
        }
      }
    });
  });
  return totalLandTypeArray;
};

export const landTypeDashBoard = (filterRequest: any) => {
  const totalLandTypeArray: any = [];
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        const index = totalLandTypeArray.findIndex(
          (land: any) => land.name === parcel.landType
        );
        if (index !== -1) {
          totalLandTypeArray[index].area += parcel.parcelArea;
        } else {
          if (parcel.landType && parcel.landType != " ") {
            totalLandTypeArray.push({
              name: parcel.landType,
              area: parcel.parcelArea,
            });
          }
        }
      }
    });
  });
  return totalLandTypeArray;
};

export const totalRequest = (filterRequest: any) => {
  let requestCnt = 0;
  filterRequest.forEach((request: any) => {
    if (request.landParcel.length) {
      requestCnt += 1;
    }
  });
  return requestCnt;
};

export const totalAreaRequired = (filterRequest: any) => {
  let totalArea = 0;
  filterRequest.forEach((request: any) => {
    if (request.landParcel.length) {
      totalArea += request.area;
    }
  });
  return totalArea;
};

export const totalAreaAccquired = (filterRequest: any) => {
  let totalArea = 0;
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        if (
          parcel.acquiredStatus &&
          parcel.acquiredStatus.toUpperCase() === "YES"
        ) {
          totalArea += Number(parcel.parcelArea);
        }
      }
    });
  });
  return totalArea;
};

export const totalSalesDeed = (filterRequest: any) => {
  let totalArea = 0;
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        if (
          parcel.saleDeedStatus &&
          parcel.saleDeedStatus.toUpperCase() === "YES"
        ) {
          totalArea += Number(parcel.parcelArea);
        }
      }
    });
  });
  return totalArea;
};

export const totalPendingSalesDeed = (filterRequest: any) => {
  return totalAreaRequired(filterRequest) - totalSalesDeed(filterRequest);
};

export const totalMutationStatus = (filterRequest: any) => {
  let totalArea = 0;
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        if (
          parcel.mutationStatus &&
          parcel.mutationStatus.toUpperCase() === "YES"
        ) {
          totalArea += Number(parcel.parcelArea);
        }
      }
    });
  });
  return totalArea;
};

export const totalPendingMutationStatus = (filterRequest: any) => {
  return totalSalesDeed(filterRequest) - totalMutationStatus(filterRequest);
};

export const totalNAStatus = (filterRequest: any) => {
  let totalArea = 0;
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        if (parcel.naStatus && parcel.naStatus.toUpperCase() === "YES") {
          totalArea += Number(parcel.parcelArea);
        }
      }
    });
  });
  return totalArea;
};

export const totalPendingNAStatus = (filterRequest: any) => {
  return totalMutationStatus(filterRequest) - totalNAStatus(filterRequest);
};

export const totalTSRStatus = (filterRequest: any) => {
  let totalArea = 0;
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        if (parcel.tsrStatus && parcel.tsrStatus.toUpperCase() === "YES") {
          totalArea += Number(parcel.parcelArea);
        }
      }
    });
  });
  return totalArea;
};

export const totalFreeHoldArea = (filterRequest: any) => {
  let totalArea = 0;
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        if (
          parcel.saleDeedStatus &&
          parcel.saleDeedStatus.toUpperCase() === "YES"
        ) {
          totalArea += Number(parcel.freeHoldArea);
        }
      }
    });
  });
  return totalArea;
};

export const totalLeaseArea = (filterRequest: any) => {
  let totalArea = 0;
  filterRequest.forEach((request: any) => {
    request.landParcel.forEach((parcel: any) => {
      if (request.landParcel.length) {
        if (
          parcel.saleDeedStatus &&
          parcel.saleDeedStatus.toUpperCase() === "YES"
        ) {
          totalArea += Number(parcel.leaseArea);
        }
      }
    });
  });
  return totalArea;
};

export const saleDeedGrapData = (
  filterRequest: any,
  originalRequest: any = [],
  startDate = "",
  endDate = "",
  filterDataSet: any = {}
) => {
  const saleDeedData: any = {};
  let totalSaleDeed = 0;
  let last3MonthSaleDeed = 0;
  let currentYearSaleDeed = 0;
  let currentMonthSaleDeed = 0;
  let filterdDateSaleDeed = -1;
  if (startDate || endDate) {
    filterdDateSaleDeed = 0;
  }
  const currentYear = moment().format("YYYY");
  const currentMonth = moment().format("MM");

  const currentDate = moment();
  const dateThreeMonthsAgo = currentDate.subtract(3, "months").startOf("month");
  const last3MonthStartDate = dateThreeMonthsAgo.format("YYYY-MM-DD");

  const currentDate1 = moment();
  const dateOneMonthsAgo = currentDate1.subtract(1, "months");
  const endOfMonthThreeMonthsAgo = dateOneMonthsAgo.endOf("month");
  const last3MonthEndtDate = endOfMonthThreeMonthsAgo.format("YYYY-MM-DD");
  let isOtherFilterApplied = false;
  let isDateFilterApplied = false;

  if (startDate || endDate) {
    isDateFilterApplied = true;
  }
  if (
    filterDataSet?.sites?.length > 0 ||
    filterDataSet?.states?.length > 0 ||
    filterDataSet?.businessNames?.length > 0
  ) {
    isOtherFilterApplied = true;
  }

  console.log("===", isDateFilterApplied, isOtherFilterApplied);

  filterRequest.forEach((request: any) => {
    if (request.landParcel.length) {
      request.landParcel.forEach((parcel: any) => {
        if (
          parcel.saleDeedStatus &&
          parcel.saleDeedStatus.toUpperCase() === "YES"
        ) {
          if (
            parcel?.saleDeedCreatedDateTime &&
            moment(parcel?.saleDeedCreatedDateTime).isBetween(
              last3MonthStartDate,
              last3MonthEndtDate,
              undefined,
              "[]"
            )
          ) {
            last3MonthSaleDeed += parcel.parcelArea;
          }
          if (isOtherFilterApplied && !isDateFilterApplied) {
            console.log("isOtherFilterApplied");
            totalSaleDeed += parcel.parcelArea;
            if (
              parcel?.saleDeedCreatedDateTime &&
              moment(parcel?.saleDeedCreatedDateTime).format("YYYY") ==
                currentYear
            ) {
              currentYearSaleDeed += parcel.parcelArea;
            }
          }

          if (
            parcel?.saleDeedCreatedDateTime &&
            moment(parcel?.saleDeedCreatedDateTime).format("MM") == currentMonth
          ) {
            currentMonthSaleDeed += parcel.parcelArea;
          }

          if (startDate || endDate) {
            if (parcel?.saleDeedCreatedDateTime) {
              filterdDateSaleDeed += parcel.parcelArea;
            }
          }
        }
      });
    }
  });

  if (!isDateFilterApplied && !isOtherFilterApplied) {
    console.log("isDateFilterApplied");
    currentYearSaleDeed = 0;
    totalSaleDeed = 0;
    originalRequest.forEach((request: any) => {
      if (request.landParcel.length) {
        request.landParcel.forEach((parcel: any) => {
          if (
            parcel.saleDeedStatus &&
            parcel.saleDeedStatus.toUpperCase() === "YES"
          ) {
            totalSaleDeed += parcel.parcelArea;
            if (
              parcel?.saleDeedCreatedDateTime &&
              moment(parcel?.saleDeedCreatedDateTime).format("YYYY") ==
                currentYear
            ) {
              currentYearSaleDeed += parcel.parcelArea;
            }
          }
        });
      }
    });
  } else if (isDateFilterApplied && isOtherFilterApplied) {
    let originalRequest1: any = JSON.parse(JSON.stringify(originalRequest));
    if (filterDataSet.businessNames.length) {
      let parcel = [];

      for (const request of originalRequest1) {
        parcel = request.landParcel.filter((parcel: any) => {
          return filterDataSet.businessNames.includes(
            Number(parcel.businessName)
          );
        });
        if (parcel.length) {
          request.landParcel = [...parcel];
        } else {
          request.landParcel = [];
        }
      }
    }

    if (filterDataSet.states.length) {
      let parcel = [];
      for (const request of originalRequest1) {
        parcel = request.landParcel.filter((parcel: any) => {
          return filterDataSet.states.includes(Number(parcel.state));
        });
        if (parcel.length) {
          request.landParcel = [...parcel];
        } else {
          request.landParcel = [];
        }
      }
    }

    if (filterDataSet.sites.length) {
      let parcel = [];
      for (const request of originalRequest1) {
        parcel = request.landParcel.filter((parcel: any) => {
          return filterDataSet.sites.includes(parcel.site);
        });
        if (parcel.length) {
          request.landParcel = [...parcel];
        } else {
          request.landParcel = [];
        }
      }
    }

    totalSaleDeed = 0;
    currentYearSaleDeed = 0;
    originalRequest1.forEach((request: any) => {
      if (request.landParcel.length) {
        request.landParcel.forEach((parcel: any) => {
          if (
            parcel.saleDeedStatus &&
            parcel.saleDeedStatus.toUpperCase() === "YES"
          ) {
            totalSaleDeed += parcel.parcelArea;
            if (
              parcel?.saleDeedCreatedDateTime &&
              moment(parcel?.saleDeedCreatedDateTime).format("YYYY") ==
                currentYear
            ) {
              currentYearSaleDeed += parcel.parcelArea;
            }
          }
        });
      }
    });
  } else if (isDateFilterApplied) {
    currentYearSaleDeed = 0;
    totalSaleDeed = 0;
    originalRequest.forEach((request: any) => {
      if (request.landParcel.length) {
        request.landParcel.forEach((parcel: any) => {
          if (
            parcel.saleDeedStatus &&
            parcel.saleDeedStatus.toUpperCase() === "YES"
          ) {
            totalSaleDeed += parcel.parcelArea;
            if (
              parcel?.saleDeedCreatedDateTime &&
              moment(parcel?.saleDeedCreatedDateTime).format("YYYY") ==
                currentYear
            ) {
              currentYearSaleDeed += parcel.parcelArea;
            }
          }
        });
      }
    });
  }

  saleDeedData.totalSaleDeed = totalSaleDeed;
  saleDeedData.last3MonthSaleDeed = last3MonthSaleDeed;
  saleDeedData.currentYearSaleDeed = currentYearSaleDeed;
  saleDeedData.currentMonthSaleDeed = currentMonthSaleDeed;
  saleDeedData.filterdDateSaleDeed = filterdDateSaleDeed;

  console.log("saleDeedData", saleDeedData);
  return saleDeedData;
};
