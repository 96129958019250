// @ts-nocheck
import { Link } from "react-router-dom";
import { landDetailsToggle, landParcelId } from "../../state/atom/gis";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect, useRef, useState } from "react";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { getUserDetails } from "../../utility/jwt";
import { JwtPayLoad } from "../../shared/jwt.interface";
import { checkPermission } from "../../utility/permission";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import { toaster } from "../../state/atom/toaster";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { customStyles } from "../../shared/landTypeStyle";
export const LandDetails = () => {
  const { user }: JwtPayLoad = getUserDetails();

  interface MultipleUploadFormInputs {
    landParcelsIds?: Array<{ value: string; label: string }>;
    fileType?: string;
    document?: string;
    village?: string;
  }

  const [landViewToggle, setLandDetailsToggle] =
    useRecoilState(landDetailsToggle);
  const [isShowMore, setIsShowMore] = useState(false);
  const [parcelId, setParcelId] = useRecoilState(landParcelId);
  const [area, setArea] = useState("");
  const [survey, setSurvey] = useState("");
  const [remarks, setRemark] = useState("");
  const [freeHoldArea, setFreeHoldArea] = useState("");
  const [usedArea, setUsedArea] = useState("");
  const [unusedArea, setUnusedArea] = useState("");
  const [leaseArea, setLeaseArea] = useState("");
  const setToasterStatus = useSetRecoilState(toaster);
  const [landData, setLandDetails] = useState({
    id: 0,
    business: {
      name: "",
    },
    company: {
      name: "",
    },
    companyCode: "",
    city: {
      city: "",
    },
    plantCode: "",
    state: {
      stateName: "",
    },
    district: {
      districtName: "",
    },
    village: "",
    surveyNo: "",
    site: "",
    tsrFile: [],
    naFile: [],
    mutationFile: [],
    parcelArea: "",
    landType: "",
    sellerName: "",
    acquiredStatus: "",
    saleDeedStatus: "",
    mutationStatus: "",
    naStatus: "",
    tsrStatus: "",
    freeHoldArea: "",
    saleDeedFile: [],
    leaseArea: "",
    usedArea: "",
    unusedArea: "",
    proposal: {
      id: "",
      proposalId: "",
      proposalName: "",
    },
    proposalNo: "",
    companyCodeSapCode: "",
    aggregatorLandSpoc: "",
    nickNameCompany: "",
    oldNameofCompany: "",
    typeOfConveyanceDeed: "",
    conveyanceDeedNo: "",
    registrationDate: "",
    subLeaseToCompany: "",
    subLeaseToCompanyCode: "",
    subLeaseFromCompany: "",
    subLeaseFromCompanyCode: "",
    ceilingPermission: "",
    revenueMapStatus: "",
    leasePeriodStartDate: "",
    leasePeriodEndDate: "",
    totalMortgagedArea: "",
    fileNo: "",
    mortgagedAt: "",
    mortgageStartDate: "",
    mortgageEndDate: "",
    litigation: "",
    caseNo: "",
    courtNameWithaddress: "",
    remarks: "",
    availabilityOfPhotocopy: "",
    storageLocation: "",
    floor: "",
    floorType: "",
    racks: "",
    shelfNo: "",
    statusOfGisMapping: "",
    statusOfSurvey: "",
    abuOrLemUserId: "",
    leSpocUserId: "",
    updatedDate: "",
    leDhUserId: "",
  });

  const [documentUploadLevel, setDocumentUploadLevel] = useState(0);
  useEffect(() => {
    if (parcelId) {
      setDocumentUploadLevel(0);
      setArea("");
      setSurvey("");
      setRemark("");
      setFreeHoldArea("");
      setLeaseArea("");
      setUsedArea("");
      setUnusedArea("");
      Http.request(
        "get",
        `${process.env.REACT_APP_API_URL}land-parcel/${parcelId}`,
        null,
        apiHeaders()
      )
        .then((result) => {
          setLandDetails(result.data.parcel);
          setArea(result.data.parcel.parcelArea);
          setSurvey(result.data.parcel.surveyNo);
          setRemark(result.data.parcel.remarks);
          setFreeHoldArea(result.data.parcel.freeHoldArea);
          setLeaseArea(result.data.parcel.leaseArea);
          setUsedArea(result.data.parcel.usedArea);
          setUnusedArea(result.data.parcel.unusedArea);
          if (result.data.parcel?.saleDeedFile) {
            setDocumentUploadLevel(1);
          } else {
            setDocumentUploadLevel(1);
            setLandDetails((landData) => {
              return {
                ...landData,
                saleDeedFile: [],
              };
            });
          }

          if (result.data.parcel.mutationFile) {
            setDocumentUploadLevel(2);
          } else {
            setDocumentUploadLevel(2);
            setLandDetails((landData) => {
              return {
                ...landData,
                mutationFile: [],
              };
            });
          }
          if (result.data.parcel.naFile) {
            setDocumentUploadLevel(3);
          } else {
            setDocumentUploadLevel(3);
            setLandDetails((landData) => {
              return {
                ...landData,
                naFile: [],
              };
            });
          }
          if (result.data.parcel.tsrFile) {
            setDocumentUploadLevel(4);
          } else {
            setDocumentUploadLevel(4);
            setLandDetails((landData) => {
              return {
                ...landData,
                tsrFile: [],
              };
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [parcelId]);

  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [uploadType, setUploadType] = useState("");

  /* const [cfmFileUpload, setConfirmFileUpload] = useState(false); */
  const [showCfmFileUpload, setShowConfirmFileUpload] = useState(false);
  const [uploadMultipleDoc, setUploadMultipleDoc] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [uploadLandParcelFileProgress, setuploadLandParcelFileProgress] =
    useState(false);

  const selectRef: any = useRef();
  const confirmFileUpload = (
    event: any,
    fileName: string,
    type: string = "",
    index: number = 0
  ) => {
    setFile(event.target.files[0]);
    setFileType(fileName);
    setUploadType(type);
    setShowConfirmFileUpload(true);
    setSelectedIndex(index);
  };

  const [parcelsDetails, setParcelsDetails] = useState([]);
  const [parcelsDetailsClone, setParcelsDetailsClone] = useState([]);
  const [villageList, setVillageList] = useState([]);
  const [village, setVillage] = useState("");
  const handleMultipleDoc = (e) => {
    if (selectRef.current) {
      selectRef.current.clearValue();
    }
    setVillage("");
    setFileType(e.target.value);
    setParcelsDetails([]);
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}land-parcel/proposal/${landData?.proposal?.id}/${e.target.value}`,
      null,
      apiHeaders()
    )
      .then((result) => {
        const village = result.data.parcel.map((parcel: any) => {
          return parcel.village;
        });

        setVillageList([...new Set(village)]);

        setParcelsDetails(
          result.data.parcel.map((parcel: any) => {
            return {
              label: parcel.surveyNo,
              value: parcel.id,
            };
          })
        );

        setParcelsDetailsClone(
          result.data.parcel.map((parcel: any) => {
            return {
              label: parcel.surveyNo,
              value: parcel.id,
              village: parcel.village,
            };
          })
        );
      })
      .catch((error) => {
        setParcelsDetails([]);
        console.log(error);
      });
  };

  const filterSurveyNo = (e) => {
    if (selectRef.current) {
      selectRef.current.clearValue();
    }
    if (e.target.value) {
      const parcelsDetails = parcelsDetailsClone.filter(
        (parcel) => parcel.village === e.target.value
      );
      setParcelsDetails(parcelsDetails);
    } else {
      setParcelsDetails(parcelsDetailsClone);
    }
  };

  const removeUploadedFile = () => {};

  const validationSchema = Yup.object().shape({
    landParcelsIds: Yup.array().required("Survey No. is required"),
    fileType: Yup.string().required("File type is required"),
    document: Yup.mixed().required("File is required"),
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<MultipleUploadFormInputs>({
    resolver: yupResolver(validationSchema),
  });

  const [formData, setFormData] = useState({
    siteLocation: null,
  });

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setFormData({
      siteLocation: file,
    });
  };

  const onErrors = (errors: any) => {
    console.log("Error", errors);
  };

  const onSubmit = (data: any) => {
    console.log(data);
    const form = new FormData();
    // @ts-expect-error
    form.append("document", formData.siteLocation);
    form.append(
      "landParcelsIds",
      data.landParcelsIds.map((parcel: any) => parcel.value).join()
    );
    form.append("fileType", data.fileType);

    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}land-parcel/multiple-landparcel-document`,
      form,
      apiHeaders()
    )
      .then((result) => {
        setParcelId("0");
        setLandDetailsToggle(false);
        reset();
        if (selectRef.current) {
          selectRef.current.clearValue();
        }
        setVillage("");
        setUploadMultipleDoc(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (!uploadMultipleDoc) {
      if (selectRef.current) {
        selectRef.current.clearValue();
      }
      setVillage("");
    }
  }, [uploadMultipleDoc]);

  // Handle Land Parcel file change
  const uploadLandParcelFile = () => {
    setuploadLandParcelFileProgress(true);
    const fileName = fileType;
    const formData = new FormData();
    if (file) {
      formData.append("document", file);
    }
    formData.append("landParcelsId", `${landData?.id}`);
    formData.append("fileType", fileName);
    setuploadLandParcelFileProgress(true);

    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}land-parcel/document`,
      formData,
      apiHeaders()
    )
      .then((result: any) => {
        setuploadLandParcelFileProgress(false);
        setShowConfirmFileUpload(false);
        setFile(null);

        if (fileName === "sale") {
          const newFilesArray = [...landData.saleDeedFile];
          newFilesArray.push({ url: result.url });
          setLandDetails((landData) => {
            return {
              ...landData,
              saleDeedStatus: "YES",
              acquiredStatus: "YES",
              saleDeedFile: newFilesArray,
            };
          });
          if (newFilesArray.length === 1) {
            setDocumentUploadLevel(1);
          }
        }

        if (fileName === "mutation") {
          const newFilesArray = [...landData.mutationFile];
          //newFilesArray[selectedIndex].url = result.url;
          newFilesArray.push({ url: result.url });
          setLandDetails((landData) => {
            return {
              ...landData,
              mutationStatus: "YES",
              mutationFile: newFilesArray,
            };
          });
          if (newFilesArray.length === 1) {
            setDocumentUploadLevel(2);
          }
        }

        if (fileName === "na") {
          const newFilesArray = [...landData.naFile];
          //newFilesArray[selectedIndex].url = result.url;
          newFilesArray.push({ url: result.url });
          setLandDetails((landData) => {
            return {
              ...landData,
              naStatus: "YES",
              naFile: newFilesArray,
            };
          });
          if (newFilesArray.length === 1) {
            setDocumentUploadLevel(3);
          }
        }

        if (fileName === "tsr") {
          const newFilesArray = [...landData.tsrFile];
          //newFilesArray[selectedIndex].url = result.url;
          newFilesArray.push({ url: result.url });
          setLandDetails((landData) => {
            return {
              ...landData,
              tsrStatus: "YES",
              tsrFile: newFilesArray,
            };
          });
          if (newFilesArray.length === 1) {
            setDocumentUploadLevel(4);
          }
        }
      })
      .catch((error: any) => {
        setuploadLandParcelFileProgress(false);
      });
  };

  const handleDownload = (file: string, name: string) => {
    console.log(file, name);
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "name.pdf");
    document.body.appendChild(link);
    link.click();
  };

  const updateLandDetails = (data: any) => {
    Http.request(
      "put",
      `${process.env.REACT_APP_API_URL}land-parcel/${landData.id}`,
      data,
      apiHeaders()
    )
      .then((result: any) => {
        setToasterStatus({
          status: true,
          title: "Land Details",
          text: "Land details updated successfully",
        });
      })
      .catch((error: any) => {});
  };

  const changeLandDetails = (e: any) => {
    if (e.target.name === "area") {
      setArea(e.target.value);
    }
    if (e.target.name === "survey") {
      setSurvey(e.target.value);
    }
    if (e.target.name === "remarks") {
      setRemark(e.target.value);
    }
    if (e.target.name === "freeHoldArea") {
      setFreeHoldArea(e.target.value);
    }
    if (e.target.name === "leaseArea") {
      setLeaseArea(e.target.value);
    }
    if (e.target.name === "usedArea") {
      setUsedArea(e.target.value);
    }
    if (e.target.name === "unusedArea") {
      setUnusedArea(e.target.value);
    }
  };

  const handleAddsaleDeedFiles = (action, index: number = 0, url: string) => {
    if (action === "delete") {
      if (
        landData.mutationFile.length >= 1 &&
        landData.saleDeedFile.length === 1
      ) {
        setToasterStatus({
          status: true,
          title: "Land Parcel",
          text: "At least one conveyance deed file required.",
        });
        return;
      }
      const newFilesArray = [...landData.saleDeedFile];
      newFilesArray.splice(index, 1);
      setLandDetails((landData) => {
        return {
          ...landData,
          saleDeedFile: newFilesArray,
          ...(newFilesArray.length === 0 && {
            saleDeedStatus: "NO",
            acquiredStatus: "NO",
          }),
        };
      });
      if (url) {
        const data = {
          fileName: url,
          fileType: "sale",
        };

        Http.request(
          "delete",
          `${process.env.REACT_APP_API_URL}land-parcel/${landData.id}`,
          data,
          apiHeaders()
        )
          .then((result: any) => {
            setToasterStatus({
              status: true,
              title: "Land Parcel",
              text: "File deleted successfully",
            });
          })
          .catch((error: any) => {});
      }
    } else {
      const newFilesArray = [...landData.saleDeedFile];
      newFilesArray.push({ url: "" });
      setLandDetails((landData) => {
        return {
          ...landData,
          saleDeedFile: newFilesArray,
        };
      });
    }
  };

  const handleAddMutuationFiles = (action, index: number = 0, url: string) => {
    if (action === "delete") {
      if (landData.naFile.length >= 1 && landData.mutationFile.length === 1) {
        setToasterStatus({
          status: true,
          title: "Land Parcel",
          text: "At least one mutation file file required.",
        });
        return;
      }
      const newFilesArray = [...landData.mutationFile];
      newFilesArray.splice(index, 1);
      setLandDetails((landData) => {
        return {
          ...landData,
          mutationFile: newFilesArray,
          ...(newFilesArray.length === 0 && {
            mutationStatus: "NO",
          }),
        };
      });
      if (url) {
        const data = {
          fileName: url,
          fileType: "mutation",
        };
        Http.request(
          "delete",
          `${process.env.REACT_APP_API_URL}land-parcel/${landData.id}`,
          data,
          apiHeaders()
        )
          .then((result: any) => {
            setToasterStatus({
              status: true,
              title: "Land Parcel",
              text: "File deleted successfully",
            });
          })
          .catch((error: any) => {});
      }
    } else {
      const newFilesArray = [...landData.mutationFile];
      newFilesArray.push({ url: "" });
      setLandDetails((landData) => {
        return {
          ...landData,
          mutationFile: newFilesArray,
        };
      });
    }
  };

  const handleAddNAFiles = (action, index: number = 0, url: string) => {
    if (action === "delete") {
      const newFilesArray = [...landData.naFile];
      newFilesArray.splice(index, 1);
      setLandDetails((landData) => {
        return {
          ...landData,
          naFile: newFilesArray,
          ...(newFilesArray.length === 0 && {
            naStatus: "NO",
          }),
        };
      });
      if (url) {
        const data = {
          fileName: url,
          fileType: "na",
        };
        Http.request(
          "delete",
          `${process.env.REACT_APP_API_URL}land-parcel/${landData.id}`,
          data,
          apiHeaders()
        )
          .then((result: any) => {
            setToasterStatus({
              status: true,
              title: "Land Parcel",
              text: "File deleted successfully",
            });
          })
          .catch((error: any) => {});
      }
    } else {
      const newFilesArray = [...landData.naFile];
      newFilesArray.push({ url: "" });
      setLandDetails((landData) => {
        return {
          ...landData,
          naFile: newFilesArray,
        };
      });
    }
  };

  const handleAddTSRFiles = (action, index: number = 0, url: string) => {
    if (action === "delete") {
      const newFilesArray = [...landData.tsrFile];
      newFilesArray.splice(index, 1);
      setLandDetails((landData) => {
        return {
          ...landData,
          tsrFile: newFilesArray,
          ...(newFilesArray.length === 0 && {
            tsrStatus: "NO",
          }),
        };
      });
      if (url) {
        const data = {
          fileName: url,
          fileType: "tsr",
        };
        Http.request(
          "delete",
          `${process.env.REACT_APP_API_URL}land-parcel/${landData.id}`,
          data,
          apiHeaders()
        )
          .then((result: any) => {
            setToasterStatus({
              status: true,
              title: "Land Parcel",
              text: "File deleted successfully",
            });
          })
          .catch((error: any) => {});
      }
    } else {
      const newFilesArray = [...landData.tsrFile];
      newFilesArray.push({ url: "" });
      setLandDetails((landData) => {
        return {
          ...landData,
          tsrFile: newFilesArray,
        };
      });
    }
  };

  const [showFileList, setshowFileList] = useState(false);
  const [fileTypeName, setFileTypeName] = useState("");
  const viewFile = (fileType) => {
    setshowFileList(true);
    setFileTypeName(fileType);
  };

  const saleDeedInputRef = useRef(null);
  const mutationInputRef = useRef(null);
  const naInputRef = useRef(null);
  const tsrInputRef = useRef(null);

  return (
    <>
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal
          show={showCfmFileUpload}
          onHide={() => {
            setShowConfirmFileUpload(false);
          }}
        >
          <Modal.Header closeButton className="toaster-header ">
            <Modal.Title>Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure want to upload this file?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => removeUploadedFile}>
              Cancel
            </Button>
            <Button onClick={() => uploadLandParcelFile()} variant="primary">
              {uploadLandParcelFileProgress ? "Uploading..." : "Upload"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal
          show={showFileList}
          onHide={() => {
            setshowFileList(false);
          }}
        >
          <Modal.Header closeButton className="toaster-header ">
            <Modal.Title>
              {fileTypeName === "sale" && "Conveyance Deed Files"}
              {fileTypeName === "mutation" && "Mutation Files"}
              {fileTypeName === "na" && "NA Files"}
              {fileTypeName === "tsr" && "TSR Files"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="file-list-view">
              {fileTypeName === "sale" &&
                landData.saleDeedFile.map((file: any, index: number) => {
                  return (
                    <div className="file-list-item">
                      {file.url && (
                        <>
                          <Link
                            target="_blank"
                            title="View File"
                            to={file.url}
                            className="view-file"
                          >
                            {file.url.split("/").filter(Boolean).pop()}
                          </Link>
                          &nbsp;
                          {checkPermission(
                            user?.role,
                            "gis",
                            "upload_document"
                          ) && (
                            <button
                              className="btn btn-secondary btn-xs"
                              type="button"
                              onClick={() =>
                                handleAddsaleDeedFiles(
                                  "delete",
                                  index,
                                  file.url
                                )
                              }
                            >
                              <i className="bi-trash-fill "></i>
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}

              {fileTypeName === "mutation" &&
                landData.mutationFile.map((file: any, index: number) => {
                  return (
                    <div className="file-list-item">
                      {file.url && (
                        <>
                          <Link
                            target="_blank"
                            title="View File"
                            to={file.url}
                            className="view-file"
                          >
                            {file.url.split("/").filter(Boolean).pop()}
                          </Link>
                          {checkPermission(
                            user?.role,
                            "gis",
                            "upload_document"
                          ) && (
                            <button
                              className="btn btn-secondary btn-xs"
                              type="button"
                              onClick={() =>
                                handleAddMutuationFiles(
                                  "delete",
                                  index,
                                  file.url
                                )
                              }
                            >
                              <i className="bi-trash-fill "></i>
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}

              {fileTypeName === "na" &&
                landData.naFile.map((file: any, index: number) => {
                  return (
                    <div className="file-list-item">
                      {file.url && (
                        <>
                          <Link
                            target="_blank"
                            title="View File"
                            to={file.url}
                            className="view-file"
                          >
                            {file.url.split("/").filter(Boolean).pop()}
                          </Link>
                          {checkPermission(
                            user?.role,
                            "gis",
                            "upload_document"
                          ) && (
                            <button
                              className="btn btn-secondary btn-xs"
                              type="button"
                              onClick={() =>
                                handleAddNAFiles("delete", index, file.url)
                              }
                            >
                              <i className="bi-trash-fill "></i>
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}

              {fileTypeName === "tsr" &&
                landData.tsrFile.map((file: any, index: number) => {
                  return (
                    <div className="file-list-item">
                      {file.url && (
                        <>
                          <Link
                            target="_blank"
                            title="View File"
                            to={file.url}
                            className="view-file"
                          >
                            {file.url.split("/").filter(Boolean).pop()}
                          </Link>
                          &nbsp;
                          {checkPermission(
                            user?.role,
                            "gis",
                            "upload_document"
                          ) && (
                            <button
                              className="btn btn-secondary btn-xs"
                              type="button"
                              onClick={() =>
                                handleAddTSRFiles("delete", index, file.url)
                              }
                            >
                              <i className="bi-trash-fill "></i>
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setshowFileList(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal
          show={uploadMultipleDoc}
          onHide={() => {
            if (selectRef.current) {
              selectRef.current.clearValue();
            }
            reset();
            setVillage("");
            setFileType("");
            setUploadMultipleDoc(false);
          }}
        >
          <form
            className="text-white"
            onSubmit={handleSubmit(onSubmit, onErrors)}
          >
            <Modal.Header closeButton className="toaster-header ">
              <Modal.Title>Upload Multiple Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group px-3 py-2">
                <label className="m-label">File Type*</label>
                <select
                  className="form-control"
                  {...register("fileType")}
                  value={fileType}
                  onChange={(e) => handleMultipleDoc(e)}
                >
                  <option value="">--Select--</option>
                  <option value="sale">Conveyance Deed</option>
                  <option value="mutation">Mutation</option>
                  <option value="na">NA Status</option>
                  <option value="tsr">TSR Status</option>
                </select>
                {errors.fileType && (
                  <span className="text-warning">
                    {errors.fileType.message}
                  </span>
                )}
              </div>
              <div className="form-group px-3 py-2">
                <label className="m-label">Village</label>
                <select
                  className="form-control"
                  {...register("village")}
                  value={village}
                  onChange={(e) => {
                    filterSurveyNo(e);
                    setVillage(e.target.value);
                  }}
                >
                  <option value="">--Select--</option>
                  {villageList?.map((village) => {
                    return <option value={village}>{village}</option>;
                  })}
                </select>
              </div>
              <div className="form-group px-3 py-2">
                <label className="m-label">Survey No*</label>
                <Controller
                  name="landParcelsIds"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isMulti
                      ref={selectRef}
                      options={parcelsDetails}
                      isClearable={true}
                      className="basic-multi-select"
                      placeholder="Select Survey No"
                      classNamePrefix="select"
                      styles={customStyles}
                    />
                  )}
                />
                {errors.landParcelsIds && (
                  <span className="text-warning">
                    {errors.landParcelsIds.message}
                  </span>
                )}
              </div>

              <div className="form-group px-3 py-2">
                <label className="m-label">Document File*</label>
                <input
                  className="form-control"
                  type="file"
                  accept="application/pdf"
                  {...register("document")}
                  onChange={handleFileChange}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" variant="primary">
                Upload
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>

      <div className="box">
        <div
          className={
            "new-request-init land-details-popup align-self-end " +
            (!landViewToggle ? "new-request-init--collapsed" : "")
          }
        >
          <div
            className="text-left panel-title"
            style={{ background: "#375db1" }}
          >
            <span
              style={{ color: "white", fontSize: "24px", paddingLeft: "40px" }}
            >
              Land Details
            </span>
            <span
              className="close-btn"
              id="new-request-close"
              onClick={() => setLandDetailsToggle(false)}
            >
              &times;
            </span>
          </div>
          <div style={{ overflowY: "auto", maxHeight: "85vh" }}>
            <h6 className="mb-0 panel-header">Land Details</h6>
            <table className="table land-parcel-details">
              <tbody>
                {checkPermission(user?.role, "gis", "upload_document") && (
                  <tr>
                    <th>Upload Document for Mupltiple Survey No</th>
                    <td>
                      <span
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => setUploadMultipleDoc(true)}
                      >
                        Upload
                      </span>
                    </td>
                  </tr>
                )}

                <tr>
                  <th>Last Update Date Time</th>
                  <td>
                    {landData?.updatedDate
                      ? moment(landData?.updatedDate).format(
                          "MMM Do YYYY, hh:mm:ss a"
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>Business Name</th>
                  <td>{landData?.business?.name}</td>
                </tr>
                <tr>
                  <th>Company Name</th>
                  <td>{landData?.company?.name}</td>
                </tr>
                <tr>
                  <th>Survey No</th>
                  <td>
                    {!checkPermission(user?.role, "gis", "upload_document") &&
                      landData?.surveyNo}
                    {checkPermission(user?.role, "gis", "upload_document") && (
                      <div className="land-details-field">
                        <input
                          value={survey}
                          name="survey"
                          onChange={changeLandDetails}
                        />
                        <span
                          className="material-icons a-link"
                          title="Save"
                          onClick={() => updateLandDetails({ survey: survey })}
                        >
                          save
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Area</th>
                  <td>
                    {!checkPermission(user?.role, "gis", "upload_document") &&
                      landData?.parcelArea}
                    {checkPermission(user?.role, "gis", "upload_document") && (
                      <div className="land-details-field">
                        <input
                          type=""
                          value={area}
                          name="area"
                          onChange={changeLandDetails}
                        />
                        <span
                          className="material-icons a-link"
                          title="Save"
                          onClick={() => updateLandDetails({ area: area })}
                        >
                          save
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Land Type</th>
                  <td>{landData?.landType}</td>
                </tr>
                <tr>
                  <th>Site Name</th>
                  <td>{landData?.site}</td>
                </tr>
                <tr>
                  <th>Village</th>
                  <td>{landData?.village}</td>
                </tr>
                <tr>
                  <th>State</th>
                  <td>{landData?.state?.stateName}</td>
                </tr>

                <tr>
                  <th>Acquired Status</th>
                  <td>{landData?.acquiredStatus}</td>
                </tr>
                <tr>
                  <th>Conveyance Deed Status</th>
                  <td className="file-upload-type">
                    {landData?.saleDeedStatus}
                    {landData.saleDeedFile.length > 0 && (
                      <span
                        className="a-link-with-underline "
                        type="button"
                        onClick={() => viewFile("sale")}
                      >
                        View Files
                      </span>
                    )}

                    {checkPermission(user?.role, "gis", "upload_document") &&
                      /* landData?.saleDeedStatus === "NO" && */
                      documentUploadLevel >= 0 && (
                        <>
                          <input
                            type="file"
                            accept="application/pdf"
                            id="sale-file"
                            ref={saleDeedInputRef}
                            style={{ display: "none" }}
                            onChange={(e) => {
                              console.log("Changed");
                              confirmFileUpload(e, "sale", "add", 0);
                              saleDeedInputRef.current.value = "";
                            }}
                          />
                          <label className="file-label" htmlFor="sale-file">
                            Upload File
                          </label>
                        </>
                      )}
                  </td>
                </tr>
                <tr>
                  <th>Mutation Status</th>
                  <td className="file-upload-type">
                    {landData?.mutationStatus}
                    {landData.mutationFile.length > 0 && (
                      <span
                        className="a-link-with-underline "
                        type="button"
                        onClick={() => viewFile("mutation")}
                      >
                        View Files
                      </span>
                    )}
                    {checkPermission(user?.role, "gis", "upload_document") &&
                      landData?.saleDeedFile.length > 0 && (
                        <>
                          <input
                            type="file"
                            accept="application/pdf"
                            id="mutation-file"
                            ref={mutationInputRef}
                            style={{ display: "none" }}
                            onChange={(e) => {
                              confirmFileUpload(e, "mutation", "add", 0);
                              mutationInputRef.current.value = "";
                            }}
                          />
                          <label className="file-label" htmlFor="mutation-file">
                            Upload File
                          </label>
                        </>
                      )}
                  </td>
                </tr>
                <tr>
                  <th>NA Status</th>
                  <td className="file-upload-type">
                    {landData?.naStatus}
                    {landData.naFile.length > 0 && (
                      <span
                        className="a-link-with-underline "
                        type="button"
                        onClick={() => viewFile("na")}
                      >
                        View Files
                      </span>
                    )}
                    {checkPermission(user?.role, "gis", "upload_document") &&
                      landData?.mutationFile.length > 0 && (
                        <>
                          <input
                            type="file"
                            accept="application/pdf"
                            id="na-file"
                            ref={naInputRef}
                            style={{ display: "none" }}
                            onChange={(e) => {
                              confirmFileUpload(e, "na", "add", 0);
                              naInputRef.current.value = "";
                            }}
                          />
                          <label className="file-label" htmlFor="na-file">
                            Upload File
                          </label>
                        </>
                      )}
                  </td>
                  {/* <td className="file-upload-type">
                    {landData?.naStatus}
                    {checkPermission(user?.role, "gis", "upload_document") &&
                      landData?.naStatus === "NO" &&
                      documentUploadLevel === 2 && (
                        <>
                          <input
                            type="file"
                            accept="application/pdf"
                            id="na-file"
                            style={{ display: "none" }}
                            onChange={(e) => confirmFileUpload(e, "na", "add")}
                          />
                          <label className="file-label" htmlFor="na-file">
                            Select File
                          </label>
                        </>
                      )}
                    {landData?.naStatus === "YES" && (
                      <>
                        {checkPermission(
                          user?.role,
                          "gis",
                          "upload_document"
                        ) && (
                          <>
                            <input
                              type="file"
                              accept="application/pdf"
                              id="na-file"
                              style={{ display: "none" }}
                              onChange={(e) =>
                                confirmFileUpload(e, "na", "edit")
                              }
                            />
                            <label className="file-label" htmlFor="na-file">
                              Change File
                            </label>
                          </>
                        )}

                        <Link
                          target="_blank"
                          title="View File"
                          style={{ color: "#fff" }}
                          to={landData?.naFile}
                          className="view-file"
                        >
                          <i className="material-icons opacity-10 pe-1">
                            visibility
                          </i>
                        </Link>
                      </>
                    )}
                  </td> */}
                </tr>
                <tr>
                  <th>TSR Status</th>
                  <td className="file-upload-type">
                    {landData?.tsrStatus}
                    {landData.tsrFile.length > 0 && (
                      <span
                        className="a-link-with-underline"
                        type="button"
                        onClick={() => viewFile("tsr")}
                      >
                        View Files
                      </span>
                    )}
                    {checkPermission(user?.role, "gis", "upload_document") && (
                      <>
                        <input
                          type="file"
                          accept="application/pdf"
                          id="tsr-file"
                          ref={tsrInputRef}
                          style={{ display: "none" }}
                          onChange={(e) => {
                            confirmFileUpload(e, "tsr", "add", 0);
                            tsrInputRef.current.value = "";
                          }}
                        />
                        <label className="file-label" htmlFor="tsr-file">
                          Upload File
                        </label>
                      </>
                    )}
                  </td>
                  {/*<td className="file-upload-type">
                    {landData?.tsrStatus}
                    {checkPermission(user?.role, "gis", "upload_document") &&
                      landData?.tsrStatus === "NO" &&
                      documentUploadLevel === 3 && (
                        <>
                          <input
                            type="file"
                            accept="application/pdf"
                            id="tsr-file"
                            style={{ display: "none" }}
                            onChange={(e) => confirmFileUpload(e, "tsr", "add")}
                          />
                          <label className="file-label" htmlFor="tsr-file">
                            Select file
                          </label>
                        </>
                      )}
                    {landData?.tsrStatus === "YES" && (
                      <>
                        {checkPermission(
                          user?.role,
                          "gis",
                          "upload_document"
                        ) && (
                          <>
                            <input
                              type="file"
                              accept="application/pdf"
                              id="tsr-file"
                              style={{ display: "none" }}
                              onChange={(e) =>
                                confirmFileUpload(e, "tsr", "edit")
                              }
                            />
                            <label className="file-label" htmlFor="tsr-file">
                              Change file
                            </label>
                          </>
                        )}

                        <Link
                          target="_blank"
                          title="View File"
                          style={{ color: "#fff" }}
                          to={landData?.tsrFile}
                          className="view-file"
                        >
                          <i className="material-icons opacity-10 pe-1">
                            visibility
                          </i>
                        </Link>
                      </>
                    )}
                            </td>*/}
                </tr>
                <tr>
                  <th>Free Hold Area</th>
                  <td>
                    {!checkPermission(user?.role, "gis", "upload_document") &&
                      landData?.freeHoldArea}
                    {checkPermission(user?.role, "gis", "upload_document") && (
                      <div className="land-details-field">
                        <input
                          type=""
                          value={freeHoldArea}
                          name="freeHoldArea"
                          onChange={changeLandDetails}
                        />
                        <span
                          className="material-icons a-link"
                          title="Save"
                          onClick={() =>
                            updateLandDetails({ freeHoldArea: freeHoldArea })
                          }
                        >
                          save
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Lease Area</th>
                  <td>
                    {!checkPermission(user?.role, "gis", "upload_document") &&
                      landData?.leaseArea}
                    {checkPermission(user?.role, "gis", "upload_document") && (
                      <div className="land-details-field">
                        <input
                          type=""
                          value={leaseArea}
                          name="leaseArea"
                          onChange={changeLandDetails}
                        />
                        <span
                          className="material-icons a-link"
                          title="Save"
                          onClick={() =>
                            updateLandDetails({ leaseArea: leaseArea })
                          }
                        >
                          save
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Remarks</th>
                  {/*  <td>{landData?.remarks}</td> */}
                  <td>
                    {!checkPermission(user?.role, "gis", "upload_document") &&
                      landData?.remarks}
                    {checkPermission(user?.role, "gis", "upload_document") && (
                      <div className="land-details-field">
                        <textarea
                          value={remarks}
                          name="remarks"
                          onChange={changeLandDetails}
                        />
                        <span
                          className="material-icons a-link"
                          title="Save"
                          onClick={() =>
                            updateLandDetails({ remarks: remarks })
                          }
                        >
                          save
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th>
                    <span
                      className="a-link"
                      onClick={() => setIsShowMore(!isShowMore)}
                    >
                      {isShowMore ? "Show Less" : "Show More"}
                    </span>{" "}
                  </th>
                </tr>
                {isShowMore && (
                  <>
                    <tr>
                      <th>Used Area</th>
                      <td>
                        {!checkPermission(
                          user?.role,
                          "gis",
                          "upload_document"
                        ) && landData?.usedArea}
                        {checkPermission(
                          user?.role,
                          "gis",
                          "upload_document"
                        ) && (
                          <div className="land-details-field">
                            <input
                              type=""
                              value={usedArea}
                              name="usedArea"
                              onChange={changeLandDetails}
                            />
                            <span
                              className="material-icons a-link"
                              title="Save"
                              onClick={() =>
                                updateLandDetails({ usedArea: usedArea })
                              }
                            >
                              save
                            </span>
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Un Used Area</th>
                      <td>
                        {!checkPermission(
                          user?.role,
                          "gis",
                          "upload_document"
                        ) && landData?.unusedArea}
                        {checkPermission(
                          user?.role,
                          "gis",
                          "upload_document"
                        ) && (
                          <div className="land-details-field">
                            <input
                              type=""
                              value={unusedArea}
                              name="unusedArea"
                              onChange={changeLandDetails}
                            />
                            <span
                              className="material-icons a-link"
                              title="Save"
                              onClick={() =>
                                updateLandDetails({ unusedArea: unusedArea })
                              }
                            >
                              save
                            </span>
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Company Code Sap Code</th>
                      <td>{landData?.companyCodeSapCode || ""}</td>
                    </tr>
                    <tr>
                      <th>Aggregator Land Spoc</th>
                      <td>{landData?.aggregatorLandSpoc}</td>
                    </tr>
                    <tr>
                      <th>Nick Name Company</th>
                      <td>{landData?.nickNameCompany}</td>
                    </tr>
                    <tr>
                      <th>Old Name of Company</th>
                      <td>{landData?.oldNameofCompany}</td>
                    </tr>
                    <tr>
                      <th>Type Of Conveyance Deed</th>
                      <td>{landData?.typeOfConveyanceDeed}</td>
                    </tr>
                    <tr>
                      <th>Conveyance Deed No</th>
                      <td>{landData?.conveyanceDeedNo}</td>
                    </tr>
                    <tr>
                      <th>Registration Date</th>
                      <td>{landData?.registrationDate}</td>
                    </tr>
                    <tr>
                      <th>Sub Lease To Company</th>
                      <td>{landData?.subLeaseToCompany}</td>
                    </tr>
                    <tr>
                      <th>Sub Lease To Company Code</th>
                      <td>{landData?.subLeaseToCompanyCode}</td>
                    </tr>
                    <tr>
                      <th>Sub Lease From Company</th>
                      <td>{landData?.subLeaseFromCompany}</td>
                    </tr>
                    <tr>
                      <th>SubLease From Company Code</th>
                      <td>{landData?.subLeaseFromCompanyCode}</td>
                    </tr>
                    <tr>
                      <th>Ceiling Permission</th>
                      <td>{landData?.ceilingPermission}</td>
                    </tr>
                    <tr>
                      <th>Revenue Map Status</th>
                      <td>{landData?.revenueMapStatus}</td>
                    </tr>
                    <tr>
                      <th>Lease Period Start Date</th>
                      <td>{landData?.leasePeriodStartDate}</td>
                    </tr>
                    <tr>
                      <th>Lease Period End Date</th>
                      <td>{landData?.leasePeriodEndDate}</td>
                    </tr>
                    <tr>
                      <th>Total Mortgaged Area</th>
                      <td>{landData?.totalMortgagedArea}</td>
                    </tr>
                    <tr>
                      <th>File No</th>
                      <td>{landData?.fileNo}</td>
                    </tr>
                    <tr>
                      <th>Mortgaged At</th>
                      <td>{landData?.mortgagedAt}</td>
                    </tr>
                    <tr>
                      <th>Mortgage Start Date</th>
                      <td>{landData?.mortgageStartDate}</td>
                    </tr>
                    <tr>
                      <th>Mortgage End Date</th>
                      <td>{landData?.mortgageEndDate}</td>
                    </tr>
                    <tr>
                      <th>Litigation</th>
                      <td>{landData?.litigation}</td>
                    </tr>
                    <tr>
                      <th>Case No</th>
                      <td>{landData?.caseNo}</td>
                    </tr>
                    <tr>
                      <th>Court Name With Address</th>
                      <td>{landData?.courtNameWithaddress}</td>
                    </tr>
                    <tr>
                      <th>Availability Of Photocopy</th>
                      <td>{landData?.availabilityOfPhotocopy}</td>
                    </tr>
                    <tr>
                      <th>Storage Location</th>
                      <td>{landData?.storageLocation}</td>
                    </tr>
                    <tr>
                      <th>Floor</th>
                      <td>{landData?.floor}</td>
                    </tr>
                    <tr>
                      <th>Floor Type</th>
                      <td>{landData?.floorType}</td>
                    </tr>
                    <tr>
                      <th>Racks</th>
                      <td>{landData?.racks}</td>
                    </tr>
                    <tr>
                      <th>Shelf No</th>
                      <td>{landData?.shelfNo}</td>
                    </tr>
                    <tr>
                      <th>GIS Mapping Status</th>
                      <td>{landData?.statusOfGisMapping}</td>
                    </tr>
                    <tr>
                      <th>Survey Status</th>
                      <td>{landData?.statusOfSurvey}</td>
                    </tr>
                    <tr>
                      <th>Proposal ID</th>
                      <td>{landData?.proposal?.proposalId}</td>
                    </tr>
                    <tr>
                      <th>Proposal Name</th>
                      <td>{landData?.proposal?.proposalName}</td>
                    </tr>
                    <tr>
                      <th>Company Code</th>
                      <td>{landData?.companyCode}</td>
                    </tr>
                    <tr>
                      <th>Plant Code</th>
                      <td>{landData?.plantCode}</td>
                    </tr>
                    <tr>
                      <th>District</th>
                      <td>{landData?.district?.districtName}</td>
                    </tr>
                    <tr>
                      <th>Tehshil</th>
                      <td>{landData?.city?.city}</td>
                    </tr>
                    <tr>
                      <th>Seller Name</th>
                      <td>{landData?.sellerName}</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
