// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import { Http } from "../../utility/http";
import { Business, State } from "../../shared/generic.interface";
import { useRecoilState, useSetRecoilState } from "recoil";
import { gisFeatureLayerFilter, gisList } from "../../state/atom/gis";
import { getLandSummary } from "../../shared/gis";
import {
  dashboardSummary,
  dashboardSummaryLoading,
  dashboardSummaryOptimized,
} from "../../state/atom/dashboard";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { apiHeaders } from "../../utility/generic";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { capitalizeString } from "../../shared/generic.constant";

interface DashboardFilterFormInputs {
  //businessName: string;
  //state: string;
  project: string;
  start_date: string | null;
  end_date: string | null;
}
export const DashboardFilter = () => {
  const [states, setState] = useState<State[]>([]);
  const [gisFilterData, setGisFilterData] = useState<any>([]);
  /* let [gisFeatureLayerFilterData, setGisFeatureLayerFilter] = useRecoilState(
    gisFeatureLayerFilter
  ); */
  const [dashboardSummaryData, setDashboardSummaryData] = useRecoilState(
    dashboardSummaryOptimized
  );
  const [gisFilterDataClone, setGisFilterDataClone] = useState<any>([]);
  const setdashboardSummary = useSetRecoilState(dashboardSummary);
  const [projects, setProject] = useState<any[]>([]);
  const setDashboardSummaryLoader = useSetRecoilState(dashboardSummaryLoading);
  const [business, setBusiness] = useState<Business[]>([]);
  const [gis, setGisList] = useRecoilState(gisList);
  const isStateUpdated = useRef(false);
  const validationSchema = Yup.object().shape({
    //businessName: Yup.string().notRequired(),
    //state: Yup.string().notRequired(),
    project: Yup.string().notRequired(),
    start_date: Yup.string().notRequired(),
    end_date: Yup.string().notRequired(),
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<DashboardFilterFormInputs>({
    resolver: yupResolver(validationSchema),
  });

  const [filterDataSet, setFilterDataSet] = useState({
    businessNames: [],
    states: [],
    sites: [],
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    Http.request("get", `${process.env.REACT_APP_API_URL}generic/state`)
      .then((result: any) => {
        setState(result.data.state);
      })
      .catch((error: any) => {});
  }, []);
  useEffect(() => {
    if (!isStateUpdated.current && gis?.data?.request.length) {
      const landSummary = getLandSummary(gis.data.request, gis.data.request);
      setdashboardSummary(landSummary);
      isStateUpdated.current = true;
    }
  }, [isStateUpdated, gis, setdashboardSummary]);

  useEffect(() => {
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}land-parcel/filter-data`,
      null,
      apiHeaders()
    )
      .then((result: any) => {
        setGisFilterData(result.data.request);
        setGisFilterDataClone(result.data.request);
        createFilterData(result.data.request);
      })
      .catch((error: any) => {});
  }, [setGisFilterData]);

  const onSubmit = (data: DashboardFilterFormInputs) => {
    const startDate = data?.start_date
      ? moment(data?.start_date, "DD/MM/YYYY").format("YYYY-MM-DD")
      : "";

    const endDate = data?.end_date
      ? moment(data?.end_date, "DD/MM/YYYY").format("YYYY-MM-DD")
      : "";
    setDashboardSummaryLoader(true);
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}land-parcel/optimized`,
      {
        startDate,
        endDate,
        filterDataSet,
      },
      apiHeaders()
    )
      .then((result) => {
        setDashboardSummaryLoader(false);
        setDashboardSummaryData(result.data);
      })
      .catch((error) => {});
  };

  const filterData = (e: any, field: string, value: any = "") => {
    let filterData: any = gisFilterDataClone;
    if (e?.target) {
      //let filters = { ...appliedFilters };
      let filterDataTemp = { ...filterDataSet };
      if (field === "business") {
        let bNames = filterDataTemp.businessNames;
        if (e?.target?.checked) {
          bNames.push(value);
        } else {
          bNames = bNames.filter((i) => i != value);
        }
        filterDataTemp = {
          ...filterDataSet,
          businessNames: bNames,
        };
        setFilterDataSet(filterDataTemp);
      }
      if (field === "state") {
        let sNames = filterDataTemp.states;
        if (e?.target?.checked) {
          sNames.push(value);
        } else {
          sNames = sNames.filter((i) => i != value);
        }
        filterDataTemp = {
          ...filterDataSet,
          states: sNames,
        };
        setFilterDataSet(filterDataTemp);
      }
      if (field === "project") {
        let sites = filterDataTemp.sites;
        if (e?.target?.checked) {
          sites.push(value);
        } else {
          sites = sites.filter((i) => i != value);
        }
        filterDataTemp = {
          ...filterDataSet,
          sites: sites,
        };
        setFilterDataSet(filterDataTemp);
      }

      if (field === "startDate") {
        filterDataTemp = {
          ...filterDataSet,
          startDate: moment(value, "DD/MM/YYYY").format("YYYY-MM-DD"),
        };
        setFilterDataSet(filterDataTemp);
      }

      if (field === "endDate") {
        filterDataTemp = {
          ...filterDataSet,
          endDate: moment(value, "DD/MM/YYYY").format("YYYY-MM-DD"),
        };
        setFilterDataSet(filterDataTemp);
      }

      console.log("filterDataTemp", filterDataTemp);

      if (filterDataTemp.businessNames.length) {
        filterData = filterData.filter((request: any) => {
          return filterDataTemp.businessNames.includes(
            Number(request?.business?.id)
          );
        });
        createFilterData(filterData, "business");
      }

      if (filterDataTemp.states.length) {
        filterData = filterData.filter((request: any) => {
          return filterDataTemp.states.includes(Number(request?.state?.id));
        });
        createFilterData(filterData, "state");
      }

      if (filterDataTemp.sites.length) {
        filterData = filterData.filter((request: any) => {
          return filterDataTemp.sites.includes(request.site);
        });
        createFilterData(filterData, "site");
      }

      if (filterDataTemp.startDate && filterDataTemp.endDate) {
        filterData = filterData.filter((request: any) => {
          if (request.saleDeedCreatedDateTime) {
            return moment(request.saleDeedCreatedDateTime).isBetween(
              filterDataTemp.startDate,
              filterDataTemp.endDate
            );
          }
        });
        console.log("filterDatax", filterData);
        createFilterData(filterData, "business");
        createFilterData(filterData, "state");
        createFilterData(filterData, "site");
      } else if (filterDataTemp.startDate) {
        filterData = filterData.filter((request: any) => {
          if (request.saleDeedCreatedDateTime) {
            return moment(request.saleDeedCreatedDateTime).isAfter(
              filterDataTemp.startDate
            );
          }
        });
        console.log("filterDatax", filterData);
        createFilterData(filterData, "business");
        createFilterData(filterData, "state");
        createFilterData(filterData, "site");
      } else if (filterDataTemp.endDate) {
        filterData = filterData.filter((request: any) => {
          if (request.saleDeedCreatedDateTime) {
            return moment(request.saleDeedCreatedDateTime).isBefore(
              filterDataTemp.endDate
            );
          }
        });
        console.log("filterDatax", filterData);
        createFilterData(filterData, "business");
        createFilterData(filterData, "state");
        createFilterData(filterData, "site");
      }

      setGisFilterData(filterData);
    }
  };

  const createFilterData = (data: any, skip: string = "") => {
    const businessData = [];
    if (skip !== "business") {
      for (const parcel of data) {
        if (parcel?.business) {
          const index = businessData.findIndex(
            (business) => business.id === parcel.business.id
          );
          if (index === -1) {
            businessData.push(parcel.business);
          }
        }
      }
      setBusiness(businessData.sort((a, b) => a.name.localeCompare(b.name)));
    }

    if (skip !== "state") {
      const states = [];
      for (const parcel of data) {
        if (parcel?.state) {
          const index = states.findIndex(
            (state) => state.id === parcel.state.id
          );
          if (index === -1) {
            states.push(parcel.state);
          }
        }
      }
      setState(states.sort((a, b) => a.stateName.localeCompare(b.stateName)));
    }
    if (skip !== "site") {
      const sites = [];
      for (const parcel of data) {
        const index = sites.findIndex((site) => site === parcel.site);
        if (index === -1) {
          sites.push(parcel.site);
        }
      }

      setProject(
        sites.sort((a, b) => {
          const lowerA = a.toLowerCase();
          const lowerB = b.toLowerCase();

          if (lowerA < lowerB) return -1;
          if (lowerA > lowerB) return 1;
          return a.localeCompare(b);
        })
      );
    }

    setGisFilterData(filterData);
  };

  const compare = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.band.toUpperCase();
    const bandB = b.band.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  };

  useEffect(() => {
    return () => {
      setFilterDataSet({
        businessNames: [],
        sites: [],
        states: [],
        startDate: "",
        endDate: "",
      });
    };
  }, []);

  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);

  const [showSelectedBusiness, setshowSelectedBusiness] = useState("");
  useEffect(() => {
    if (filterDataSet?.businessNames.length) {
      const businessId = filterDataSet?.businessNames[0];
      const selectedBusiness = business.find((b) => b.id === businessId);
      if (selectedBusiness) {
        if (filterDataSet?.businessNames.length > 1) {
          setshowSelectedBusiness(
            `${selectedBusiness.name} and ${
              filterDataSet?.businessNames.length - 1
            } other`
          );
        } else {
          setshowSelectedBusiness(`${selectedBusiness.name}`);
        }
      }
    } else {
      setshowSelectedBusiness(``);
    }
  }, [business, filterDataSet]);

  const [showSelectedState, setshowSelectedState] = useState("");
  useEffect(() => {
    if (filterDataSet?.states.length) {
      const stateId = filterDataSet?.states[0];
      const selectedState = states.find((b) => b.id === stateId);
      if (selectedState) {
        if (filterDataSet?.states.length > 1) {
          setshowSelectedState(
            `${selectedState.stateName} and ${
              filterDataSet?.states.length - 1
            } other`
          );
        } else {
          setshowSelectedState(`${selectedState.stateName}`);
        }
      }
    } else {
      setshowSelectedState(``);
    }
  }, [states, filterDataSet]);

  const [showSelectedSite, setshowSelectedSite] = useState("");
  useEffect(() => {
    if (filterDataSet?.sites.length) {
      if (filterDataSet?.sites.length > 1) {
        setshowSelectedSite(
          `${filterDataSet?.sites[0]} and ${
            filterDataSet?.sites.length - 1
          } other`
        );
      } else {
        setshowSelectedSite(`${filterDataSet?.sites[0]}`);
      }
    } else {
      setshowSelectedSite(``);
    }
  }, [filterDataSet]);

  const [businessFilterInput, handleBusinessDropDown] = useState("");
  const [siteFilterInput, handleSiteDropDown] = useState("");
  const [stateFilterInput, handleStateDropDown] = useState("");

  const resetDashboard = () => {
    setGisFilterData(gisFilterDataClone);
    createFilterData(gisFilterDataClone);
    handleBusinessDropDown("");
    handleSiteDropDown("");
    handleStateDropDown("");
    setFilterDataSet({
      businessNames: [],
      sites: [],
      states: [],
      startDate: "",
      endDate: "",
    });
    setStartDate(null);
    setEndDate(null);
    reset();
    setDashboardSummaryLoader(true);
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}land-parcel/optimized`,
      null,
      apiHeaders()
    )
      .then((result) => {
        setDashboardSummaryLoader(false);
        setDashboardSummaryData(result.data);
      })
      .catch((error) => {});
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="filter-form">
        <div className="filter-row" /* style={{ alignItems: "baseline" }} */>
          <div className="filter-item">
            <div className="form-group mb-2">
              <div className="btn-group">
                <button
                  className="btn btn-default dashboard-content-border dropdown-toggle"
                  type="button"
                  id="business-filter"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  {showSelectedBusiness
                    ? showSelectedBusiness
                    : "---Select Business---"}
                </button>
                <ul
                  className="dropdown-menu  dropdown-menu-content"
                  aria-labelledby="business-filter"
                >
                  <li>
                    <input
                      onChange={(e) => handleBusinessDropDown(e.target.value)}
                      value={businessFilterInput}
                      placeholder="search..."
                    ></input>
                  </li>
                  {business.map((unit, index) => {
                    return (
                      unit.name
                        .toLowerCase()
                        .indexOf(businessFilterInput?.toLowerCase()) !== -1 && (
                        <li className="checkbox dropdown-item">
                          <label>
                            <Controller
                              name={`businessName[${index}]`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="checkbox"
                                  value={unit.id}
                                  checked={
                                    filterDataSet?.businessNames.includes(
                                      unit.id
                                    )
                                      ? true
                                      : false
                                  }
                                  {...field}
                                  onChange={(event) =>
                                    filterData(event, "business", unit.id)
                                  }
                                />
                              )}
                            />
                            &nbsp;
                            {unit.name}
                          </label>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="filter-item">
            <div className="form-group mb-2">
              <div className="btn-group">
                <button
                  className="btn btn-default dashboard-content-border dropdown-toggle"
                  type="button"
                  id="state-filter"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  {showSelectedState ? showSelectedState : "---Select State---"}
                </button>
                <ul
                  className="dropdown-menu  dropdown-menu-content"
                  aria-labelledby="state-filter"
                >
                  <li>
                    <input
                      onChange={(e) => handleStateDropDown(e.target.value)}
                      value={stateFilterInput}
                      placeholder="search..."
                    ></input>
                  </li>
                  {states.map((state, index) => {
                    return (
                      state.stateName
                        .toLowerCase()
                        .indexOf(stateFilterInput?.toLowerCase()) !== -1 && (
                        <li className="checkbox dropdown-item">
                          <label>
                            <Controller
                              name={`state[${index}]`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="checkbox"
                                  value={state.id}
                                  checked={
                                    filterDataSet?.states.includes(state.id)
                                      ? true
                                      : false
                                  }
                                  {...field}
                                  onChange={(event) =>
                                    filterData(event, "state", state.id)
                                  }
                                />
                              )}
                            />
                            &nbsp;
                            {capitalizeString(state.stateName)}
                          </label>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="filter-item">
            <div className="form-group mb-2">
              <div className="btn-group">
                <button
                  className="btn btn-default dashboard-content-border dropdown-toggle"
                  type="button"
                  id="site-filter"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  {showSelectedSite ? showSelectedSite : "---Select Site---"}
                </button>
                <ul
                  className="dropdown-menu  dropdown-menu-content"
                  aria-labelledby="state-filter"
                >
                  <li>
                    <input
                      onChange={(e) => handleSiteDropDown(e.target.value)}
                      value={siteFilterInput}
                      placeholder="search..."
                    ></input>
                  </li>
                  {projects.map((project, index) => {
                    return (
                      project
                        .toLowerCase()
                        .indexOf(siteFilterInput?.toLowerCase()) !== -1 && (
                        <li className="checkbox dropdown-item">
                          <label>
                            <Controller
                              name={`site[${index}]`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="checkbox"
                                  value={project}
                                  checked={
                                    filterDataSet?.sites.includes(project)
                                      ? true
                                      : false
                                  }
                                  {...field}
                                  onChange={(event) =>
                                    filterData(event, "project", project)
                                  }
                                />
                              )}
                            />
                            &nbsp;
                            {project}
                          </label>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          <div className="filter-item">
            <div className="form-group mb-2 date-picker-field">
              <Controller
                name="start_date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    className="form-control"
                    autoComplete="off"
                    selected={startDate}
                    onChange={(e: any) => {
                      field.onChange(
                        moment(e.toLocaleDateString()).format("DD/MM/YYYY")
                      );
                      setStartDate(e);
                      const eventx = { target: true };
                      filterData(eventx, "startDate", e);
                    }}
                    startDate={startDate}
                    showYearDropdown
                    showMonthDropdown
                    endDate={endDate}
                    selectsStart
                    placeholderText="---Start Date---"
                  />
                )}
              />
            </div>
          </div>
          <div className="filter-item">
            <div className="form-group mb-2 date-picker-field">
              <Controller
                name="end_date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    className="form-control"
                    autoComplete="off"
                    selected={endDate}
                    onChange={(e: any) => {
                      field.onChange(
                        moment(e.toLocaleDateString()).format("DD/MM/YYYY")
                      );
                      setEndDate(endDate);
                      const eventx = { target: true };
                      filterData(eventx, "endDate", e);
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    showYearDropdown
                    showMonthDropdown
                    minDate={startDate}
                    placeholderText="---End Date---"
                  />
                )}
              />
            </div>
            {errors.end_date && (
              <span className="text-danger">{errors.end_date.message}</span>
            )}
          </div>
          <div className="filter-item">
            <div className="form-group mb-2" style={{ display: "flex" }}>
              <button type="submit" className="bg-gradient-primary-login btn2">
                Apply Filter
              </button>
              &nbsp;
              <button
                style={{ background: "#0d6efd" }}
                type="button"
                onClick={() => {
                  resetDashboard();
                }}
                className=" btn-primary btn2 fw-bold"
              >
                Reset Filter
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
