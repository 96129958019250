import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { getUserDetails } from "./utility/jwt";
import { JwtPayLoad } from "./shared/jwt.interface";
interface Props {
  children?: ReactNode;
}
const Protected = ({ children }: Props): JSX.Element => {
  const result: JwtPayLoad = getUserDetails();
  if (!result.isLoggedIn) {
    return <Navigate to="/" replace />;
  }
  return <>{children}</>;
};
export default Protected;
