import { atom } from "recoil";

export const reportToggle = atom({
  key: "reportToggle",
  default: false,
});

export const msiFilterToggle = atom({
  key: "msiFilterToggle",
  default: false,
});
export const forceRender = atom({
  key: "forceRender",
  default: "",
});

export const misReport = atom({
  key: "misReport",
  dangerouslyAllowMutability: true,
  default: {
    data: {
      landRequest: [] as any,
    },
  },
});
