import { IRequestModifiedStatus, Request } from "./request.interface";

export const RequestStatus: Request = {
  INPROGRESS: "in_progress",
  PENDING: "pending",
  CLOSED: "completed",
  HOLD: "hold",
  RESUME: "resume",
};

export const RequestStatusMap = {
  in_progress: "In Progress",
  pending: "Pending",
  completed: "Closed",
  hold: "On Hold",
  resume: "Resume",
  acquisition: "Acquisition",
};

export const RequestStatusClassMap = {
  in_progress: "in-progress-request",
  pending: "pending-request",
  completed: "complete-request",
  hold: "hold-request",
  resume: "resume-request",
  acquisition: "acquisition-request",
};

export const RequestModifiedStatus: IRequestModifiedStatus = {
  in_progress: "In Progress",
  pending: "Pending",
  proposal_assigned: "Proposal assigned",
  acquisition: "Acquisition",
  completed: "Completed"
};


export const RequestModifiedStatusClassMap = {
  in_progress: "in-progress-request",
  pending: "pending-request",
  proposal_assigned: "proposal-assigned",
  acquisition: "acquisition-request",
  completed: "completed-request"
};