// imports
import { useRecoilState } from "recoil";
import { myProfileToggle } from "../state/atom/generic";
import { JwtPayLoad } from "../shared/jwt.interface";
import { getUserDetails } from "../utility/jwt";
import { ROLESNAME } from "../shared/role.constant";
import { ROLES_TYPE } from "../shared/role.interface";
import { useState, useEffect } from "react";
import { Http } from "../utility/http";
import { apiHeaders } from "../utility/generic";

// Component showing the logged in user details
export const MyProfile = () => {
  // state and hooks
  const [myProToggle, setMyProfileToggle] = useRecoilState(myProfileToggle);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    employeeId: "",
    businessunit: {
      id: 0,
      name: "",
    },
  });
  // This line retrieves the user details from the JWT payload using the getUserDetails utility function.
  const { user }: JwtPayLoad = getUserDetails();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await Http.request(
          "get",
          `${process.env.REACT_APP_API_URL}auth/my-profile`,
          null,
          apiHeaders(),
        );
        setUserData(result.data.user);
      } catch (error) {
       
      }
    };

    fetchData();
  }, []);

  return (
    <div className="box">
      <div
        className={
          "new-request-init align-self-end " +
          (!myProToggle ? "new-request-init--collapsed" : "")
        }
      >
        {/* Heading panel */}
        <div
          className="text-right panel-title"
          style={{ background: "#375db1" }}
        >
          <span
            style={{ color: "white", fontSize: "24px", paddingLeft: "20px" }}
          >
            My Profile
          </span>
          <span
            className="close-btn"
            id="request-detail-close"
            onClick={() => setMyProfileToggle(false)}
          >
            &times;
          </span>
        </div>
        {/* Logged in User details */}
        <div style={{ overflowY: "auto", maxHeight: "75vh" }}>
          <h6 className="mb-0 panel-header">My Profile</h6>
          <table className="table">
            <tbody>
              <tr>
                <th>First Name</th>
                <td>{userData.firstName}</td>
              </tr>
              <tr>
                <th>Last Name</th>
                <td>{userData.lastName}</td>
              </tr>
              <tr>
                <th>Phone Number</th>         
                <td>{userData.phoneNumber==null?"-":userData.phoneNumber}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{userData.email}</td>
              </tr>
              <tr>
                <th>Employee Id</th>
                <td>{userData.employeeId}</td>
              </tr>
              <tr>
                <th>Business Unit</th>
                <td>{userData.businessunit?.name}</td>
              </tr>
              <tr>
                <th>Role</th>
                <td>{ROLESNAME[user.role as keyof ROLES_TYPE]}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
