import { Link } from "react-router-dom";
import "./../assets/css/main.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {
  requestDetailsToggle,
  requestListToggle,
  requestToggle,
} from "../state/atom/request";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { checkPermission } from "../utility/permission";
import { getUserDetails } from "../utility/jwt";
import { JwtPayLoad } from "../shared/jwt.interface";
import { ROLESNAME } from "../shared/role.constant";
import { ROLES_TYPE } from "../shared/role.interface";
import { changePasswordToggle, myProfileToggle } from "../state/atom/generic";
import { MyProfile } from "./profile";
import { ChangePassword } from "./change-password";
import { selectedMenu } from "../state/atom/generic";
import { Menu } from "../shared/generic.constant";
import { useEffect } from "react";
import { Http } from "../utility/http";
import { apiHeaders } from "../utility/generic";
import { addUserToggle, profileDetails } from "../state/atom/user";
import { aggregatorToggle } from "../state/atom/aggregator";
import {
  addProposalToggle,
  proposalDetailsToggle,
} from "../state/atom/proposal";
import { NotificationBox } from "./notification/notification-box";
import { landDetailsToggle, landSummaryToggle } from "../state/atom/gis";

export const Header = () => {
  const setChangePassword = useSetRecoilState(changePasswordToggle);
  const setRequestDetailsToggle = useSetRecoilState(requestDetailsToggle);
  const navigate = useNavigate();
  const logout = () => {
    Cookies.remove("token");
    window.location.href = "/";
  };
  const setToogleRequest = useSetRecoilState(requestToggle);
  const setToogleProposalDetail = useSetRecoilState(proposalDetailsToggle);
  const setToogleAggregator = useSetRecoilState(aggregatorToggle);
  const setToggleAddProposal = useSetRecoilState(addProposalToggle);
  const setMyProfileToggle = useSetRecoilState(myProfileToggle);
  const setAddUserToggle = useSetRecoilState(addUserToggle);
  const setToogleRequestList = useSetRecoilState(requestListToggle);
  const { user }: JwtPayLoad = getUserDetails();
  const [myProfileDetails, setProfileDetails] = useRecoilState(profileDetails);
  const menu = useRecoilValue(selectedMenu);
  const setLandSummaryToggle = useSetRecoilState(landSummaryToggle);
  const setLandDetailsToggle =
  useSetRecoilState(landDetailsToggle);
  useEffect(() => {
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}auth/my-profile`,
      null,
      apiHeaders()
    )
      .then((result: any) => {
        setProfileDetails(result.data.user);
      })
      .catch((error: any) => {});
  }, [setProfileDetails]);

  const changeLocation = (placeToGo: string) => {
    //navigate(placeToGo, { replace: true });
    window.location.href = placeToGo;
  };

  return (
    <>
      <nav
        className="navbar fixed-top navbar-expand-sm"
        style={{
          padding: 0,
          zIndex: 100,
        }}
      >
        <Link className="navbar-brand" to="/home">
          <img
            src="images/logo.png"
            className="navbar-brand-img"
            alt="main_logo"
            style={{ height: "58px" }}
          />
        </Link>
        <div
          className="container-fluid"
          style={{
            backgroundImage:
              "linear-gradient(164deg, #ab2e6e 17%, #0070b4 100%)",
            padding: "10px",
          }}
        >
          <span
            className="ms-1 mt-1  font-weight-bold"
            style={{ color: "white", marginRight: "5px" }}
          >
            ALMS 1.0
          </span>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mynavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="mynavbar">
            <ul className="navbar-nav me-auto">
              {checkPermission(user?.role, "menu", "home") && (
                <li className="nav-item align-items-center pe-2">
                  <span
                    onClick={() => changeLocation("/home")}
                    className={
                      "nav-link a-link font-weight-bold d-flex text-white " +
                      (menu === Menu.home ? "active" : "")
                    }
                  >
                    <i className="material-icons opacity-10 pe-1">home</i>
                    <span className="d-sm-inline d-none dashboard-icons">
                      Home
                    </span>
                  </span>
                </li>
              )}

              {myProfileDetails?.dashboardAccess &&
                checkPermission(user?.role, "menu", "dashboard") && (
                  <li className="nav-item align-items-center pe-2">
                    <Link
                      to="/dashboard"
                      className={
                        "nav-link font-weight-bold d-flex text-white " +
                        (menu === Menu.dashboard ? "active" : "")
                      }
                    >
                      <i className="material-icons opacity-10 pe-1">
                        dashboard
                      </i>
                      <span className="d-sm-inline d-none dashboard-icons">
                        Dashboard
                      </span>
                    </Link>
                  </li>
                )}

              {checkPermission(user?.role, "menu", "home") && (
                <li className="nav-item dropdown pe-2">
                  <a
                    className="nav-link dropdown-toggle text-white d-flex"
                    href="!#"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    <i className="material-icons opacity-10 pe-1">
                      request_page
                    </i>
                    <span className="d-sm-inline d-none dashboard-icons">
                      Request
                    </span>
                  </a>
                  <ul className="dropdown-menu">
                    {checkPermission(user?.role, "request", "can_add") && (
                      <li>
                        <span
                          className="dropdown-item d-flex a-link"
                          onClick={() => {
                            setToogleRequest(true);
                            setToogleRequestList(false);
                            setRequestDetailsToggle(false);
                            navigate("/home");
                          }}
                        >
                          <i className="material-icons opacity-10 pe-1">
                            table_view
                          </i>
                          <span className="d-sm-inline d-none dashboard-icons">
                            New Request
                          </span>
                        </span>
                      </li>
                    )}
                    {checkPermission(user?.role, "request", "can_view") && (
                      <li>
                        <span
                          className="dropdown-item d-flex a-link"
                          onClick={() => {
                            setToogleRequestList(true);
                            setToogleRequest(false);
                            navigate("/home");
                          }}
                        >
                          <i className="material-icons opacity-10 pe-1">feed</i>
                          <span className="d-sm-inline d-none dashboard-icons">
                            Existing Request
                          </span>
                        </span>
                      </li>
                    )}
                  </ul>
                </li>
              )}

              {checkPermission(user?.role, "menu", "report") && (
                <li className="nav-item align-items-center pe-2">
                  <Link
                    to="/report"
                    className={
                      "nav-link font-weight-bold d-flex text-white " +
                      (menu === Menu.report ? "active" : "")
                    }
                  >
                    <i className="material-icons opacity-10 pe-1">checklist</i>
                    <span className="d-sm-inline d-none dashboard-icons">
                      Reports
                    </span>
                  </Link>
                </li>
              )}

              {checkPermission(user?.role, "menu", "gis") && (
                <li className="nav-item align-items-center pe-2">
                  <Link
                    to="/gis"
                    className={
                      "nav-link font-weight-bold d-flex text-white " +
                      (menu === Menu.gis ? "active" : "")
                    }
                  >
                    <i className="material-icons opacity-10 pe-1">
                      travel_explore
                    </i>
                    <span className="d-sm-inline d-none dashboard-icons">
                      GIS
                    </span>
                  </Link>
                </li>
              )}
            </ul>
            {/* <form className="d-flex">
              <input
                className="form-control me-2"
                type="text"
                placeholder="Search"
              />
              <button className="btn btn-primary" type="button">
                Search
              </button>
            </form> */}
            <ul className="nav navbar-nav navbar-right mx-2">
              <li className="nav-item align-items-center pe-2" title="Notification">
                <NotificationBox></NotificationBox>
              </li>

              <li className="nav-item dropdown align-items-center pe-2" title="Profile">
                <Link
                  to=""
                  className={
                    "nav-link font-weight-bold d-flex text-white dropdown-toggle"
                  }
                  role="button"
                  onClick={() => {
                    setLandSummaryToggle(false);
                    setLandDetailsToggle(false);
                    setToogleRequest(false);
                    setToogleAggregator(false);
                    setToggleAddProposal(false);
                    setToogleProposalDetail(false);
                    setAddUserToggle(false);
                  }}
                  data-bs-toggle="dropdown"
                >
                  <i className="material-icons opacity-10 pe-1">person</i>
                </Link>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <span
                      className="dropdown-item sub-menu a-link"
                      onClick={() => setMyProfileToggle(true)}
                    >
                      <i className="material-icons opacity-10 pe-1">person</i>
                      {user.firstName} {user.lastName} - (
                      {ROLESNAME[user.role as keyof ROLES_TYPE]})
                    </span>
                    {checkPermission(user?.role, "settings", "can_view") && (
                      <span
                        className="dropdown-item sub-menu a-link"
                        onClick={() => {
                          changeLocation("/settings");
                        }}
                      >
                        <i className="material-icons opacity-10 pe-1">
                          settings
                        </i>
                        Settings
                      </span>
                    )}
                    <span
                      className="dropdown-item sub-menu a-link"
                      onClick={() => setChangePassword(true)}
                    >
                      <i className="material-icons opacity-10 pe-1">lock</i>
                      Change Password
                    </span>
                    <span
                      className="dropdown-item sub-menu a-link"
                      onClick={logout}
                    >
                      <i className="material-icons opacity-10 pe-1">logout</i>
                      Logout
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <MyProfile></MyProfile>
      <ChangePassword></ChangePassword>
    </>
  );
};
