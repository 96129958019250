import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toaster } from "../../../../state/atom/toaster";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import * as Yup from 'yup';
import {
  cityIdState,
  editCityModalToggle,
} from "../../../../state/atom/settings";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiHeaders } from "../../../../utility/generic";
import { Http } from "../../../../utility/http";

type EditCityForm = {
  city: string;
};

export const EditCityModal = () => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useRecoilState(editCityModalToggle);
  const cityId = useRecoilValue(cityIdState);
  const setToasterStatus = useSetRecoilState(toaster);

  useEffect(() => {
    const getCity = async () => {
      await Http.request(
        "get",
        `${process.env.REACT_APP_API_URL}city/${cityId}`,
        apiHeaders()
      )
        .then((res: any) => {
          // console.log(res);
          const city = res.data.city
          setValue("city", city.city);
        })
        .catch((error: any) => {
          console.error(error);
        });
    };
    getCity();
  }, []);

  const editCityValidationSchema = Yup.object().shape({
    city: Yup.string().required("City name is required."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<EditCityForm>({
    resolver: yupResolver(editCityValidationSchema),
  });

  const onSubmitHandler = async (data: EditCityForm) => {
    setLoading(true);
    await Http.request(
      "put",
      `${process.env.REACT_APP_API_URL}city/${cityId}`,
      data,
      apiHeaders()
    )
      .then((result: any) => {
        console.log(result.message);
        setToasterStatus({
          status: true,
          title: 'City Updated.',
          text: result.message
        })
        setLoading(false);
        handleClose();
      })
      .catch((error: any) => {
        setSubmitErrorMessage(error.response.data.message);
        setLoading(false);
      });
  };

  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header className="panel-title text-white" style={{marginBottom: 0}} closeButton closeVariant="white">
        <Modal.Title>Edit City</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
        {submitErrorMessage && (
            <div className="text-center py-1">
              <span className="text-danger">{submitErrorMessage}</span>
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="d-flex flex-column">
              <div className="form-group px-3 py-2">
                <div style={{ color: "red" }}>
                  &#42;
                  <label className="form-label" htmlFor="inputStype" style={{ color: "black" }}>
                    City
                  </label>
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    errors.city ? "is-invalid" : ""
                  }`}
                  id="stateName"
                  placeholder="Enter city"
                  {...register("city")}
                />
                {errors.city && (
                  <span className="text-danger">{errors.city.message}</span>
                )}
              </div>
            </div>
            <div className="form-group px-3 py-2">
              <button
                type="submit"
                className="btn bg-gradient-primary-login btn1 w-100"
                disabled={loading}
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Add State
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};
