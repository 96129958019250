// import
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  requestId,
  requestList,
  unholdRequestToggle,
} from "../../state/atom/request";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { toaster } from "../../state/atom/toaster";

// Component displaying a hold request confirmation.
export const UnholdRequest = () => {
  // states and hooks
  const reqId = useRecoilValue(requestId);
  const [unholdReqToggle, setUnholdRequestToggle] =
    useRecoilState(unholdRequestToggle);
  const setToasterStatus = useSetRecoilState(toaster);
  const setRequestList = useSetRecoilState(requestList);

  // Handles the user confirmation when the user click on Yes button.
  const unHoldRequest = () => {
    // Payload for API
    const request = {
      requestStatus: "resume",
    };

    // Make the POST request for the hold the request.
    Http.request(
      "patch",
      `${process.env.REACT_APP_API_URL}land-request/${reqId}`,
      request,
      apiHeaders()
    )
      .then((result: any) => {
        setRequestList((preState) => {
          let preRequest = [...preState.data.landRequest];
          const index = preRequest.findIndex((val: any) => val.id === reqId);
          //@ts-ignore
          preRequest[index].status = request.requestStatus;
          return {
            ...preState,
            landRequest: preRequest,
          };
        });

        //Show a success notification to the user
        setToasterStatus({
          status: true,
          title: "Request",
          text: "Land request successfully Unhold ",
        });

        // hide the hold request confirmation box.
        setUnholdRequestToggle(!unholdReqToggle);
      })
      .catch((error: any) => {});
  };

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal
        show={unholdReqToggle}
        onHide={() => setUnholdRequestToggle(!unholdReqToggle)}
      >
        <Modal.Header closeButton className="toaster-header ">
          <Modal.Title>Unhold Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to Unhold this request!</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setUnholdRequestToggle(!unholdReqToggle)}
          >
            Cancel
          </Button>
          <Button onClick={unHoldRequest} variant="primary">
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
