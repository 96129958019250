// @ts-nocheck
import { useEffect, useMemo, useRef, useState } from "react";
import { loadModules } from "esri-loader";
import Select from "react-select";
import {
  requestDetailsToggle,
  requestId,
  requestList,
  requestProposalKmlData,
} from "../state/atom/request";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { locationLatLon, mapCellClass, mapCenter } from "../state/atom/generic";
import {
  kmlData,
  proposalDetailsToggle,
  proposalId,
} from "../state/atom/proposal";
import Graphic from "@arcgis/core/Graphic.js";
import { Link } from "react-router-dom";
export interface Props {
  classList?: string;
}
export const RequestHomeMap = ({ classList }: Props) => {
  const request = useRecoilValue(requestList);
  const mapCenterPostion = useRecoilValue(mapCenter);
  const [kmlDataCollection, setKmlData] = useRecoilState(kmlData);
  const [reqProposalKmlData, setRequestProposalKmlData] = useRecoilState(
    requestProposalKmlData
  );
  const setProposalDetailsToggle = useSetRecoilState(proposalDetailsToggle);
  const setProposalId = useSetRecoilState(proposalId);
  const [mapView, setMapView] = useState("satellite");
  const setRequestDetailsToggle = useSetRecoilState(requestDetailsToggle);
  const setRequestId = useSetRecoilState(requestId);
  const [view, setView] = useState(null);
  const [map, setMap] = useState(null);
  const mapRef = useRef(null);
  const mapViewRef = useRef(null);
  const [mapCellCls, setMapCellCls] = useRecoilState(mapCellClass);
  //const mapCellCls = useRecoilValue(mapCellClass);
  const setlocationLatLon = useSetRecoilState(locationLatLon);
  let mouseHandler = null;
  const createMap = useMemo(
    () => async () => {
      loadModules([
        "esri/Map",
        "esri/views/MapView",
        "esri/Graphic",
        "esri/layers/GraphicsLayer",
        "esri/layers/WebTileLayer",
        "esri/Basemap",
        "esri/widgets/BasemapToggle",
        "esri/layers/FeatureLayer",
      ])
        .then(
          ([
            Map,
            MapView,
            Graphic,
            GraphicsLayer,
            WebTileLayer,
            Basemap,
            BasemapToggle,
            FeatureLayer,
          ]) => {
            const map = new Map({
              basemap: mapView,
            });
            setMap(map);

            const view = new MapView({
              container: mapRef.current,
              map: map,
              zoom: 4,
              center: [72.22, 23.0], // Set the initial center point of the map
            });
            mapViewRef.current = view;
            view.container.style.border = "none";

            const GoogleStreets = new WebTileLayer({
              urlTemplate:
                "https://mts1.google.com/vt/lyrs=m@186112443&hl=x-local&src=app&x={col}&y={row}&z={level}&s=Galile",
              subdomains: ["mt0", "mt1", "mt2", "mt3"],
            });
            const goglesa = new WebTileLayer({
              urlTemplate:
                "https://mts1.google.com/vt/lyrs=s@186112443&hl=x-local&src=app&x={col}&y={row}&z={level}&s=Galile",
              subdomains: ["mt0", "mt1", "mt2", "mt3"],
            });

            const gogleSatellite = new WebTileLayer({
              urlTemplate:
                "https://mt1.google.com/vt/lyrs=s&hl=en&z={level}&x={col}&y={row}",
              subdomains: ["mt0", "mt1", "mt2", "mt3"],
            });

            const googlesate = new Basemap({
              baseLayers: [goglesa],
              thumbnailUrl: "images/google-hybrid.jpeg",
              title: "Google Hybrid",
            });
            const toggle1 = new BasemapToggle(
              {
                //titleVisible: false,
                view: view,
                nextBasemap: googlesate,
                label: "Hybrid",
              },
              "goglesat1"
            );

            const googlestrt = new Basemap({
              baseLayers: [GoogleStreets],
              thumbnailUrl: "images/google-street.jpeg",
              title: "Google Street",
            });
            const toggle2 = new BasemapToggle(
              {
                // titleVisible: false,
                view: view,
                nextBasemap: googlestrt,
                label: "Streets",
              },
              "goglesat2"
            );

            const googleSate = new Basemap({
              baseLayers: [gogleSatellite],
              thumbnailUrl: "images/satellite.jpeg",
              title: "Satellite",
            });
            const toggle3 = new BasemapToggle(
              {
                // titleVisible: false,
                view: view,
                nextBasemap: googleSate,
                label: "Satellite",
              },
              "goglesat3"
            );

            setView(view);

            const stateBoundray = new FeatureLayer({
              url: process.env.REACT_APP_STATE_BOUNDARY,
              outFields: ["*"],
            });
            map.add(stateBoundray);

            const graphicsLayer = new GraphicsLayer();

            view.when(() => {
              request.data.landRequest.forEach((marker) => {
                let markerSymbol = {
                  type: "picture-marker",
                  width: "12px",
                  height: "12px",
                };
                if (marker.requestStatus === "pending") {
                  markerSymbol.url = "images/pending-marker.png";
                }

                if (marker.requestStatus === "in_progress") {
                  markerSymbol.url = "images/in-progress-marker.png";
                }
                if (marker.requestStatus === "acquisition") {
                  markerSymbol.url = "images/acquisition-marker.png";
                }

                if (marker.requestStatus === "proposal_assigned") {
                  markerSymbol.url = "images/proposal-assigned-marker.png";
                }

                if (marker.requestStatus === "completed") {
                  markerSymbol.url = "images/completed1234.png";
                }

                const point = {
                  type: "point",
                  longitude: marker.locationLon,
                  latitude: marker.locationLat,
                };

                const attributes = {
                  name: marker.requestName,
                  id: marker.id,
                };

                const markerGraphic = new Graphic({
                  geometry: point,
                  symbol: markerSymbol,
                  attributes: attributes,
                });

                graphicsLayer.add(markerGraphic);
              });
              map.add(graphicsLayer);
            });
            view.on("click", async (event) => {
              const clickedGraphics = await view.hitTest(event);
              if (clickedGraphics.results.length > 1) {
                const clickedGraphic = clickedGraphics.results[0].graphic;
                const clickedPoint = clickedGraphic.attributes;
                const reqId = clickedPoint?.id || 0;
                if (reqId) {
                  setRequestDetailsToggle(true);
                  setRequestId(reqId);
                }
              }
            });
          }
        )
        .catch((error) => {
          console.error("Error loading ArcGIS modules:", error);
        });
    },
    [mapView, request.data.landRequest, setRequestDetailsToggle, setRequestId]
  );
  useEffect(() => {
    createMap();
  }, [request, createMap, kmlDataCollection]);

  useEffect(() => {
    const handleClickOnMap = async (event) => {
      console.log("event.mapPoint",event.mapPoint)
      //const clickedGraphics = await view.hitTest(event);
      if (mapCellCls) {
        const lat = event.mapPoint.latitude;
        const lon = event.mapPoint.longitude;
        setlocationLatLon({
          lat: parseFloat(lat.toFixed(6)),
          lon: parseFloat(lon.toFixed(6)),
        });
        setMapCellCls(false);
        if (mouseHandler) {
          mouseHandler.remove();
          mouseHandler = null;
        }
      }
    };

    if (view) {
      mouseHandler = view.on("click", handleClickOnMap);
    }
  }, [view, mapCellCls]);

  useEffect(() => {
    if (view) {
      view.zoom = mapCenterPostion.zoom;
      view.center = mapCenterPostion.center;

      if (mapCenterPostion.isShow) {
        const centerMarker = {
          type: "picture-marker",
          url: "images/live-location-1.gif",
          width: "100px",
          height: "80px",
        };

        const point = {
          type: "point",
          longitude: Number(mapCenterPostion.center[0]),
          latitude: Number(mapCenterPostion.center[1]),
        };
        const markerGraphic = new Graphic({
          geometry: point,
          symbol: centerMarker,
        });
        //Clear previous graphics
        view.graphics.removeAll();
        view.graphics.add(markerGraphic);
      }
    }
  }, [mapCenterPostion, view]);

  useEffect(() => {
    loadModules([
      "esri/layers/GraphicsLayer",
      "esri/Graphic",
      "esri/geometry/Polygon",
    ]).then(([GraphicsLayer, Graphic, Polygon]) => {
      if (map) {
        const graphicsLayer = new GraphicsLayer();

        if (kmlDataCollection?.features) {
          kmlDataCollection.features.forEach((feature) => {
            const graphic = new Graphic({
              geometry: {
                type: "polygon",
                rings:
                  feature.geometry.coordinates.length === 1
                    ? feature.geometry.coordinates
                    : [feature.geometry.coordinates],
              },
              symbol: {
                type: "simple-fill",
                color: [0, 0, 255],
              },
            });
            graphicsLayer.add(graphic);
          });
          /* graphicsLayer.when(() => {
            view.zoom = 12;
          }); */
        }

        view
          .when(() => {
            console.log("graphicsLayer.graphics", graphicsLayer.graphics);
            view.goTo(graphicsLayer.graphics.toArray());
          })
          .catch((error) => {
            console.error("Error:", error);
          });

        map.add(graphicsLayer);
      }
    });
  }, [
    kmlDataCollection,
    kmlDataCollection.features,
    map,
    mapView.spatialReference,
    view,
  ]);

  useEffect(() => {
    loadModules([
      "esri/layers/GraphicsLayer",
      "esri/Graphic",
      "esri/geometry/Polygon",
      "esri/symbols/TextSymbol",
    ]).then(([GraphicsLayer, Graphic, TextSymbol]) => {
      if (map) {
        const graphicsLayer = new GraphicsLayer();

        if (reqProposalKmlData.length) {
          reqProposalKmlData.forEach((kmlDataCollection) => {
            let textSymbol = {
              type: "text", // autocasts as new TextSymbol()
              color: "white",
              haloColor: "black",
              haloSize: "1px",
              text: kmlDataCollection.proposalId,
              xoffset: 3,
              yoffset: 3,
              font: {
                // autocasts as new Font()
                size: 12,
                family: "Adani",
                weight: "bold",
              },
            };
            if (
              kmlDataCollection?.kmlData !== null &&
              typeof JSON.parse(kmlDataCollection?.kmlData).features !==
                "undefined"
            ) {
              JSON.parse(kmlDataCollection?.kmlData).features.forEach(
                (feature) => {
                  const graphic = new Graphic({
                    geometry: {
                      type: "polygon",
                      rings:
                        feature.geometry.coordinates.length === 1
                          ? feature.geometry.coordinates
                          : [feature.geometry.coordinates],
                    },
                    symbol: {
                      type: "simple-fill",
                      color: [255, 0, 0, 0.5],
                    },
                    attributes: {
                      id: kmlDataCollection.id,
                    },
                  });
                  graphicsLayer.add(graphic);

                  var textGraphic = new Graphic({
                    geometry: graphic.geometry.centroid, // Set the position for the text
                    symbol: textSymbol,
                  });
                  graphicsLayer.add(textGraphic);

                  view.on("click", (event) => {
                    view.hitTest(event).then((response) => {
                      const results = response.results;
                      if (results.length > 0) {
                        const graphic = results[0].graphic;
                        const proposalId = graphic.attributes
                          ? graphic.attributes.id
                          : 0;
                        if (proposalId) {
                          setProposalDetailsToggle(true);
                          setProposalId(proposalId);
                        }
                      }
                    });
                  });
                }
              );
            }
          });

          view
            .when(() => {
              //view.extent = graphicsLayer.queryExtent;
              view.goTo(graphicsLayer.graphics.toArray());
            })
            .catch((error) => {
              console.error("Error:", error);
            });

          /* graphicsLayer.when(() => {
            view.zoom = 18;
          }); */
        } else {
          graphicsLayer.removeAll();
        }

        map.add(graphicsLayer);
      }
    });
  }, [
    reqProposalKmlData,
    map,
    mapView.spatialReference,
    view,
    mapView.graphics,
    mapView,
    setProposalDetailsToggle,
    setProposalId,
  ]);

  const changeMaptype = (option) => {};

  useEffect(() => {
    // Code here will run when the component is mounted

    return () => {
      setKmlData({});
      setRequestProposalKmlData([]);
    };
  }, [setKmlData, setRequestProposalKmlData]);
  return (
    <>
      <div
        ref={mapRef}
        id="viewDiv"
        style={{ height: "100vh", cursor: mapCellCls ? "cell" : "pointer" }}
        className={classList}
      >
        {/* <button
          className="toogle-map btn-grad"
          onClick={() => {
            const view =
              mapView === "streets-vector" ? "satellite" : "streets-vector";
            setMapView(view);
          }}
        >
          {mapView === "streets-vector" ? "Satellite View" : "Default  View"}
        </button> */}
      </div>
      <div className="map-type">
        <div className="dropdown">
          <div className="nav-item dropdown align-items-center pe-2">
            <Link
              className="nav-link font-weight-bold d-flex text-white dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
            >
              <i className="material-icons opacity-10 pe-1">public</i>
            </Link>
            <ul
              className="dropdown-menu dropdown-menu-end"
              style={{ minWidth: "90px" }}
            >
              <li className="map-type-li">
                <Link id="goglesat1">
                  <span></span>
                </Link>
              </li>
              <li className="map-type-li">
                <Link id="goglesat2">
                  <span></span>
                </Link>
              </li>
              <li className="map-type-li">
                <Link id="goglesat3">
                  <span></span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="map-legend">
        <ul>
          <li>
            <img src="images/pending-marker.png" alt="Pending" />
            Pending
          </li>
          <li>
            <img src="images/in-progress-marker.png" alt="in_progress" />
            In Progress
          </li>
          <li>
            <img src="images/acquisition-marker.png" alt="acquisition" />
            Acquisition
          </li>
          <li>
            <img src="images/proposal-assigned-marker.png" alt="Pending" />
            Proposal Assigned
          </li>
          <li>
            <img src="images/completed1234.png" alt="Completed" />
            Completed
          </li>
        </ul>
      </div>
    </>
  );
};
