import { ROLES_TYPE } from "./role.interface";

export const ROLES: ROLES_TYPE = {
  abu_spoc: "abu_spoc",
  le_spoc: "le_spoc",
  le_m: "le_m",
  le_dh: "le_dh",
  le_data: "le_data",
};

export const ROLESNAME = {
  abu_spoc: "ABU SPOC",
  le_spoc: "LE SPOC",
  le_m: "LE M",
  le_dh: "LE DH",
  abu_eng: "ABU Engineer",
  le_data: "LE DCC",
};

export const ROLESNAME1 = {
  abu_spoc: "Adani Business Unit - Single point of contact",
  le_spoc: "Land & Estate - Single point of contact",
  le_m: "Land & Estate-Master",
  le_dh: "Land & Estate- Divisonal Head",
  abu_eng: "Adani Business Unit Engineer",
  le_data: "Data Control Center",
};

export const ROLES_PERMISSIONS = {
  abu_spoc: {
    permissions: {
      request: {
        can_view: true,
        can_add: true,
        can_edit: true,
        can_delete: true,
        can_assign: false,
        can_close: false,
        can_hold: false,
        can_unhold: false,
      },
      proposal: {
        can_view: true,
        can_add: false,
        can_edit: true,
        can_delete: true,
        can_assign: true,
        can_update: false,
        can_send:false,
        can_view_acquistion_status:true
      },
      eng_report: {
        can_view: true,
        can_add: false,
        can_edit: false,
        can_delete: false,
        can_assign: true,
      },
      user: {
        can_view: false,
        can_add: false,
        can_edit: false,
        can_delete: false,
        can_access_dashboard_switch: false,
        can_verify: false,
      },
      aggregator: {
        can_view: false,
        can_add: false,
      },
      status: {
        proposal_status: true,
        request_status: true,
      },
      menu: {
        gis: true,
        home: true,
        dashboard: true,
        report: true,
      },
      gis: {
        upload_document: false,
      },
      settings: {
        can_view: false,
      },
    },
  },
  le_spoc: {
    permissions: {
      request: {
        can_view: true,
        can_add: false,
        can_edit: true,
        can_delete: true,
        can_assign: false,
        can_close: false,
        can_hold: false,
        can_unhold: false,
      },
      proposal: {
        can_view: true,
        can_add: true,
        can_edit: true,
        can_delete: true,
        can_assign: false,
        can_update: true,
        can_send:true,
        can_view_acquistion_status:true
      },
      eng_report: {
        can_view: true,
        can_add: false,
        can_edit: false,
        can_delete: false,
      },
      user: {
        can_view: false,
        can_add: false,
        can_edit: false,
        can_delete: false,
        can_access_dashboard_switch: false,
        can_verify: false,
      },
      aggregator: {
        can_view: true,
        can_add: true,
      },
      status: {
        proposal_status: true,
        request_status: true,
      },
      menu: {
        gis: true,
        home: true,
        dashboard: true,
        report: true,
      },
      gis: {
        upload_document: true,
      },
      settings: {
        can_view: false,
      },
    },
  },
  le_dh: {
    permissions: {
      request: {
        can_view: true,
        can_add: false,
        can_edit: true,
        can_delete: true,
        can_assign: true,
        can_close: true,
        can_hold: true,
        can_unhold: true,
      },
      proposal: {
        can_view: true,
        can_add: false,
        can_edit: true,
        can_delete: true,
        can_assign: false,
        can_update: false,
        can_send:false,
        can_view_acquistion_status:true
      },
      eng_report: {
        can_view: true,
        can_add: false,
        can_edit: false,
        can_delete: false,
      },
      user: {
        can_view: false,
        can_add: false,
        can_edit: false,
        can_delete: false,
        can_access_dashboard_switch: false,
        can_verify: false,
      },
      aggregator: {
        can_view: false,
        can_add: false,
      },
      status: {
        proposal_status: true,
        request_status: true,
      },
      menu: {
        gis: true,
        home: true,
        dashboard: true,
        report: true,
      },
      gis: {
        upload_document: false,
      },
      settings: {
        can_view: false,
      },
    },
  },
  le_m: {
    permissions: {
      request: {
        can_view: true,
        can_add: true,
        can_edit: true,
        can_delete: true,
        can_assign: true,
        can_close: true,
        can_hold: true,
        can_unhold: true,
      },
      proposal: {
        can_view: true,
        can_add: false,
        can_edit: true,
        can_delete: true,
        can_assign: true,
        can_update: false,
        can_send:false,
        can_view_acquistion_status:true
      },
      eng_report: {
        can_view: true,
        can_add: false,
        can_edit: false,
        can_delete: false,
      },
      user: {
        can_view: true,
        can_add: true,
        can_edit: true,
        can_delete: true,
        can_access_dashboard_switch: true,
        can_verify: true,
      },
      aggregator: {
        can_view: false,
        can_add: false,
      },
      status: {
        proposal_status: true,
        request_status: true,
      },
      menu: {
        gis: true,
        home: true,
        dashboard: true,
        report: true,
      },
      gis: {
        upload_document: false,
      },
      settings: {
        can_view: true,
      },
    },
  },
  abu_eng: {
    permissions: {
      request: {
        can_view: true,
        can_add: false,
        can_edit: false,
        can_delete: false,
        can_assign: false,
        can_close: false,
        can_hold: false,
        can_unhold: false,
      },
      proposal: {
        can_view: true,
        can_add: false,
        can_edit: false,
        can_delete: false,
        can_assign: false,
        can_update: false,
        can_send:false,
        can_view_acquistion_status:false
      },
      eng_report: {
        can_view: true,
        can_add: true,
        can_edit: true,
        can_delete: false,
      },
      user: {
        can_view: false,
        can_add: false,
        can_edit: false,
        can_delete: false,
        can_access_dashboard_switch: false,
        can_verify: false,
      },
      aggregator: {
        can_view: false,
        can_add: false,
      },
      status: {
        proposal_status: false,
        request_status: false,
      },
      menu: {
        gis: false,
        home: true,
        dashboard: false,
        report: false,
      },
      gis: {
        upload_document: false,
      },
      settings: {
        can_view: false,
      },
    },
  },
  le_data: {
    permissions: {
      request: {
        can_view: false,
        can_add: false,
        can_edit: false,
        can_delete: false,
        can_assign: false,
        can_close: false,
        can_hold: false,
        can_unhold: false,
      },
      proposal: {
        can_view: false,
        can_add: false,
        can_edit: false,
        can_delete: false,
        can_send:false,
        can_assign: false,
        can_update: false,
        can_view_acquistion_status:true
      },
      eng_report: {
        can_view: false,
        can_add: false,
        can_edit: false,
        can_delete: false,
        can_assign: false,
      },
      user: {
        can_view: false,
        can_add: false,
        can_edit: false,
        can_delete: false,
        can_access_dashboard_switch: false,
        can_verify: false,
      },
      aggregator: {
        can_view: false,
        can_add: false,
      },
      status: {
        proposal_status: true,
        request_status: true,
      },
      menu: {
        gis: true,
        home: false,
        dashboard: false,
        report: false,
      },
      gis: {
        upload_document: true,
      },
      settings: {
        can_view: false,
      },
    },
  },
};
