import { atom } from "recoil";
import { ILandSummary } from "../../shared/gis.interface";

export const gisList = atom({
  key: "gisList",
  default: {
    data: {
      request: [],
    },
  },
});

export const gisFilterToggle = atom({
  key: "gisFilterToggle",
  default: false,
});

export const landSummaryToggle = atom({
  key: "landSummaryToggle",
  default: false,
});

export const landDetailsToggle = atom({
  key: "landDetailsToggle",
  default: false,
});

export const landDetails = atom({
  key: "landDetails",
  default: {},
});

export const landSummary = atom({
  key: "landSummary",
  default: {} as ILandSummary,
  dangerouslyAllowMutability: true,
});

export const gisSites = atom({
  key: "gisSites",
  default: [],
});

export const landParcelId = atom({
  key: "landParcelId",
  default: "",
});

export const siteFilterState = atom({
  key: "siteFilterState",
  default: "",
});

export const gisFeatureLayerFilter = atom({
  key: "",
  default: {
    SurveyNo: "",
    Village: "",
    BusinessName: "",
    CompanyName: "",
    State: "",
    Site: "",
    Request_Id: "",
    abu_or_lem_user_id: "",
    le_spoc_user_id: "",
    le_dh_user_id: "",
  } as any,
  dangerouslyAllowMutability: true,
});

export const updateGisFilter = atom({
  key: "updateGisFilter",
  default: 1,
});

export const misSummaryFilter = atom({
  key: "",
  default: {
    BusinessName: "",
    Site: "",
  } as any,
  dangerouslyAllowMutability: true,
});
