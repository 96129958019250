import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useRecoilState } from "recoil";
import { selectedSettingsMenu } from "../../state/atom/generic";
import { SettingsMenu } from "../../shared/generic.constant";
import Card from "react-bootstrap/Card";
export const SideBarComp: React.FC = () => {
  const [isselectedSettingsMenu, SetIsSelectedSettingsMenu] =
    useRecoilState(selectedSettingsMenu);
  return (
    <>
      <div className="pt-3">
        <Card>
          <ListGroup
            as="ul"
            className="text-center"
            style={{ cursor: "pointer" }}
          >
            <ListGroup.Item
              as="li"
              className={`${
                isselectedSettingsMenu === SettingsMenu.state ? "active" : ""
              }`}
              onClick={() => SetIsSelectedSettingsMenu(SettingsMenu.state)}
            >
              State
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className={`${
                isselectedSettingsMenu === SettingsMenu.district ? "active" : ""
              }`}
              onClick={() => SetIsSelectedSettingsMenu(SettingsMenu.district)}
            >
              District
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className={`${
                isselectedSettingsMenu === SettingsMenu.city ? "active" : ""
              }`}
              onClick={() => SetIsSelectedSettingsMenu(SettingsMenu.city)}
            >
              City
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className={`${
                isselectedSettingsMenu === SettingsMenu.business ? "active" : ""
              }`}
              onClick={() => SetIsSelectedSettingsMenu(SettingsMenu.business)}
            >
              Business-Unit
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className={`${
                isselectedSettingsMenu === SettingsMenu.company ? "active" : ""
              }`}
              onClick={() => SetIsSelectedSettingsMenu(SettingsMenu.company)}
            >
              Company
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </div>
    </>
  );
};
