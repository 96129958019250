import { useRecoilState, useRecoilValue } from "recoil";
import { forceRender, misReport, reportToggle } from "../../state/atom/report";
import { gisFeatureLayerFilter, misSummaryFilter } from "../../state/atom/gis";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import fileDownload from "js-file-download";
import { Pagination } from "react-bootstrap";
import { SkeletonComp } from "../../shared/Skeleton/Skeleton";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";

export const MisList = () => {
  const [repoToggle, setReportToggle] = useRecoilState(reportToggle);
  const misReportData = useRecoilValue(misReport);
  const misSummaryFilterData = useRecoilValue(misSummaryFilter);
  const [disableSummraybtn, setDisableSummarybtn] = useState(false);
  const forceRenderTrigger = useRecoilValue(forceRender);
  const [summaryType, setSummaryType] = useState("count");
  const [misFilterOptions, setMisFilterOptions] = useState({
    business: false,
    site: false,
  });
  let gisFeatureLayerFilterData = useRecoilValue(gisFeatureLayerFilter);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (
      misSummaryFilterData.Site === "" &&
      misSummaryFilterData.BusinessName === ""
    ) {
      setDisableSummarybtn(false);
      setMisFilterOptions((prevOptions) => ({
        ...prevOptions,
        business: false,
        site: false,
      }));
      setSummaryType("count");
    } else {
      setDisableSummarybtn(true);
    }
  }, [misSummaryFilterData.BusinessName, misSummaryFilterData.Site]);

  useEffect(() => {
    setIsLoading(true);
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}land-parcel/mis/report`,
      {
        filterDataSet: {
          countWise: true,
        },
      },
      apiHeaders()
    )
      .then((result) => {
        setIsLoading(false);
        setCountSummaryData(result?.data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  const toggleSummary = (type: string) => {
    setSummaryType(type);
    setIsLoading(true);
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}land-parcel/mis/report`,
      {
        filterDataSet: {
          countWise: type === "count" ? true : false,
          areaWise: type === "area" ? true : false,
        },
      },
      apiHeaders()
    )
      .then((result) => {
        setIsLoading(false);
        if (type === "count") {
          setCountSummaryData(result?.data);
        } else {
          setAreaSummaryData(result?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const [currentPage, setCurrentPage] = useState<number>(1);
  const ITEMS_PER_PAGE: number = 20;

  const [areaSummaryData, setAreaSummaryData] = useState([]);
  const [countSummaryData, setCountSummaryData] = useState([]);
  const [businessWiseReportData, setBusinessWiseReportData] = useState([]);
  const [siteWiseReportData, setSiteWiseReportData] = useState([]);
  // Calculate the range of visible pages based on the current page
  const TOTAL_PAGES = Math.ceil(siteWiseReportData.length / ITEMS_PER_PAGE);
  const VISIBLE_PAGE = 5;
  const startPage = Math.max(1, currentPage - Math.floor(VISIBLE_PAGE / 2));
  const endPage = Math.min(TOTAL_PAGES, startPage + VISIBLE_PAGE - 1);

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;

  useEffect(() => {
    if (gisFeatureLayerFilterData.Site) {
      setDisableSummarybtn(true);
      setMisFilterOptions((prevOptions) => ({
        ...prevOptions,
        site: true,
        business: false,
      }));
      setSummaryType("");
      setIsLoading(true);
      Http.request(
        "post",
        `${process.env.REACT_APP_API_URL}land-parcel/mis/report`,
        {
          filterDataSet: {
            businessWise: gisFeatureLayerFilterData.BusinessName,
            siteWise: gisFeatureLayerFilterData.Site,
          },
        },
        apiHeaders()
      )
        .then((result) => {
          setIsLoading(false);
          setSiteWiseReportData(result?.data);
        })
        .catch((error) => {
          setIsLoading(false);
        });

      setCurrentPage(1);
    } else if (
      gisFeatureLayerFilterData.BusinessName ||
      gisFeatureLayerFilterData.State ||
      gisFeatureLayerFilterData.CompanyName
    ) {
      console.log("gisFeatureLayerFilterData", gisFeatureLayerFilterData);
      setDisableSummarybtn(true);
      setMisFilterOptions((prevOptions) => ({
        ...prevOptions,
        business: true,
        site: false,
      }));
      setSummaryType("");
      setIsLoading(true);
      Http.request(
        "post",
        `${process.env.REACT_APP_API_URL}land-parcel/mis/report`,
        {
          filterDataSet: {
            businessWise: gisFeatureLayerFilterData.BusinessName,
            stateWise: gisFeatureLayerFilterData.State,
            companyWise: gisFeatureLayerFilterData.CompanyName,
          },
        },
        apiHeaders()
      )
        .then((result) => {
          setIsLoading(false);
          setBusinessWiseReportData(result?.data);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } else if (
      !gisFeatureLayerFilterData?.BusinessName &&
      !gisFeatureLayerFilterData?.State &&
      !gisFeatureLayerFilterData?.CompanyName &&
      !gisFeatureLayerFilterData?.Site
    ) {
      setDisableSummarybtn(false);
      setMisFilterOptions((prevOptions) => ({
        ...prevOptions,
        business: false,
        site: false,
      }));
      setSummaryType("count");

      setIsLoading(true);
      Http.request(
        "post",
        `${process.env.REACT_APP_API_URL}land-parcel/mis/report`,
        {
          filterDataSet: {
            countWise: true,
          },
        },
        apiHeaders()
      )
        .then((result) => {
          setIsLoading(false);
          setCountSummaryData(result?.data);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, [misReportData, gisFeatureLayerFilterData, forceRenderTrigger]);

  const exportToXls = () => {
    if (summaryType === "area") {
      exportAreaSummary();
    } else if (summaryType === "count") {
      exportCountSummary();
    } else if (misFilterOptions.business || misFilterOptions.site) {
      exportMisFilterSummary();
    }
  };

  const exportAreaSummary = () => {
    const exportData = areaSummaryData.map(convertValuesToFloat);
    const fileName = "Summary Area Wise";
    if (exportData.length) {
      createXLSFile(exportData, fileName);
    }
  };

  const exportCountSummary = () => {
    const fileName = "Summary Count Wise";
    if (countSummaryData.length) {
      createXLSFile(countSummaryData, fileName);
    }
  };

  const exportMisFilterSummary = () => {
    if (misFilterOptions.business && businessWiseReportData.length) {
      const exportData = businessWiseReportData.map(convertValuesToFloat);
      const fileName = "Business Wise Report";
      createXLSFile(exportData, fileName);
    } else if (misFilterOptions.site && siteWiseReportData.length) {
      const exportData = siteWiseReportData.map(convertValuesToFloat);
      const fileName = "Site Wise Report";
      createXLSFile(exportData, fileName);
    }
  };

  const createXLSFile = (summaryReportData: any, fileName: string) => {
    if (summaryReportData.length) {
      const worksheet = XLSX.utils.json_to_sheet(summaryReportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const dataBlob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      fileDownload(dataBlob, `${fileName}.xlsx`);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const convertValuesToFloat = (obj: any) => {
    const floatObj: any = {};

    for (const key in obj) {
      const value = obj[key];
      const floatVal = parseFloat(value);
      floatObj[key] = isNaN(floatVal) ? value : floatVal;
    }

    return floatObj;
  };

  return (
    <>
      <div className="box center-box">
        <div
          className=" a-link request-list-corner-btn request-list-corner-btn--collapsed "
          id="request-list-corner-btn"
          onClick={() => {
            setReportToggle(!repoToggle);
          }}
        >
          <span
            style={{ padding: "0 5px" }}
            className="nav-link font-weight-bold d-flex text-white corner-btn"
          >
            <i className="material-icons opacity-10 pe-1">checklist</i>
            <span className="d-sm-inline d-none dashboard-icons">MIS List</span>
          </span>
        </div>
        <div
          className={
            "request-list-init " +
            (!repoToggle ? "request-list-init--collapsed" : "")
          }
          style={{ left: "452px", width: "70%", overflowY: "scroll" }}
        >
          <div
            className="text-right panel-title mb-0"
            style={{
              background: "#375db1",
              alignItems: "center",
              position: "sticky",
              top: "0",
            }}
          >
            <span
              style={{ color: "white", fontSize: "20px", paddingLeft: "12px" }}
            >
              Existing MIS List
            </span>
            <div className="form-group d-flex justify-content-end  mx-5 my-2 mis-list-header">
              {misFilterOptions.site && siteWiseReportData.length > 0 && (
                <Pagination className="mx-5">
                  {currentPage > 1 && (
                    <>
                      <Pagination.First
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                      />
                      <Pagination.Prev
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      />
                    </>
                  )}
                  {Array.from({ length: endPage - startPage + 1 }).map(
                    (_, index: number) => (
                      <Pagination.Item
                        className="bg-gradient"
                        key={startPage + index}
                        active={startPage + index === currentPage}
                        onClick={() => handlePageChange(startPage + index)}
                      >
                        {startPage + index}
                      </Pagination.Item>
                    )
                  )}
                  {currentPage !== TOTAL_PAGES && (
                    <>
                      <Pagination.Next
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === TOTAL_PAGES}
                      />
                      <Pagination.Last
                        onClick={() => handlePageChange(TOTAL_PAGES)}
                        disabled={currentPage === TOTAL_PAGES}
                      />
                    </>
                  )}
                </Pagination>
              )}

              <button
                type="button"
                key="count_summary"
                disabled={disableSummraybtn}
                style={{ marginRight: "5px" }}
                onClick={() => toggleSummary("count")}
                className={`btn bg-gradient-primary-login btn1 ${
                  summaryType === "count" ? "bg-primary bg-gradient" : ""
                }`}
              >
                COUNT SUMMARY
              </button>
              <button
                type="button"
                key="area_summary"
                disabled={disableSummraybtn}
                style={{ marginRight: "5px" }}
                onClick={() => toggleSummary("area")}
                className={`btn bg-gradient-primary-login btn1 ${
                  summaryType === "area" ? "bg-primary bg-gradient" : ""
                }`}
              >
                AREA SUMMARY
              </button>
            </div>
            <span
              className="close-btn"
              id="request-list-close"
              onClick={() => {
                setReportToggle(!repoToggle);
              }}
            >
              &times;
            </span>
          </div>

          {misFilterOptions.business && (
            <>
              <div
                className="table-responsive p-0 dx-table-mis"
                /* style={{ height: "430px", overflowY: "auto" }} */
              >
                <table
                  className="table align-items-center justify-content-center mb-0 text-white table-bordered text-center"
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  <thead
                    style={{
                      background: "#47a0e7d9",
                      position: "sticky",
                      top: 0,
                      zIndex: 10,
                    }}
                  >
                    <tr>
                      {businessWiseReportData.length > 0 &&
                        Object.keys(businessWiseReportData[0]).map((column) => {
                          return (
                            <th
                              style={{
                                background: "#47a0e7d9",
                                padding: "8px 16px",
                              }}
                              className="text-capitalize text-xxs font-weight-bolder"
                            >
                              {column}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: "14px" }}>
                    {isLoading && <SkeletonComp totalCol={9} />}
                    {!isLoading &&
                      businessWiseReportData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {Object.keys(businessWiseReportData[0]).map(
                            (header) => (
                              <td
                                style={{
                                  padding: "8px 16px",
                                }}
                                className="text-capitalize"
                                key={header}
                              >
                                {row[header]}
                              </td>
                            )
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "5px 50px 5px 0",
                }}
              >
                {businessWiseReportData.length > 0 && (
                  <button
                    type="button"
                    key="business_summary"
                    // disabled={disableSummraybtn}
                    onClick={exportToXls}
                    className="btn bg-gradient-primary-login btn1 export-btn"
                  >
                    EXPORT
                  </button>
                )}
              </div>
            </>
          )}
          {misFilterOptions.site && (
            <>
              <div className="table-responsive p-0 dx-table-mis">
                <table
                  className="table align-items-center justify-content-center mb-0 text-white table-bordered text-center"
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  <thead
                    style={{
                      background: "#47a0e7d9",
                      position: "sticky",
                      top: 0,
                      zIndex: 10,
                    }}
                  >
                    <tr>
                      <th className="text-capitalize text-xxs font-weight-bolder site-th">
                        Business
                      </th>
                      <th className="text-capitalize text-xxs font-weight-bolder site-th">
                        Company Name
                      </th>
                      <th className="text-capitalize text-xxs font-weight-bolder site-th">
                        Site Name
                      </th>
                      <th className="text-capitalize text-xxs font-weight-bolder site-th">
                        Village
                      </th>
                      <th className="text-capitalize text-xxs font-weight-bolder site-th">
                        Survey No
                      </th>
                      <th className="text-capitalize text-xxs font-weight-bolder site-th">
                        Area (Acre)
                      </th>
                      <th className="text-capitalize text-xxs font-weight-bolder site-th">
                        Land Type
                      </th>
                      <th className="text-capitalize text-xxs font-weight-bolder site-th">
                        Sellers Name
                      </th>
                      {/* <th className="text-capitalize text-xxs font-weight-bolder site-th">
                        LendersName
                      </th> */}
                      <th className="text-capitalize text-xxs font-weight-bolder site-th">
                        Conveyance Deed (Y/N)
                      </th>
                      <th className="text-capitalize text-xxs font-weight-bolder site-th">
                        Mutation (Y/N)
                      </th>
                      <th className="text-capitalize text-xxs font-weight-bolder site-th">
                        NA (Y/N)
                      </th>
                      <th className="text-capitalize text-xxs font-weight-bolder site-th">
                        TSR (Y/N)
                      </th>
                      <th className="text-capitalize text-xxs font-weight-bolder site-th">
                        Conveyance Deed No
                      </th>
                      <th className="text-capitalize text-xxs font-weight-bolder site-th">
                        Tehsil
                      </th>
                      <th className="text-capitalize text-xxs font-weight-bolder site-th">
                        District
                      </th>
                      <th className="text-capitalize text-xxs font-weight-bolder site-th">
                        State
                      </th>
                      <th className="text-capitalize text-xxs font-weight-bolder site-th">
                        Remarks
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: "14px" }}>
                    {isLoading && <SkeletonComp totalCol={15} />}
                    {!isLoading &&
                      siteWiseReportData
                        .slice(indexOfFirstItem, indexOfLastItem)
                        .map((row: any, rowIndex) => (
                          <tr key={rowIndex}>
                            <td className="text-capitalize" key={rowIndex}>
                              {row["Business"]}
                            </td>
                            <td className="text-capitalize" key={rowIndex}>
                              {row["Company Name"]}
                            </td>
                            <td className="text-capitalize" key={rowIndex}>
                              {row["Site Name"]}
                            </td>
                            <td className="text-capitalize" key={rowIndex}>
                              {row["Village"]}
                            </td>
                            <td className="text-capitalize" key={rowIndex}>
                              {row["Survey No"]}
                            </td>
                            <td className="text-capitalize" key={rowIndex}>
                              {row["Area (Acre)"]}
                            </td>
                            <td className="text-capitalize" key={rowIndex}>
                              {row["Land Type"]}
                            </td>
                            <td className="text-capitalize" key={rowIndex}>
                              {row["Sellers Name"]}
                            </td>
                            {/* <td className="text-capitalize" key={rowIndex}>
                              {row["LendersName"]}
                            </td> */}
                            <td className="text-capitalize" key={rowIndex}>
                              {row["Conveyance Deed (Y/N)"]}
                            </td>
                            <td className="text-capitalize" key={rowIndex}>
                              {row["Mutation (Y/N)"]}
                            </td>
                            <td className="text-capitalize" key={rowIndex}>
                              {row["NA (Y/N)"]}
                            </td>
                            <td className="text-capitalize" key={rowIndex}>
                              {row["TSR (Y/N)"]}
                            </td>
                            <td className="text-capitalize" key={rowIndex}>
                              {row["Conveyance Deed No"]}
                            </td>
                            <td className="text-capitalize" key={rowIndex}>
                              {row["Tehsil"]}
                            </td>
                            <td className="text-capitalize" key={rowIndex}>
                              {row["District"]}
                            </td>
                            <td className="text-capitalize" key={rowIndex}>
                              {row["State"]}
                            </td>
                            <td className="text-capitalize" key={rowIndex}>
                              {row["Remarks"]}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "5px 50px 5px 0",
                }}
              >
                {siteWiseReportData.length > 0 && (
                  <button
                    type="button"
                    key="site_summary"
                    // disabled={disableSummraybtn}
                    onClick={exportToXls}
                    className="btn bg-gradient-primary-login btn1 export-btn-site"
                  >
                    EXPORT
                  </button>
                )}
              </div>
            </>
          )}

          {summaryType === "area" &&
            !misFilterOptions.business &&
            !misFilterOptions.site && (
              <>
                <div
                  className="table-responsive p-0 dx-table-mis"
                  /* style={{ height: "calc(100% - 25%)", overflowY: "auto" }} */
                >
                  <table
                    className="table align-items-center justify-content-center mb-0 text-white table-bordered text-center fix-column"
                    style={{ borderCollapse: "collapse", width: "100%" }}
                  >
                    <thead
                      style={{
                        background: "#47a0e7d9",
                        position: "sticky",
                        top: 0,
                        zIndex: 10,
                      }}
                    >
                      <tr>
                        {areaSummaryData.length > 0 &&
                          Object.keys(areaSummaryData[0]).map((column) => {
                            return (
                              <th
                                style={{
                                  background: "#47a0e7d9",
                                  padding: "8px 16px",
                                }}
                                className="text-capitalize text-xxs font-weight-bolder"
                              >
                                {column}
                              </th>
                            );
                          })}
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "14px" }}>
                      {isLoading && <SkeletonComp totalCol={15} />}
                      {!isLoading &&
                        areaSummaryData
                          .filter((a: any) => a["State"] && a["State"] !== "")
                          .map((row, rowIndex) => (
                            <tr style={{ position: "inherit" }} key={rowIndex}>
                              {Object.keys(areaSummaryData[0]).map((header) => (
                                <td
                                  style={{
                                    padding: "8px 16px",
                                  }}
                                  className="text-capitalize"
                                  key={header}
                                >
                                  {/* {handlerFormatter(row[header])} */}
                                  {row[header]}
                                </td>
                              ))}
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "5px 50px 5px 0",
                  }}
                >
                  {areaSummaryData.length > 0 && (
                    <button
                      type="button"
                      key="area_summary"
                      // disabled={disableSummraybtn}
                      onClick={exportToXls}
                      className="btn bg-gradient-primary-login btn1 export-btn"
                    >
                      EXPORT
                    </button>
                  )}
                </div>
              </>
            )}

          {summaryType === "count" &&
            !misFilterOptions.business &&
            !misFilterOptions.site && (
              <>
                <div
                  className="table-responsive p-0 dx-table-mis"
                  /* style={{ height: "calc(100% - 25%)", overflowY: "auto" }} */
                >
                  <table
                    className="table align-items-center justify-content-center mb-0 text-white table-bordered text-center fix-column"
                    style={{ borderCollapse: "collapse", width: "100%" }}
                  >
                    <thead
                      style={{
                        background: "#47a0e7d9",
                        position: "sticky",
                        top: 0,
                        zIndex: 10,
                      }}
                    >
                      <tr>
                        {countSummaryData.length > 0 &&
                          Object.keys(countSummaryData[0]).map((column) => {
                            return (
                              <th
                                style={{
                                  background: "#47a0e7d9",
                                  padding: "8px 16px",
                                }}
                                className="text-capitalize text-xxs font-weight-bolder"
                              >
                                {column}
                              </th>
                            );
                          })}
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      {isLoading && <SkeletonComp totalCol={15} />}
                      {!isLoading &&
                        countSummaryData
                          .filter((a: any) => a["State"] && a["State"] !== "")
                          .map((row, rowIndex) => (
                            <tr style={{ position: "inherit" }} key={rowIndex}>
                              {Object.keys(countSummaryData[0]).map(
                                (header) => (
                                  <td
                                    className="text-capitalize table-head"
                                    key={header}
                                    style={{ padding: "8px 16px" }}
                                  >
                                    {row[header]}
                                  </td>
                                )
                              )}
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "5px 50px 5px 0",
                  }}
                >
                  {countSummaryData.length > 0 && (
                    <button
                      type="button"
                      key="count_summary"
                      // disabled={disableSummraybtn}
                      onClick={exportToXls}
                      className="btn bg-gradient-primary-login btn1 export-btn"
                    >
                      EXPORT
                    </button>
                  )}
                </div>
              </>
            )}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};
