// imports
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  requestProposalToggle,
  proposalId,
  acquistionStatusToggle,
  updateProposalList,
  proposalIdForAddEngReport,
  proposalIdForViewEngReport,
  editProposalListID,
  editProposalListToggle,
  sendProposalToggle,
} from "../../state/atom/proposal";
import { useEffect, useRef, useState } from "react";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { Link } from "react-router-dom";
import { proposalDetailsToggle } from "../../state/atom/proposal";
import { requestId, requestProposalKmlData } from "../../state/atom/request";
import { aggregatorToggle } from "../../state/atom/aggregator";
import {
  editEngReportListID,
  editEngReportListToggle,
  engReportToggle,
  viewEngReportToggle,
} from "../../state/atom/eng-report";
import { checkPermission } from "../../utility/permission";
import { JwtPayLoad } from "../../shared/jwt.interface";
import { getUserDetails } from "../../utility/jwt";
import { listHighlighter } from "../../state/atom/listhighlighter";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as XLSX from "xlsx";
import fileDownload from "js-file-download";
import { LandType1 } from "../../shared/generic.constant";
import { ExportEngReport } from "../eng-report/export-eng-report";
import { SendProposal } from "./send-proposal";

// Component displaying a list of proposal.
export const ProposalList = () => {
  // State and hooks
  const [reqProposalListToggle, setProposalListToggle] = useRecoilState(
    requestProposalToggle
  );
  const setEngReportToggle = useSetRecoilState(engReportToggle);
  const setAcqStatusToggle = useSetRecoilState(acquistionStatusToggle);
  const setProposalDetailsToggle = useSetRecoilState(proposalDetailsToggle);
  const setAddAggregatorToggle = useSetRecoilState(aggregatorToggle);
  const setViewEngReportToggle = useSetRecoilState(viewEngReportToggle);
  const reqId = useRecoilValue(requestId);
  const [proposalID, setProposalId] = useRecoilState(proposalId);
  const setProposalIdForAddEngReport = useSetRecoilState(
    proposalIdForAddEngReport
  );
  const setProposalIdForViewEngReport = useSetRecoilState(
    proposalIdForViewEngReport
  );
  const [updateProposalLists, setUpdateProposalList] =
    useRecoilState(updateProposalList);
  const [listHighLighter, setListHighLighter] = useRecoilState(listHighlighter);
  const [proposalErrorMessage, setProposalErrorMessage] = useState("");
  const [proposalData, setProposalData] = useState([
    {
      id: "",
      proposalId: "",
      proposalName: "",
      isEngReport: false,
      aggregatorId: "",
      assignedTo: "",
      offeredArea: 0,
      noOfPropertyOwner: 0,
      location: "",
      locationLat: "",
      locationLong: "",
      offeredRate: 0,
      otherDetails: "",
      comment: "",
      createdDateTime: "",
      assignedDateTime: "",
      isDeleted: false,
      isInline: false,
      inLineComment: "",
      isDeviated: false,
      deviatedComment: "",
      distanceFromRoad: 0,
      distanceFromRail: 0,
      distanceFromCity: 0,
      circleRateFile: "",
      locationFile: "",
      updatedDateTime: "",
      proposalType: "",
      landType: [],
      proposalStatus: "",
      createdBy: "",
      updatedBy: "",
      requestId: "",
      proposalAssignedComment: "",
      kmlData: "",
      assignedBy: "",
      proposalAcceptorId: "",
      aggregatorId2: {
        firstName: "",
        lastName: "",
      },
      request: {
        requestId: "",
        requestName: "",
        businessUnitName: {
          name: "",
        },
      },
      createdBy2: {
        firstName: "",
        lastName: "",
      },
      assignedTo2: "",
      updatedBy2: "",
      proposalAcceptorId2: "",
      assignedBy2: "",
    },
  ]);
  const [proposalDataClone, setProposalDataClone] = useState([]);
  const [aggregatorID, setAggregatorID] = useState();
  const setRequestProposalKmlData = useSetRecoilState(requestProposalKmlData);
  const [editPropID, setEditProposalId] = useRecoilState(editProposalListID);
  const [editProposalToggle, setEditProposalToggle] = useRecoilState(
    editProposalListToggle
  );
  const [sendPropToggle, setSendProposalToggle] =
    useRecoilState(sendProposalToggle);
  const [editEngReportID, setEditEngReportId] =
    useRecoilState(editEngReportListID);
  const [editEngReportToggle, setEditEngReportToggle] = useRecoilState(
    editEngReportListToggle
  );
  // Show the proposal detail
  const setProposalDetailsID = (id: string) => {
    setProposalDetailsToggle(true);
    setProposalId(id);
  };

  // This line retrieves the user details from the JWT payload using the getUserDetails utility function.
  const { user }: JwtPayLoad = getUserDetails();

  useEffect(() => {
    setProposalData([]);
    setProposalErrorMessage("");
    // Make GET request for get all users for all users API end point
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}proposal/${reqId}`,
      null,
      apiHeaders()
    )
      .then((res) => {
        setProposalData(res.data.proposal);
        setProposalDataClone(res.data.proposal);
        setUpdateProposalList(false);
      })
      .catch((err) => {
        setProposalErrorMessage(err.response.data.message);
      });
  }, [updateProposalLists, reqId, setUpdateProposalList]);

  const getStatusColor = (val: string) => {
    if (val === "rejected") {
      return "reject-status";
    }
    if (val === "accepted") {
      return "accept-status";
    }
    if (val === "forwarded") {
      return "forward-status";
    }
    if (val === "pending") {
      return "pending-request";
    }
    if (val === "draft") {
      return "pending-request";
    }
  };

  const getStatusName = (val: string) => {
    if (val === "rejected") {
      return "Rejected";
    }
    if (val === "accepted") {
      return "Accepted";
    }
    if (val === "forwarded") {
      return "Forwarded";
    }
    if (val === "pending") {
      return "Pending";
    }
    if (val === "draft") {
      return "Draft";
    }
  };

  const filterRequest = (e: any) => {
    let filteredProposal: any = proposalDataClone.filter((proposal: any) => {
      return (
        proposal.proposalId
          .toLowerCase()
          .indexOf(e.target.value.toLowerCase()) !== -1 ||
        proposal.proposalName
          .toLowerCase()
          .indexOf(e.target.value.toLowerCase()) !== -1
      );
    });
    setProposalData(filteredProposal);
  };

  const [selectedProposal, setSelectedProposal] = useState<any>([]);
  const [selectedAllProposal, setAllSelectedProposal] = useState<any>(false);

  const selectAllProposal = (e: any) => {
    if (e.target.checked) {
      const proposalsIds = proposalData?.map((req) => {
        return req.id;
      });
      setSelectedProposal(proposalsIds);
      setAllSelectedProposal(true);
    } else {
      setSelectedProposal([]);
      setAllSelectedProposal(false);
    }
  };

  const selectProposal = (e: any) => {
    if (e.target.checked) {
      setSelectedProposal((proposalsIds: any) => {
        return [...proposalsIds, e.target.value];
      });
    } else {
      setAllSelectedProposal(false);
      setSelectedProposal((proposalsIds: any) => {
        const proposals = proposalsIds.filter(
          (popslId: string) => popslId !== e.target.value
        );
        return [...proposals];
      });
    }
  };

  const exportProposalToXlsx = () => {
    const requestExportData: any = [];
    let proposalObject: any = {};
    selectedProposal.forEach((popslId: string) => {
      const prpslDetails: any = proposalData.find(
        (req: any) => req.id === popslId
      );
      proposalObject = {};
      proposalObject["Proposal Name"] = prpslDetails.proposalName;
      proposalObject["Request Id"] = prpslDetails.requestId;
      proposalObject["Request Name"] = prpslDetails.request.requestName;
      //proposalObject["Is EngReport"] = prpslDetails.isEngReport;
      proposalObject["Assigned To"] = prpslDetails.assignedTo2 || "";
      proposalObject["offered Area"] = prpslDetails.offeredArea || "";
      proposalObject["No Of Property Owner"] =
        prpslDetails.noOfPropertyOwner || "";
      proposalObject["Location"] = prpslDetails.location || "";
      proposalObject["Latitude"] = prpslDetails.locationLat || "";
      proposalObject["Longitude"] = prpslDetails.locationLong || "";
      proposalObject["Offered Rate"] = prpslDetails.offeredRate || "";
      proposalObject["Other Details"] = prpslDetails.otherDetails || "";
      proposalObject["Comment"] = prpslDetails.comment || "";
      proposalObject["Created Date Time"] = prpslDetails.createdDateTime || "";
      /* proposalObject["Assigned Date Time"] =
        prpslDetails.assignedDateTime || ""; */
      proposalObject["Is Inline"] = prpslDetails.isInline || "";
      proposalObject["In Line Comment"] = prpslDetails.inLineComment || "";
      proposalObject["Is Deviated"] = prpslDetails.isDeviated || "";
      proposalObject["Deviated Comment"] = prpslDetails.deviatedComment || "";
      proposalObject["Distance From Road"] =
        prpslDetails.distanceFromRoad || "";
      proposalObject["Distance From Rail"] =
        prpslDetails.distanceFromRail || "";
      proposalObject["Distance From City"] =
        prpslDetails.distanceFromCity || "";
      proposalObject["Circle Rate File"] = prpslDetails.circleRateFile || "";
      proposalObject["Location File"] = prpslDetails.locationFile || "";
      proposalObject["Proposal Type"] = prpslDetails.proposalType || "";
      proposalObject["Land Type"] =
        prpslDetails?.landType
          ?.map(
            (value: any) =>
              LandType1.find((entry) => entry.value === value)?.label
          )
          .filter((label: any) => label)
          .join(", ") || "";
      proposalObject["Proposal Status"] = prpslDetails.proposalStatus || "";
      proposalObject["Proposal Assigned Comment"] =
        prpslDetails.proposalAssignedComment || "";

      proposalObject["Aggregator Name"] =
        prpslDetails.aggregatorId2 &&
        `${prpslDetails.aggregatorId2.firstName} ${prpslDetails.aggregatorId2.lastName}`;

      proposalObject["Created By"] = prpslDetails.createdBy2.firstName
        ? `${prpslDetails.createdBy2.firstName} ${prpslDetails.createdBy2.lastName}`
        : "";

      requestExportData.push(proposalObject);
    });

    const worksheet = XLSX.utils.json_to_sheet(requestExportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    fileDownload(dataBlob, `Proposal Report.xlsx`);
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClearSearch = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setProposalData(proposalDataClone);
    }
  };
  return (
    <>
      <div
        className={
          "proposal-list-init  " +
          (!reqProposalListToggle ? "proposal-list-init--collapsed" : "")
        }
      >
        {/* Heading panel */}
        <div
          className="text-right panel-title mb-0"
          style={{ background: "#375db1" }}
        >
          <span
            style={{
              color: "white",
              fontSize: "24px",
              paddingLeft: "20px",
              display: "flex",
            }}
          >
            Existing Proposal List
            {selectedProposal.length > 0 && (
              <button
                type="button"
                onClick={exportProposalToXlsx}
                className="btn bg-gradient-primary-login btn1"
                style={{ margin: "5px 3px" }}
              >
                Export
              </button>
            )}
            <div className="form-group has-search user-search-box">
              <span className="fa fa-search form-control-feedback proposal-search-icon"></span>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                ref={inputRef}
                onChange={filterRequest}
              />
              <i
                className="bi bi-x-circle-fill cancle-icon-proposal"
                onClick={handleClearSearch}
              ></i>
            </div>
          </span>

          <span
            className="close-btn"
            id="proposal-list-close"
            onClick={() => {
              setViewEngReportToggle(false);
              setAcqStatusToggle(false);
              setProposalListToggle(false);
              setRequestProposalKmlData([]);
            }}
          >
            &times;
          </span>
        </div>
        <div>
          <div className="table-responsive p-0 dx-table">
            <table className="table align-items-center justify-content-center mb-0 text-white">
              <thead
                style={{ background: "#47a0e7d9", color: "white" }}
                className="table-head"
              >
                <tr>
                  <th className="text-uppercase text-xxs font-weight-bolder ps-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedAllProposal}
                      onChange={(e) => selectAllProposal(e)}
                    />
                  </th>
                  <th className="text-uppercase text-xxs font-weight-bolder">
                    Proposal ID
                  </th>
                  <th className="text-uppercase text-xxs font-weight-bolder ps-2">
                    Proposal Name
                  </th>
                  <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                    Proposal DETAILS
                  </th>
                  {checkPermission(user?.role, "proposal", "can_send") && (
                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Send Proposal
                    </th>
                  )}
                  {checkPermission(user?.role, "status", "proposal_status") && (
                    <th className="text-uppercase text-xxs font-weight-bolder text-center ps-2">
                      Proposal Status
                    </th>
                  )}
                </tr>
              </thead>
              <tbody style={{ fontSize: "14px" }}>
                {/* Whole proposal detail */}
                {proposalData?.map((item: any, index: number) => {
                  return (
                    <tr
                      key={index}
                      className={
                        listHighLighter
                          ? proposalID === item.id
                            ? "selected-row text-white"
                            : aggregatorID === item.aggregatorId
                            ? "selected-row text-white"
                            : ""
                          : ""
                      }
                    >
                      <td className="td">
                        <input
                          type="checkbox"
                          value={item.id}
                          className="form-check-input"
                          onChange={(e) => selectProposal(e)}
                          checked={
                            selectedProposal.includes(item.id) ? true : false
                          }
                        />
                      </td>
                      <td>
                        <div className="d-flex px-2">
                          <div className="my-auto">
                            <h6 className="mb-0 text-sm">
                              {item.id && (
                                <CopyToClipboard text={item.id}>
                                  <i
                                    className="material-icons text-sm me-2 a-link"
                                    title="Copy Proposal ID"
                                  >
                                    content_copy
                                  </i>
                                </CopyToClipboard>
                              )}

                              {item.proposalId}
                            </h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p className="text-sm font-weight-bold mb-0">
                          {item.proposalName}
                        </p>
                      </td>
                      <td>
                        {item.proposalId && item.proposalName ? (
                          <div className="ms-auto">
                            <Link
                              to=""
                              className="request-detail-init-button btn btn-link text-white text-gradient px-3 mb-0"
                              onClick={() => {
                                setProposalDetailsID(item.id);
                                setAddAggregatorToggle(false);
                                setAcqStatusToggle(false);
                                setListHighLighter(true);
                              }}
                            >
                              <i className="material-icons text-sm me-2">
                                real_estate_agent
                              </i>
                              View Proposal
                            </Link>
                            {checkPermission(
                              user?.role,
                              "proposal",
                              "can_update"
                            ) && (
                              <span title="Edit Proposal">
                                <i
                                  style={{ cursor: "pointer" }}
                                  className="material-icons text-sm edit-btn"
                                  onClick={() => {
                                    setEditProposalToggle(true);
                                    setEditProposalId(item.id);
                                  }}
                                >
                                  edit
                                </i>
                              </span>
                            )}

                            {item.isEngReport === true
                              ? (item.proposalStatus === "accepted" || item.proposalStatus === "forwarded") && (
                                  <>
                                    <Link
                                      className="drawer-eng-button btn btn-link text-white text-gradient px-3 mb-0"
                                      to=""
                                      onClick={() => {
                                        setAcqStatusToggle(false);
                                        setProposalIdForViewEngReport(item.id);
                                        setViewEngReportToggle(true);
                                        setProposalId(item.id);
                                      }}
                                    >
                                      <i className="material-icons text-sm me-2">
                                        article
                                      </i>
                                      View EngReport
                                    </Link>
                                    {checkPermission(
                                      user?.role,
                                      "eng_report",
                                      "can_edit"
                                    ) && (
                                      <span title="Edit Eng Report">
                                        <i
                                          style={{ cursor: "pointer" }}
                                          className="material-icons text-sm edit-btn"
                                          onClick={() => {
                                            setEditEngReportToggle(true);
                                            setEditEngReportId(item.id);
                                          }}
                                        >
                                          edit
                                        </i>
                                      </span>
                                    )}

                                    <ExportEngReport
                                      id={item.id}
                                    ></ExportEngReport>
                                  </>
                                )
                              : (item.proposalStatus === "accepted" || item.proposalStatus === "forwarded") &&
                                checkPermission(
                                  user?.role,
                                  "eng_report",
                                  "can_add"
                                ) && (
                                  <Link
                                    className="drawer-eng-button btn btn-link text-white text-gradient px-3 mb-0"
                                    to=""
                                    onClick={() => {
                                      setEngReportToggle(true);
                                      setProposalIdForAddEngReport(item.id);
                                    }}
                                  >
                                    <i className="material-icons text-sm me-2">
                                      article
                                    </i>
                                    Add EngReport
                                  </Link>
                                )}

                            {/* {(item.proposalStatus === "accepted" || item.proposalStatus === "forwarded") &&
                                checkPermission(
                                  user?.role,
                                  "proposal",
                                  "can_view_acquistion_status"
                                )  && (
                              <Link
                                className="drawer-eng-button btn btn-link text-white text-gradient px-3 mb-0"
                                to=""
                                onClick={() => {
                                  setViewEngReportToggle(false);
                                  setAcqStatusToggle(true);
                                  setProposalDetailsToggle(false);
                                  setAggregatorID(item.aggregatorId);
                                  setListHighLighter(true);
                                }}
                              >
                                <i className="material-icons text-sm me-2">
                                  handshake
                                </i>
                                Acquistion Status
                              </Link>
                            )} */}
                          </div>
                        ) : null}
                      </td>
                      {checkPermission(user?.role, "proposal", "can_send") && (
                        <td>
                          {item.proposalStatus === "draft" && (
                            <button
                              className="btn send-proposal"
                              onClick={() => {
                                setSendProposalToggle(true);
                                setProposalId(item.id);
                              }}
                              disabled={item.kmlData == null ? true : false}
                            >
                              Send
                            </button>
                          )}
                          {item.proposalStatus !== "draft" && (
                            <button className="btn send-proposal">Sent</button>
                          )}
                        </td>
                      )}
                      {checkPermission(
                        user?.role,
                        "status",
                        "proposal_status"
                      ) && (
                        <td>
                          <span
                            className={`text-gradient px-3 mb-0 ${getStatusColor(
                              item.proposalStatus
                            )}`}
                          >
                            {getStatusName(item.proposalStatus)}
                          </span>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* <div className="d-flex justify-content-center">
              {!proposalData[0].proposalId && !proposalData[0].proposalName ? (
                <div>
                  {proposalErrorMessage ? (
                    <span
                      className="badge bg-primary"
                      style={{ fontSize: "1.2rem", marginTop: "10px" }}
                    >
                      Proposal Not Found!
                    </span>
                  ) : (
                    <span className="text-center text-white">Loading...</span>
                  )}
                </div>
              ) : null}
            </div> */}
          </div>
        </div>
      </div>
      <SendProposal></SendProposal>
    </>
  );
};
