import { atom } from "recoil";
export const proposalDetailsToggle = atom({
  key: "proposalDetailsToggle",
  default: false,
});

export const requestProposalToggle = atom({
  key: "requestProposalToggle",
  default: false,
});
export const updateProposalList = atom({
  key: "updateProposalList",
  default: false,
});
export const editProposalListToggle = atom({
  key: "editProposalListToggle",
  default: false,
});
export const editProposalListID = atom({
  key: "editProposalListID",
  default: '',
});
export const proposalId = atom({
  key: "proposalId",
  default: "",
});

export const proposalIdForAddEngReport = atom({
  key: "proposalIdForAddEngReport",
  default: "",
});

export const proposalIdForViewEngReport = atom({
  key: "proposalIdForViewEngReport",
  default: "",
});

export const addProposalToggle = atom({
  key: "addProposalToggle",
  default: false,
});

export const acquistionStatusToggle = atom({
  key: "acquistionStatusToggle",
  default: false,
});

export const kmlData = atom({
  key: "kmlData",
  default: {} as any,
});

export const sendProposalToggle = atom({
  key: "sendProposalToggle",
  default: false,
});
