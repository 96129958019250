import { useEffect } from "react";
import { Toaster } from "../component/toaster";
import { MainLayout } from "../layout/main-layout";
import { useSetRecoilState } from "recoil";
import { selectedMenu } from "../state/atom/generic";
import { Menu } from "../shared/generic.constant";
import { MisList } from "../component/report/mis-list";
import { MisFilter } from "../component/report/mis-filter";
import { ReportMap } from "../component/report/report-map";
import { validatePermission } from "../utility/permission";

export const Report = () => {
  validatePermission("menu", "report");
  const setSelectedMenu = useSetRecoilState(selectedMenu);

  useEffect(() => {
    setSelectedMenu(Menu.report);
  }, []);
  return (
    <MainLayout>
      <Toaster></Toaster>
      <MisFilter></MisFilter>
      <ReportMap classList="full-map gis-map"></ReportMap>
      <MisList></MisList>
    </MainLayout>
  );
};
