import React, { useEffect, useRef, useState } from "react";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";
import { Http } from "../../utility/http";
import { State } from "../../shared/generic.interface";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  addStateModalToggle,
  // deleteStateModalToggle,
  editStateModalToggle,
  stateIdState,
  stateListState,
} from "../../state/atom/settings";
import { AddStateModal } from "./Modals/State/AddStateModal";
import { EditStateModal } from "./Modals/State/EditStateModal";
// import { DeleteStateModal } from "./Modals/State/DeleteStateModal";
import { SkeletonComp } from "../../shared/Skeleton/Skeleton";

const StatesTableList: React.FC = () => {
  const [stateList, setStateList] = useState<Array<State>>([]);
  const [stateListClone, setStateListClone] = useState<Array<State>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const setAddStateModalToggle = useSetRecoilState(addStateModalToggle);
  const setEditStateModalToggle = useSetRecoilState(editStateModalToggle);
  // const setDeleteStateModalToggle = useSetRecoilState(deleteStateModalToggle);
  const showAddModal = useRecoilValue(addStateModalToggle);
  const showEditModal = useRecoilValue(editStateModalToggle);
  // const showDeleteModal = useRecoilValue(deleteStateModalToggle);
  const setStateId = useSetRecoilState(stateIdState);
  const setStateListData = useSetRecoilState(stateListState);
  const ITEMS_PER_PAGE: number = 10;
  const SR_NO = (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const TOTAL_PAGES = Math.ceil(stateList.length / ITEMS_PER_PAGE);
  // Calculate the range of visible pages based on the current page
  const VISIBLE_PAGE = 5;
  const startPage = Math.max(1, currentPage - Math.floor(VISIBLE_PAGE / 2));
  const endPage = Math.min(TOTAL_PAGES, startPage + VISIBLE_PAGE - 1);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const getStates = async () => {
      setLoading(true);
      await Http.request("get", `${process.env.REACT_APP_API_URL}states`)
        .then((result: any) => {
          setStateList(result.data.items);
          setStateListData(result.data.items);
          setStateListClone(result.data.items);
          setLoading(false);
        })
        .catch((error: any) => {
          console.error(error);
        });
    };
    getStates();
  }, [showAddModal, showEditModal]);

  //pagination code...
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const paginateData = stateList.slice(indexOfFirstItem, indexOfLastItem);

  //handle search feature
  const filterRequest = (event: any) => {
    let filteredRequest: any = stateListClone.filter((state: State) => {
      return (
        state.stateName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1
      );
    });
    setStateList(filteredRequest);
  };

  const handleShowAddModal = () => {
    setAddStateModalToggle(true);
  };

  const handleShowEditModal = () => {
    setEditStateModalToggle(true);
  };

  // const handleShowDeleteModal = () => {
  //   setDeleteStateModalToggle(true);
  // };
  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleClearSearch = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setStateList(stateListClone);
    }
  };

  return (
    <>
      <Card>
        <div className="panel-title mb-0">
          <div className="">
            <span
              style={{ color: "white", fontSize: "24px", paddingLeft: "20px" }}
            >
              States List
            </span>
          </div>

          {/* Search box */}
          <div className="form-group has-search user-search-box">
            <span className="fa fa-search form-control-feedback"></span>

            <input
              type="text"
              className="form-control"
              placeholder="Search"
              ref={inputRef}
              onChange={filterRequest}
            />
            {inputRef?.current?.value !== "" && (
              <i
                className="bi bi-x-circle-fill cancle-icon"
                onClick={handleClearSearch}
              ></i>
            )}
          </div>

          <div className="">
            <span
              style={{
                paddingRight: "60px",
                paddingTop: "2px",
                paddingBottom: "2px",
              }}
            >
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleShowAddModal}
              >
                Add State
              </button>
            </span>
          </div>

          {/* Close Icon */}
          {/* <span className="close-btn" id="state-mgt-close">
            &times;
          </span> */}
        </div>
        <div className="table-responsive p-0" style={{ maxHeight: "350px" }}>
          {/* List of the state in table form */}
          <table className="table table-hover align-items-center justify-content-center mb-0 text-white">
            <thead
              style={{ background: "#6a294b", color: "white" }}
              className="table-head"
            >
              <tr>
                <th className="text-uppercase text-xxs font-weight-bolder">
                  No
                </th>
                <th className="text-uppercase text-xxs font-weight-bolder">
                  State Name
                </th>
                <th className="text-uppercase text-xxs font-weight-bolder">
                  State Code
                </th>

                <th className="text-uppercase text-xxs font-weight-bolder">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "14px" }}>
              {loading && <SkeletonComp totalCol={4} />}
              {!loading &&
                paginateData.length > 0 &&
                paginateData.map((state: any, index: number) => {
                  return (
                    <tr key={index} style={{ color: "black" }}>
                      <td>
                        <div className="d-flex px-2">
                          <div className="my-auto">
                            <h6 className="mb-0 text-sm">{SR_NO + index}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        {" "}
                        <p className="text-sm font-weight-bold mb-0">
                          {state.stateName}
                        </p>
                      </td>
                      <td>
                        {" "}
                        <p className="text-sm font-weight-bold mb-0">
                          {state.stateCode}
                        </p>
                      </td>
                      <td>
                        <div className="ms-auto">
                          <Link
                            className="fixed-plugin-button btn btn-sm  btn-primary text-gradient"
                            to=""
                            onClick={() => {
                              setStateId(state?.id);
                              handleShowEditModal();
                            }}
                          >
                            <i className="material-icons">edit</i>
                          </Link>
                          &nbsp;
                          {/* <Link
                            className="fixed-plugin-button btn btn-sm btn-danger text-gradient"
                            to=""
                            onClick={() => {
                              setStateId(state?.id);
                              handleShowDeleteModal();
                            }}
                          >
                            <i className="material-icons">close</i>
                          </Link> */}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {!loading && (
          <div className="d-flex justify-content-center pt-3">
            <Pagination>
              {currentPage > 1 && (
                <>
                  <Pagination.First
                    onClick={() => handlePageChange(1)}
                    disabled={currentPage === 1}
                  />
                  <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                </>
              )}
              {Array.from({ length: endPage - startPage + 1 }).map(
                (_, index: number) => (
                  <Pagination.Item
                    key={startPage + index}
                    active={startPage + index === currentPage}
                    onClick={() => handlePageChange(startPage + index)}
                  >
                    {startPage + index}
                  </Pagination.Item>
                )
              )}
              {currentPage !== TOTAL_PAGES && (
                <>
                  <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === TOTAL_PAGES}
                  />
                  <Pagination.Last
                    onClick={() => handlePageChange(TOTAL_PAGES)}
                    disabled={currentPage === TOTAL_PAGES}
                  />
                </>
              )}
            </Pagination>
          </div>
        )}
      </Card>
      {showAddModal && <AddStateModal />}
      {showEditModal && <EditStateModal />}
      {/* {showDeleteModal && <DeleteStateModal />} */}
    </>
  );
};

export default StatesTableList;
