import { atom } from "recoil";
import { Menu, SettingsMenu } from "../../shared/generic.constant";
import { Business, Company } from "../../shared/generic.interface";
export const mapCenterPosition = [79.0882, 21.1458];
export const myProfileToggle = atom({
  key: "myProfileToggle",
  default: false,
});

export const changePasswordToggle = atom({
  key: "changePasswordToggle",
  default: false,
});

export const selectedMenu = atom({
  key: "selectedMenu",
  default: Menu.home,
});

export const mapCenter = atom({
  key: "mapCenter",
  default: {
    zoom: 14,
    center: mapCenterPosition,
    isShow: false,
  },
});

export const businessUnit = atom({
  key: "businessUnit",
  default: [] as Business[],
});

export const companies = atom({
  key: "companies",
  default: [] as Company[],
});

export const mapCellClass = atom({
  key: "mapCellClass",
  default: false,
});

export const locationLatLon = atom({
  key: "locationLatLon",
  default: {
    lat: 0,
    lon: 0,
  },
});

export const selectedSettingsMenu = atom({
  key: 'selectedSettingsMenu',
  default: SettingsMenu.state
})
