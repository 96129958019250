// @ts-nocheck
import { useState, useEffect } from "react";
import { LandRequestForm } from "../component/request/land-request-form";
import { MainLayout } from "../layout/main-layout";
import { LandRequestList } from "../component/request/land-request-list";
import { Http } from "../utility/http";
import { apiHeaders } from "../utility/generic";
import { Link } from "react-router-dom";
import { LandRequestDetails } from "../component/request/land-request-details";
import { ProposalList } from "../component/proposal/proposal-list";
import { Toaster } from "../component/toaster";

import { ProposalAddForm } from "../component/proposal/proposal-add-form";
import {
  requestDetailsToggle,
  requestList,
  requestListClone,
  requestListToggle,
  requestToggle,
  updateRequestList,
} from "../state/atom/request";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ProposalDetails } from "../component/proposal/proposal-details";
import { getUserDetails } from "../utility/jwt";
import { JwtPayLoad } from "../shared/jwt.interface";
import { checkPermission, validatePermission } from "../utility/permission";
import { RequestHomeMap } from "../component/request-home-map";
import UserList from "../component/user/user-list";
import {
  addUserToggle,
  editUserToggle,
  userList,
  userListClone,
  usersListToggle,
} from "../state/atom/user";
import { UserEdit } from "../component/user/user-edit";
import { updateUserList } from "../state/atom/user";
import { AcquistionStatus } from "../component/proposal/acquistion-status";
import { AggregatorRegistration } from "../component/aggregator/aggregator-registration";
import { aggregatorToggle } from "../state/atom/aggregator";
import { businessUnit, companies, selectedMenu } from "../state/atom/generic";
import { Menu } from "../shared/generic.constant";
import { AddUserForm } from "../component/user/add-user-form";
import {
  acquistionStatusToggle,
  addProposalToggle,
  proposalDetailsToggle,
  requestProposalToggle,
} from "../state/atom/proposal";
import { EngineerReportForm } from "../component/eng-report/engineer-report-form";
import { ViewEngReport } from "../component/eng-report/engineer-report-detail";
import { LandRequestEditForm } from "../component/request/land-request-edit-form";
import { ProposalEditForm } from "../component/proposal/proposal-edit-form";
import { EngineerReportUpdateForm } from "../component/eng-report/engineer-report-edit-form";

// component showing the home page details
export const Home = () => {
  validatePermission("menu", "home");

  // state and hooks
  const setSelectedMenu = useSetRecoilState(selectedMenu);
  const setRequestDetailsToggle = useSetRecoilState(requestDetailsToggle);
  const setProposalDetailsToggle = useSetRecoilState(proposalDetailsToggle);
  const setAddProposalToggle = useSetRecoilState(addProposalToggle);
  const setAcquistionStatusToggle = useSetRecoilState(acquistionStatusToggle);
  const setAddAggregatorToggle = useSetRecoilState(aggregatorToggle);
  const setProposalListToggle = useSetRecoilState(requestProposalToggle);
  const setBusinessUnit = useSetRecoilState(businessUnit);
  const setCompanies = useSetRecoilState(companies);
  const [reqToggle, setToogleRequest] = useRecoilState(requestToggle);
  const setAggreToggle = useSetRecoilState(aggregatorToggle);
  const setRequestList = useSetRecoilState(requestList);
  const setRequestListClone = useSetRecoilState(requestListClone);
  const setUserListClone = useSetRecoilState(userListClone);
  const [reqListToggle, setToogleRequestList] =
    useRecoilState(requestListToggle);
  const [userListToggle, setUserListToggle] = useRecoilState(usersListToggle);
  const [users, setUsers] = useState([]);
  const setUserList = useSetRecoilState(userList);
  const [proposal, setProposal] = useState([]);
  const [updateUsersList, setUpdateUsersList] = useRecoilState(updateUserList);
  const [updateReqList, setUpdateReqList] = useRecoilState(updateRequestList);
  const setEditUserToggle = useSetRecoilState(editUserToggle);
  const setAddUserToggle = useSetRecoilState(addUserToggle);

  // This line retrieves the user details from the JWT payload using the getUserDetails utility function.
  const { user }: JwtPayLoad = getUserDetails();

  useEffect(() => {
    setSelectedMenu(Menu.home);

    // Make GET request for get land request for land request API end point
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}land-request`,
      null,
      apiHeaders()
    )
      .then((result) => {
        setRequestList(result);
        setRequestListClone(result);
        setProposal(result);
        setUpdateReqUsersList(false);
      })
      .catch((error) => {});

    // Make GET request for get all users for all users API end point
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}users`,
      null,
      apiHeaders()
    )
      .then((result) => {
        setUsers(result);
        setUserList(result);
        setUserListClone(result);
        setUpdateUsersList(false);
      })
      .catch((error) => {});

    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}generic/business-unit`,
      null
    )
      .then((result) => {
        setBusinessUnit(result.data.businessUnit);
      })
      .catch((error) => {});

    Http.request("get", `${process.env.REACT_APP_API_URL}generic/company`, null)
      .then((result) => {
        setCompanies(result.data.company);
      })
      .catch((error) => {});
  }, [
    updateReqList,
    updateUsersList,
    setRequestList,
    setUpdateReqList,
    setSelectedMenu,
    setUpdateUsersList,
    setBusinessUnit,
    setCompanies,
  ]);

  return (
    // This component displays the main layout of the home page.
    <MainLayout>
      {/* Component showing the toaster bar */}
      <Toaster></Toaster>

      {/* Component showing the home page map */}
      <RequestHomeMap classList="full-map"></RequestHomeMap>

      {/* Role based condition for access the New Request options */}
      {checkPermission(user?.role, "request", "can_add") && (
        <div className="box">
          <div
            className={
              "new-request-corner-btn align-self-end " +
              (!reqToggle ? "new-request-corner-btn--collapsed" : "")
            }
            onClick={() => {
              setToogleRequest(true);
              setToogleRequestList(false);
              setRequestDetailsToggle(false);
            }}
            id="new-request-corner-btn"
          >
            <Link
              to=""
              className="nav-link font-weight-bold d-flex text-white corner-btn"
            >
              <i className="material-icons opacity-10 pe-1">checklist</i>
              <span className="d-sm-inline d-none dashboard-icons p-1">
                New Request
              </span>
            </Link>
          </div>

          {/* Component showing the land request form */}
          <LandRequestForm key="land_request_form"></LandRequestForm>
        </div>
      )}

      {/* Role based condition for access the New Aggregator options */}
      {checkPermission(user?.role, "aggregator", "can_add") && (
        <div className="box">
          <div
            className={
              "new-request-corner-btn new-request-btn align-self-end " +
              (!reqToggle ? "new-request-corner-btn--collapsed" : "")
            }
            onClick={() => {
              setAggreToggle(true);
              setToogleRequestList(false);
              setRequestDetailsToggle(false);
              setProposalListToggle(false);
              setAcquistionStatusToggle(false);
            }}
            id="new-request-corner-btn"
          >
            <Link
              to=""
              className="nav-link font-weight-bold d-flex text-white corner-btn"
            >
              <i className="material-icons opacity-10 pe-1">checklist</i>
              <span className="d-sm-inline d-none dashboard-icons p-1">
                New Aggregator
              </span>
            </Link>
          </div>

          {/* component showing the aggregator registration form */}
          <AggregatorRegistration key="aggregator_registration_form"></AggregatorRegistration>
        </div>
      )}

      {/* Request List */}
      <div className="box center-box">
        <div
          className={
            "request-list-corner-btn " +
            (!reqListToggle ? "request-list-corner-btn--collapsed" : "")
          }
          id="request-list-corner-btn"
          onClick={() => {
            setToogleRequestList(true);
            setToogleRequest(false);
            setProposalDetailsToggle(false);
            setAddAggregatorToggle(false);
            setAddProposalToggle(false);
            setAcquistionStatusToggle(false);
            setAddUserToggle(false);
            setEditUserToggle(false);
          }}
        >
          <Link
            className="nav-link font-weight-bold d-flex text-white corner-btn "
            style={{ padding: "0 5px" }}
          >
            <i className="material-icons opacity-10 pe-1">checklist</i>
            <span className="d-sm-inline d-none dashboard-icons">
              Request List
            </span>
          </Link>
        </div>

        {/* Component showing the Land request list */}
        <LandRequestList
          key="land_request_list"
          proposal={proposal}
        ></LandRequestList>
      </div>

      {/* Role based condition for access the User Management options */}
      {checkPermission(user?.role, "user", "can_view") && (
        <div className="box">
          <div
            className={
              "user-mgt-list-corner-btn " +
              (!userListToggle ? "user-mgt-list-corner-btn--collapsed" : "")
            }
            id="user-mgt-list-corner-btn"
            onClick={() => {
              setUserListToggle(true);
              setToogleRequestList(false);
              setProposalListToggle(false);
              setProposalDetailsToggle(false);
            }}
          >
            <Link
              style={{ padding: "0 5px" }}
              className="nav-link font-weight-bold d-flex text-white corner-btn"
            >
              <i className="material-icons opacity-10 pe-1">checklist</i>
              <span className="d-sm-inline d-none dashboard-icons">
                User Management
              </span>
            </Link>
          </div>

          {/* Component showing the users list */}
          <UserList key="user_list" users={users}></UserList>
        </div>
      )}
      {/* This component is responsible for rendering the details of a land request. */}
      <LandRequestDetails></LandRequestDetails>
      {/* This component provides a form for adding a proposal. */}
      <ProposalAddForm></ProposalAddForm>
      {/* This component displays a list of proposals. */}
      <ProposalList></ProposalList>
      {/* This component shows the details of a specific proposal. */}
      <ProposalDetails></ProposalDetails>
      {/* This component allows editing user information. */}
      <UserEdit></UserEdit>
      {/* This component provides a form for adding a new user. */}
      <AddUserForm></AddUserForm>
      {/* This component displays the status of an acquisition. */}
      <AcquistionStatus></AcquistionStatus>
      {/* This component displays the details of the engineer report */}
      <ViewEngReport></ViewEngReport>
      <EngineerReportForm></EngineerReportForm>
      <LandRequestEditForm></LandRequestEditForm>
      <ProposalEditForm></ProposalEditForm>
      <EngineerReportUpdateForm></EngineerReportUpdateForm>
    </MainLayout>
  );
};
