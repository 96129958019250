// imports
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useState } from "react";
import { proposalIdForViewEngReport } from "../../state/atom/proposal";
import { useEffect } from "react";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { Link } from "react-router-dom";
import { viewEngReportToggle } from "../../state/atom/eng-report";
import { checkPermission } from "../../utility/permission";
import { getUserDetails } from "../../utility/jwt";
import { JwtPayLoad } from "../../shared/jwt.interface";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { toaster } from "../../state/atom/toaster";
import { LandType1 } from "../../shared/generic.constant";
import moment from "moment";

// interfaces
interface AssignEnggReportFormInputs {
  userId: {
    label: string;
    value: string;
    firstName: string;
    lastName: string;
  };
  comment: string;
  status: string;
}

// Component displaying View Engineer details.
export const ViewEngReport = () => {
  // state and hooks
  const [loading, setLoading] = useState(false);
  const [isEnggReportUpdate, setIsEnggReportUpdate] = useState(false);
  const [viewEngineerReportToggle, setViewEngineerReportToggle] =
    useRecoilState(viewEngReportToggle);
  const proPosalID = useRecoilValue(proposalIdForViewEngReport);
  const setToasterStatus = useSetRecoilState(toaster);
  const { user }: JwtPayLoad = getUserDetails();

  const [engReportData, setEngReportData] = useState({
    id: "",
    nearByRailwayStation: "",
    nearByAirport: "",
    nearByCity: "",
    nearByHighway: "",
    nearByPoolingStation: "",

    siteLocation: "",
    updatedDateTime: "",
    comment: "",
    siteOrientation: "",
    presenceWater: "",
    landType: [],
    soilCondition: "",
    siteVisitDate: "",
    vegetation: "",
    engReportStatus: "",
    commentOnEngReport: "",

    elevationFromMsl: 0,
    seismicZone: 0,
    avgAnnualWindSpeed: 0,
    windZone: 0,
    annualRainfall: 0,
    proposedCapacity: 0,
    proposedArea: 0,

    status: null,
    availabilityOfWater: false,
    displacementOfHutsAndPeople: false,
    villageRoadPassingThoughLand: false,
    presenceOfHtLtLine: false,
    workshopStructureOrAccessRoad: false,
    isLandAdjacentToVillage: false,

    basicWindSpeedCode: "",
    locationPdf: "",
    surveyReport: "",
    photographs: [
      {
        id: 0,
        name: "",
      },
    ],
  });

  useEffect(() => {
    setIsEnggReportUpdate(false);
    if (proPosalID) {
      Http.request(
        "get",
        `${process.env.REACT_APP_API_URL}eng-report/${proPosalID}`,
        null,
        apiHeaders()
      )
        .then((result) => {
          setEngReportData(result.data.engReport);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [proPosalID, setEngReportData, isEnggReportUpdate]);

  // validation schema
  const validationSchema = Yup.object().shape({
    userId: Yup.object({
      label: Yup.string(),
      value: Yup.string(),
    }).when("status", {
      is: (val: string) => val === "forward",
      then: (validationSchema) => validationSchema.required("Select user"),
      otherwise: (validationSchema) => validationSchema.notRequired(),
    }),
    status: Yup.string().required("Select Status"),
    comment: Yup.string().when("status", {
      is: (val: string) => val === "accepted" || val === "rejected",
      then: (validationSchema) => validationSchema.required("Enter comment"),
      otherwise: (validationSchema) => validationSchema.notRequired(),
    }),
  });

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<AssignEnggReportFormInputs>({
    resolver: yupResolver(validationSchema),
  });

  const status = watch("status");

  const onSubmit = (data: AssignEnggReportFormInputs) => {
    const request = {
      commentOnEngReport: data.comment || "",
      engReportStatus: data.status,
    };
    setLoading(true);
    Http.request(
      "patch",
      `${process.env.REACT_APP_API_URL}eng-report/${engReportData?.id}`,
      request,
      apiHeaders()
    )
      .then((res) => {
        // Show a success notification to the user
        setToasterStatus({
          status: true,
          title: "Proposal",
          text:
            data.status === "accepted"
              ? "Engineer Report assigned successfully"
              : "Engineer Report rejected successfully",
        });
        setIsEnggReportUpdate(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="box">
        <div
          className={
            "analysis-detail-init " +
            (!viewEngineerReportToggle ? "analysis-detail-init--collapsed" : "")
          }
        >
          <div
            className="text-right panel-title"
            style={{ background: "#375db1" }}
          >
            <span style={{ color: "white", fontSize: "24px" }}>
              Engineering Report Detail
            </span>
            <span
              className="close-btn"
              id="analysis-detail-close"
              onClick={() => {
                setViewEngineerReportToggle(!viewEngineerReportToggle);
              }}
            >
              &times;
            </span>
          </div>
          <div style={{ overflowY: "auto", maxHeight: "85vh" }}>
            <h6 className="mb-0 panel-header">Engineering Report</h6>
            <table className="table">
              <tbody>
              <tr>
                  <th>Last Update Date Time</th>
                  <td>
                    {moment(engReportData?.updatedDateTime).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Nearest Railway Station</th>
                  <td>{engReportData?.nearByRailwayStation}</td>
                </tr>
                <tr>
                  <th>Nearest Airport</th>
                  <td>{engReportData?.nearByAirport}</td>
                </tr>
                <tr>
                  <th>Nearest City</th>
                  <td>{engReportData?.nearByCity}</td>
                </tr>

                <tr>
                  <th>Nearby Highway</th>
                  <td>{engReportData?.nearByHighway}</td>
                </tr>

                <tr>
                  <th>Nearby Pooling Station</th>
                  <td>{engReportData?.nearByPoolingStation}</td>
                </tr>
                <tr>
                  <th>Site Visit Date</th>
                  <td>{engReportData?.siteVisitDate}</td>
                </tr>

                <tr>
                  <th>Site Location</th>
                  <td>{engReportData?.siteLocation}</td>
                </tr>

                <tr>
                  <th>Comment</th>
                  <td>{engReportData?.comment}</td>
                </tr>

                <tr>
                  <th>Site Orientation</th>
                  <td>{engReportData?.siteOrientation}</td>
                </tr>

                <tr>
                  <th>Source of Water</th>
                  <td>{engReportData?.presenceWater}</td>
                </tr>

                <tr>
                  <th>Type of Land</th>
                  <td>{engReportData?.landType?.map(value => LandType1.find(entry => entry.value === value)?.label).filter(label => label).join(", ")}</td>
                </tr>

                <tr>
                  <th>Soil Condition</th>
                  <td>{engReportData?.soilCondition}</td>
                </tr>

                <tr>
                  <th>Vegetation</th>
                  <td>{engReportData?.vegetation}</td>
                </tr>

                <tr>
                  <th>Elevation from MSL</th>
                  <td>{engReportData?.elevationFromMsl}</td>
                </tr>

                <tr>
                  <th>Seismic Zone</th>
                  <td>{engReportData?.seismicZone}</td>
                </tr>

                <tr>
                  <th>Average Annual Wind Speed</th>
                  <td>{engReportData?.avgAnnualWindSpeed}</td>
                </tr>

                <tr>
                  <th>Wind Zone</th>
                  <td>{engReportData?.windZone}</td>
                </tr>

                <tr>
                  <th>Annual Rainfall</th>
                  <td>{engReportData?.annualRainfall}</td>
                </tr>

                <tr>
                  <th>Proposed Capacity</th>
                  <td>{engReportData?.proposedCapacity}</td>
                </tr>

                <tr>
                  <th>Proposed Area</th>
                  <td>{engReportData?.proposedArea}</td>
                </tr>

                <tr>
                  <th>Availability of Water</th>
                  {engReportData?.availabilityOfWater === true ? (
                    <td>Yes</td>
                  ) : (
                    <td>No</td>
                  )}
                </tr>

                <tr>
                  <th>Displacement of Huts and People</th>
                  {engReportData?.displacementOfHutsAndPeople === true ? (
                    <td>Yes</td>
                  ) : (
                    <td>No</td>
                  )}
                </tr>

                <tr>
                  <th>Village Road Passing Through Land</th>
                  {engReportData?.villageRoadPassingThoughLand === true ? (
                    <td>Yes</td>
                  ) : (
                    <td>No</td>
                  )}
                </tr>

                <tr>
                  <th>Presence of HT/LT Line</th>
                  {engReportData?.presenceOfHtLtLine === true ? (
                    <td>Yes</td>
                  ) : (
                    <td>No</td>
                  )}
                </tr>

                <tr>
                  <th>Workshop Structure or Access Road</th>
                  {engReportData?.workshopStructureOrAccessRoad === true ? (
                    <td>Yes</td>
                  ) : (
                    <td>No</td>
                  )}
                </tr>

                <tr>
                  <th>Land Adjacent to Village</th>
                  {engReportData?.isLandAdjacentToVillage === true ? (
                    <td>Yes</td>
                  ) : (
                    <td>No</td>
                  )}
                </tr>

                <tr>
                  <th>Basic Wind Speed Code</th>
                  <td>
                    {engReportData?.basicWindSpeedCode ? (
                      <Link
                        target="_blank"
                        style={{ color: "#fff" }}
                        to={engReportData?.basicWindSpeedCode}
                      >
                        PDF
                      </Link>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>

                <tr>
                  <th>Location PDF</th>
                  <td>
                    {engReportData?.locationPdf ? (
                      <Link
                        target="_blank"
                        style={{ color: "#fff" }}
                        to={engReportData?.locationPdf}
                      >
                        PDF
                      </Link>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>

                <tr>
                  <th>Survey Report</th>
                  <td>
                    {engReportData?.surveyReport ? (
                      <Link
                        target="_blank"
                        style={{ color: "#fff" }}
                        to={engReportData?.surveyReport}
                      >
                        PDF
                      </Link>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>

                {engReportData?.photographs.map((val, index) => {
                  return (
                    <tr key={index}>
                      <th>Photograph {index + 1}</th>
                      <td>
                        {val.name ? (
                          <Link
                            target="_blank"
                            style={{ color: "#fff" }}
                            to={val?.name}
                          >
                            Image
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {
              <>
                <h6 className="text-uppercase text-white font-weight-bolder mt-1 ps-2 panel-sub-title">
                  Eng Report Status
                </h6>
                <table className="table">
                  <tbody>
                    <tr>
                      <th>Status</th>
                      <td>{engReportData?.engReportStatus}</td>
                    </tr>
                    <tr>
                      <th>Comment</th>
                      <td>{engReportData?.commentOnEngReport}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            }
            {checkPermission(user?.role, "eng_report", "can_assign") &&
              engReportData?.engReportStatus === "pending" && (
                <>
                  <h6 className="text-uppercase text-white font-weight-bolder mt-1 ps-2 panel-sub-title">
                    Manage Eng Report Status
                  </h6>

                  {/* Form for assign the user */}
                  <form
                    className="text-white"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="tab-content px-2" id="myTabContent">
                      <div
                        className="tab-pane active"
                        id="accept"
                        role="tabpanel"
                        aria-labelledby="accept-tab"
                      >
                        <div className="form-group px-3 py-2">
                          <label htmlFor="inputSRequest">Select Status</label>
                          <select
                            className="form-select"
                            aria-label="Default select status"
                            {...register("status")}
                          >
                            <option value="">
                              ---Please Select Status Name---
                            </option>
                            <option value="accepted">Accept</option>
                            <option value="rejected">Reject</option>
                          </select>
                          <small
                            id="sRequestHelp"
                            className="form-text text-white"
                          >
                            Status will be shown here. {status}
                          </small>
                          <br></br>
                          {errors.status && (
                            <span className="text-warning">
                              {errors.status.message}
                            </span>
                          )}
                        </div>

                        <div className="form-group px-3 py-2 text-white">
                          <label htmlFor="commentAccpt">
                            Comment on Accept or Reject
                          </label>
                          <textarea
                            className="form-control"
                            id="commentProp"
                            {...register("comment")}
                          ></textarea>
                          <div>
                            {errors.comment && (
                              <span className="text-warning">
                                {errors.comment.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group px-3 py-2 text-white">
                      <button
                        type="submit"
                        className="btn bg-gradient-success w-100 my-4 mb-2"
                        disabled={loading}
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                </>
              )}
          </div>
        </div>
      </div>
    </>
  );
};
