// import
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { proposalId, sendProposalToggle, updateProposalList } from "../../state/atom/proposal";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { toaster } from "../../state/atom/toaster";

// Component displaying a hold request confirmation.
export const SendProposal = () => {
  const [sendPropToggle, setSendProposalToggle] =
    useRecoilState(sendProposalToggle);
  const proposalID = useRecoilValue(proposalId);
  const setToasterStatus = useSetRecoilState(toaster);
  const setUpdateProposalList = useSetRecoilState(updateProposalList);
  const sendProposal = () => {
    Http.request(
      "patch",
      `${process.env.REACT_APP_API_URL}proposal/forward/${proposalID}`,
      null,
      apiHeaders()
    )
      .then((result: any) => {
        
        setToasterStatus({
          status: true,
          title: "Proposal",
          text: "Proposal sent successfully",
        });
        setSendProposalToggle(!sendPropToggle)
        setUpdateProposalList(true)
      })
      .catch((error: any) => {});
  };
  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal
        show={sendPropToggle}
        onHide={() => setSendProposalToggle(!sendPropToggle)}
      >
        <Modal.Header closeButton className="toaster-header ">
          <Modal.Title>Send Proposal</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to send this proposal!</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setSendProposalToggle(!sendPropToggle)}
          >
            Cancel
          </Button>
          <Button onClick={sendProposal} variant="primary">
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
