import { atom } from "recoil";
import { User } from "../../shared/user.interface";

export const usersListToggle = atom({
  key: "usersListToggle",
  default: false,
});

export const editUserToggle = atom({
  key: "editUserToggle",
  default: false,
});

export const addUserToggle = atom({
  key: "addUserToggle",
  default: false,
});

export const userId = atom({
  key: "userId",
  default: "",
});

export const associatedEmail = atom({
  key: "associatedEmail",
  default: "",
});

export const updateUserList = atom({
  key: "updateUserList",
  default: false,
});

export const profileDetails = atom({
  key: "profileDetails",
  default: {} as User,
});

export const userListClone = atom({
  key: "userListClone",
  dangerouslyAllowMutability: true,
  default: {
    data: {
      user: [],
    },
  },
});

export const userList = atom({
  key: "userList",
  dangerouslyAllowMutability: true,
  default: {
    data: {
      user: [],
    },
  },
});