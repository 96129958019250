import { atom } from "recoil";
import { IDashboardSummary } from "../../shared/dashboard.interface";

export const dashboardSummary = atom({
  key: "dashboardSummary",
  default: {} as IDashboardSummary,
});

export const dashboardSummaryOptimized = atom({
  key: "dashboardSummaryOptimized",
  default: {} as IDashboardSummary,
});

export const dashboardSummaryLoading = atom({
  key: "dashboardSummaryLoading",
  default: true,
});
