import { useRecoilState, useSetRecoilState } from "recoil";
import { Business, Company, State } from "../../shared/generic.interface";
import { requestId, requestList } from "../../state/atom/request";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  gisFeatureLayerFilter,
  gisFilterToggle,
  gisList,
  landSummary,
  landSummaryToggle,
  siteFilterState,
  updateGisFilter,
} from "../../state/atom/gis";
import { Link } from "react-router-dom";
import { getLandSummary } from "../../shared/gis";
import { mapCenter } from "../../state/atom/generic";
import { getUserDetails } from "../../utility/jwt";
import { JwtPayLoad } from "../../shared/jwt.interface";
import { ROLES } from "../../shared/role.constant";
import { capitalizeString } from "../../shared/generic.constant";
interface MisFilterFormInputs {
  businessName: string;
  companyName: string;
  state: string;
  district: string;
  city: string;
  village: string;
  site: string;
  surveyNo: string;
}
export const GisFilter = () => {
  const { user }: JwtPayLoad = getUserDetails();
  const [request, setRequestList] = useRecoilState(requestList);
  const setRequestId = useSetRecoilState(requestId);
  let [gisFeatureLayerFilterData, setGisFeatureLayerFilter] = useRecoilState(
    gisFeatureLayerFilter
  );
  const setLandSummary = useSetRecoilState(landSummary);
  const setUpdateGisFilter = useSetRecoilState(updateGisFilter);
  const setLandSummaryToggle = useSetRecoilState(landSummaryToggle);
  const [gis, setGisList] = useRecoilState(gisList);
  const [gisFilterData, setGisFilterData] = useState<any>([]);
  const [gisFilterDataClone, setGisFilterDataClone] = useState<any>([]);
  const setmapCenter = useSetRecoilState(mapCenter);
  const [gisClone, setGisClone] = useState<any>({});
  const [states, setState] = useState<State[]>([]);
  const [surveys, setSurvey] = useState<any[]>([]);
  const [villages, setVillage] = useState<any[]>([]);
  const [sites, setSite] = useState<State[]>([]);
  const [siteFilter, setSiteFilter] = useRecoilState(siteFilterState);
  const [business, setBusiness] = useState<Business[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const isStateUpdated = useRef(false);
  const [gisFltrToggle, setGisFilterToggle] = useRecoilState(gisFilterToggle);
  const validationSchema = Yup.object().shape({
    businessName: Yup.string().notRequired(),
    companyName: Yup.string().notRequired(),
    state: Yup.string().notRequired(),
    village: Yup.string().notRequired(),
    site: Yup.string().notRequired(),
    surveyNo: Yup.string().notRequired(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<MisFilterFormInputs>({
    resolver: yupResolver(validationSchema),
  });
  const businessName = watch("businessName");
  const companyName = watch("companyName");
  const state = watch("state");
  const site = watch("site");
  const surveyNo = watch("surveyNo");
  const village = watch("village");
  useEffect(() => {
    if (
      !isStateUpdated.current &&
      gis?.data?.request.length &&
      request?.data?.landRequest.length
    ) {
      setGisClone({ ...gis });
      const landSummary = getLandSummary(gis.data.request);
      setLandSummary(landSummary);
      isStateUpdated.current = true;
    }
  }, [gis, request, isStateUpdated, setLandSummary, setRequestId]);

  useEffect(() => {
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}land-parcel/filter-data`,
      null,
      apiHeaders()
    )
      .then((result: any) => {
        setGisFilterData(result.data.request);
        setGisFilterDataClone(result.data.request);
        createFilterData(result.data.request);
      })
      .catch((error: any) => {});
  }, [setGisFilterData]);

  const onSubmit = (data: MisFilterFormInputs) => {
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}land-parcel/gis`,
      {
        filterDataSet: {
          businessNames: data.businessName ? [data.businessName] : [],
          states: data.state ? [data.state] : [],
          companyNames: data.companyName ? [data.companyName] : [],
          sites: data.site ? [data.site] : [],
          surveyNos: data.surveyNo ? [data.surveyNo] : [],
          villages: data.village ? [data.village] : [],
        },
      },
      apiHeaders()
    )
      .then((result) => {
        console.log("gisFeatureLayerFilterData", gisFeatureLayerFilterData);
        setGisFeatureLayerFilter(gisFeatureLayerFilterData);
        setLandSummary(result?.data);
        setUpdateGisFilter(Math.floor(Math.random() * 1000000 + 1));
      })
      .catch((error) => {});
  };

  const [appliedFilters, setAppliedFilters] = useState({
    business: "",
    state: "",
    site: "",
    company: "",
    survey: "",
    village: "",
  });

  const filterData = (e: any, field: string) => {
    let filterData: any = gisFilterDataClone;
    if (e.target.value) {
      let filters = { ...appliedFilters };
      if (field === "business") {
        filters = { ...appliedFilters, business: e.target.value };
        setAppliedFilters(filters);
      }
      if (field === "state") {
        filters = { ...appliedFilters, state: e.target.value };
        setAppliedFilters(filters);
      }
      if (field === "site") {
        filters = { ...appliedFilters, site: e.target.value };
        setAppliedFilters(filters);
      }

      if (field === "company") {
        filters = { ...appliedFilters, company: e.target.value };
        setAppliedFilters(filters);
      }

      if (field === "village") {
        filters = { ...appliedFilters, village: e.target.value };
        setAppliedFilters(filters);
      }

      if (field === "survey") {
        filters = { ...appliedFilters, survey: e.target.value };
        setAppliedFilters(filters);
      }

      if (filters.business) {
        filterData = filterData.filter((request: any) => {
          return Number(request?.business?.id) === Number(filters.business);
        });
        gisFeatureLayerFilterData.BusinessName = filters.business;
        createFilterData(filterData, "business");
        setValue("businessName", filters.business);
      }

      if (filters.company) {
        filterData = filterData.filter((request: any) => {
          return Number(request?.company?.id) === Number(filters.company);
        });
        gisFeatureLayerFilterData.CompanyName = filters.company;
        createFilterData(filterData, "company");
        setValue("companyName", filters.company);
      }

      if (filters.state) {
        filterData = filterData.filter((request: any) => {
          return Number(request?.state?.id) === Number(filters.state);
        });
        gisFeatureLayerFilterData.State = filters.state;
        createFilterData(filterData, "state");
        setValue("state", filters.state);
      }

      if (filters.site) {
        filterData = filterData.filter((request: any) => {
          return request.site === filters.site;
        });
        gisFeatureLayerFilterData.Site = filters.site;
        createFilterData(filterData, "site");
        setValue("site", filters.site);
      }

      if (filters.survey) {
        filterData = filterData.filter((request: any) => {
          return request.surveyNo === filters.survey;
        });
        gisFeatureLayerFilterData.SurveyNo = filters.survey;
        createFilterData(filterData, "survey");
        setValue("surveyNo", filters.survey);
      }

      if (filters.village) {
        filterData = filterData.filter((request: any) => {
          return request.village === filters.village;
        });
        gisFeatureLayerFilterData.Village = filters.village;
        createFilterData(filterData, "village");
        setValue("village", filters.village);
      }
      setGisFilterData(filterData);
    }
  };

  const createFilterData = (data: any, skip: string = "") => {
    const businessData = [];
    if (skip !== "business") {
      for (const parcel of data) {
        if (parcel?.business) {
          const index = businessData.findIndex(
            (business) => business?.id === parcel?.business?.id
          );
          if (index === -1) {
            if (parcel?.business?.id) {
              businessData.push(parcel.business);
            }
          }
        }
      }
      setBusiness(businessData);
    }
    if (skip !== "company") {
      const companies = [];
      for (const parcel of data) {
        if (parcel?.company) {
          const index = companies.findIndex(
            (company) => company?.id === parcel?.company?.id
          );
          if (index === -1 && parcel.company) {
            if (parcel?.company?.id) {
              companies.push(parcel.company);
            }
          }
        }
      }
      setCompanies(companies);
    }

    if (skip !== "state") {
      const states = [];
      for (const parcel of data) {
        if (parcel?.state) {
          const index = states.findIndex(
            (state) => state.id === parcel?.state?.id
          );
          if (index === -1) {
            if (parcel?.state?.id) {
              states.push(parcel.state);
            }
          }
        }
      }
      setState(states);
    }

    if (skip !== "site") {
      const sites = [];
      for (const parcel of data) {
        const index = sites.findIndex((site) => site === parcel.site);
        if (index === -1) {
          if (parcel.site) {
            sites.push(parcel.site);
          }
        }
      }
      setSite(sites);
    }

    if (skip !== "survey") {
      const surveys = [];
      for (const parcel of data) {
        const index = surveys.findIndex((survey) => survey === parcel.surveyNo);
        if (index === -1) {
          if (parcel.surveyNo) {
            surveys.push(parcel.surveyNo);
          }
        }
      }
      setSurvey(surveys);
    }

    if (skip !== "village") {
      const villages = [];
      for (const parcel of data) {
        const index = villages.findIndex(
          (village) => village === parcel.village
        );
        if (index === -1) {
          if (parcel.village) {
            villages.push(parcel.village);
          }
        }
      }
      setVillage(villages);
    }
  };

  useEffect(() => {
    const event = {
      target: {
        value: siteFilter,
      },
    };
    setValue("site", siteFilter);
    filterData(event, "site");
  }, [setValue, siteFilter]);

  const resetGis = () => {
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}land-parcel/gis`,
      null,
      apiHeaders()
    )
      .then((result) => {
        setLandSummary(result?.data);
      })
      .catch((error) => {});
    setLandSummaryToggle(false);
    setGisList(gisClone);
    setGisFilterData(gisFilterDataClone);
    createFilterData(gisFilterDataClone);
    setGisFeatureLayerFilter({
      SurveyNo: "",
      Village: "",
      BusinessName: "",
      CompanyName: "",
      Site: "",
      State: "",
      Request_Id: "",
      abu_or_lem_user_id: user.role === ROLES.abu_spoc ? user.userId : "",
      le_spoc_user_id: user.role === ROLES.le_spoc ? user.userId : "",
      le_dh_user_id: user.role === ROLES.le_dh ? user.userId : "",
    });

    setAppliedFilters({
      business: "",
      state: "",
      site: "",
      company: "",
      survey: "",
      village: "",
    });
    reset();
  };

  useEffect(() => {
    return () => {
      setAppliedFilters({
        business: "",
        state: "",
        site: "",
        company: "",
        survey: "",
        village: "",
      });
    };
  }, []);

  return (
    <>
      <div className="box">
        <div
          className={
            "new-request-corner-btn gis-corner-btn align-self-end " +
            (!gisFltrToggle ? "new-request-corner-btn--collapsed" : "")
          }
          onClick={() => {
            setGisFilterToggle(true);
          }}
          id="new-request-corner-btn"
        >
          <Link
            to=""
            className="nav-link font-weight-bold d-flex text-white corner-btn"
          >
            <i className="material-icons opacity-10 pe-1">checklist</i>
            <span className="d-sm-inline d-none dashboard-icons p-1">
              GIS Filter&nbsp;
            </span>
          </Link>
        </div>
        <div
          className={
            "request-detail-init " +
            (!gisFltrToggle ? "request-detail-init--collapsed" : "")
          }
        >
          <div
            className="text-right panel-title sticky-heading"
            style={{ background: "#375db1" }}
          >
            <span style={{ color: "white", fontSize: "24px" }}>
              GIS Filters
            </span>
            <span
              className="close-btn"
              id="request-detail-close"
              onClick={() => setGisFilterToggle(!gisFltrToggle)}
            >
              &times;
            </span>
          </div>
          <div style={{ overflowY: "auto", maxHeight: "75vh" }}>
            <h6 className="mb-0 panel-header">
              Select the data for apply filter.
            </h6>
            <form className="text-white" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group px-3 py-2">
                <label htmlFor="inputBusiness">Business Name</label>
                <select
                  className="form-select"
                  aria-label="Default Business Name"
                  {...register("businessName")}
                  onChange={(event) => filterData(event, "business")}
                  value={businessName}
                >
                  <option value="" selected>
                    ---Business Name ---
                  </option>
                  {business
                    .sort((a: Business, b: Business) =>
                      a.name.localeCompare(b.name)
                    )
                    .map((unit: any, index: any) => {
                      return <option value={unit.id}>{unit.name}</option>;
                    })}
                </select>
              </div>
              <div className="form-group px-3 py-2">
                <label htmlFor="inputBusiness">Company Name</label>
                <select
                  className="form-select"
                  aria-label="Default Company Name"
                  {...register("companyName")}
                  onChange={(event) => filterData(event, "company")}
                  value={companyName}
                >
                  <option value="" selected>
                    ---Select Company Name---
                  </option>
                  {companies
                    .sort((a: Company, b: Company) =>
                      a.name.localeCompare(b.name)
                    )
                    .map((company: any, index: any) => {
                      return <option value={company.id}>{company.name}</option>;
                    })}
                </select>
              </div>
              <div className="form-group px-3 py-2">
                <label htmlFor="select-state">Select State</label>
                <select
                  className="form-select"
                  {...register("state")}
                  onChange={(event) => filterData(event, "state")}
                  value={state}
                >
                  <option value="">---Please Select State---</option>
                  {states
                    .sort((a: State, b: State) =>
                      a.stateName.localeCompare(b.stateName)
                    )
                    .map((state: any, index: any) => {
                      return (
                        <option value={state.id}>
                          {capitalizeString(state.stateName)}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="form-group px-3 py-2">
                <label htmlFor="select-city">Select Site</label>
                <select
                  className="form-control"
                  aria-label="Default City"
                  {...register("site")}
                  onChange={(event) => filterData(event, "site")}
                  value={site}
                >
                  <option value="" selected>
                    ---Select Site---
                  </option>
                  {sites
                    .sort((a: any, b: any) => a.localeCompare(b))
                    .map((site: any, index: any) => {
                      return <option value={site}>{site}</option>;
                    })}
                </select>
              </div>

              <div className="form-group px-3 py-2">
                <label htmlFor="select-state">Village</label>
                <select
                  className="form-control"
                  aria-label="Default City"
                  {...register("village")}
                  onChange={(event) => filterData(event, "village")}
                  value={village}
                >
                  <option value="" selected>
                    ---Select Village---
                  </option>
                  {villages
                    .sort((a: any, b: any) => a.localeCompare(b))
                    .map((village: any, index: any) => {
                      return <option value={village}>{village}</option>;
                    })}
                </select>
              </div>

              <div className="form-group px-3 py-2">
                <label htmlFor="select-survey-no">Select Survey No</label>
                <select
                  className="form-select"
                  aria-label="Default select-survey-no"
                  {...register("surveyNo")}
                  onChange={(event) => filterData(event, "survey")}
                  value={surveyNo}
                >
                  <option value="" selected>
                    ---Select Survey No---
                  </option>
                  {surveys.map((survey: any, index: any) => {
                    return <option value={survey}>{survey}</option>;
                  })}
                </select>
              </div>
              <div
                className="form-group d-flex  mt-3 mb-3"
                style={{ justifyContent: "space-evenly" }}
              >
                <button
                  type="button"
                  onClick={() => {
                    /* const landSummary = getLandSummary(gisClone.data.request);
                    setLandSummary(landSummary); */
                    resetGis();
                  }}
                  className="btn bg-gradient-primary-login  btn1"
                >
                  Reset FILTER
                </button>
                <button
                  type="submit"
                  className="btn bg-gradient-primary-login btn1"
                >
                  APPLY FILTER
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
