// Imports
import { useEffect, useState } from "react";
import SignupLayout from "../layout/signup-layout";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { Business } from "../shared/generic.interface";
import { Link } from "react-router-dom";
import { Http } from "../utility/http";
import Select from "react-select";
import { customStyles } from "../shared/landTypeStyle";
// Interfaces
interface SignupFormInputs {
  firstName: string;
  lastName: string;
  employeeId: string;
  email: string;
  password: string;
  businessUnit:  Array<{ value: string; label: string }>;
  phoneNumber?: string | undefined;
  confirmPassword: string;
  agreeTerms: boolean;
}

// This component show the sign up form
export const Signup = () => {
  // hooks and state
  const [loading, setLoading] = useState(false);
  const [accountSuccess, setAccountSuccess] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [businessUnit, setBusinessUnit] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Enter email").email("Enter valid e-mail"),
    password: Yup.string().required("Enter password"),
    confirmPassword: Yup.string()
      .required("Enter confirm password")
      .oneOf([Yup.ref("password")], "Your passwords do not match."),
    firstName: Yup.string().required("Enter first name"),
    lastName: Yup.string().required("Enter last name"),
    phoneNumber: Yup.string().test(
      "is-10-digits",
      "Phone Number must be 10 digits",
      (value) => !value || value.toString().length === 10
    ),
    businessUnit: Yup.array()
    .min(1, "Business Name is required")
    .required("Business Name is required"),
    employeeId: Yup.string().required("Enter employee Id"),
    agreeTerms: Yup.boolean().oneOf(
      [true],
      "Terms and Conditions are required"
    ),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await Http.request(
          "get",
          `${process.env.REACT_APP_API_URL}generic/business-unit`,
          null
        );
        const businessUnit = result.data.businessUnit.map((unit: any) => {
          return {
            value: unit.id,
            label: unit.name,
          };
        });
        setBusinessUnit(businessUnit);
      } catch (error) {
        // Handle or log the error here
      }
    };

    fetchData();
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SignupFormInputs>({
    resolver: yupResolver(validationSchema),
  });

  /**
  Handles the form submission when the user clicks the sign up button.
  This function performs an API call to sign up.
  @param {SignupFormInputs} data - The data representing the all the value of the inputs fields.
*/
  const onSubmit = (data: SignupFormInputs) => {
    setLoading(!loading);
    if (serverErrorMessage) {
      setServerErrorMessage("");
    }

    const cleanedData = removeEmptyValues(data);
    cleanedData.businessUnit = cleanedData.businessUnit.map((unit: any) => {
      return unit.value;
    });
    //return;

    // Make POST request for sign up user.
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}auth/sign-up`,
      cleanedData
    )
      .then((res) => {
        setLoading(false);
        setAccountSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        // Set an error message if the API request fails
        setServerErrorMessage(err.response.data.message);
      });
  };

  const removeEmptyValues = (obj: any) => {
    const newObj = { ...obj };
    for (const key in newObj) {
      if (
        newObj[key] === "" ||
        newObj[key] === null ||
        newObj[key] === undefined
      ) {
        delete newObj[key];
      }
    }
    return newObj;
  };

  return (
    <SignupLayout>
      <main className="main-content  mt-0">
        <section>
          <div className="page_header min-vh-100">
            <div className="container">
              <div className="row">
                <div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column ">
                  <div className="position-relative  h-100  px-7 border-radius-lg d-flex flex-column d-flex justify-content-center page-header col6"></div>
                </div>
                <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                  {!accountSuccess && (
                    <div className="card card-plain card2">
                      <div className="card-header">
                        <h4 className="font-weight-bolder">Sign Up</h4>

                        {serverErrorMessage && (
                          <span className="mt-2 mb-0 text-center text-danger">
                            {serverErrorMessage}
                          </span>
                        )}
                      </div>
                      <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="row">
                            {/* First Name */}
                            <div className="col-lg-6">
                              <div className="input-group input-group-outline">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name*"
                                  {...register("firstName")}
                                />
                              </div>
                              <div className="mb-3">
                                {errors.firstName && (
                                  <span className="text-danger">
                                    {errors.firstName.message}
                                  </span>
                                )}
                              </div>
                            </div>

                            {/* Last Name */}
                            <div className="col-lg-6">
                              <div className="input-group input-group-outline ">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name*"
                                  {...register("lastName")}
                                />
                              </div>
                              <div className="mb-3">
                                {errors.lastName && (
                                  <span className="text-danger">
                                    {errors.lastName.message}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* Phone Number */}

                          <div className="input-group input-group-outline">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Phone Number"
                              {...register("phoneNumber")}
                            />
                          </div>
                          <div className="mb-3">
                            {errors.phoneNumber && (
                              <span className="text-danger">
                                {errors.phoneNumber.message}
                              </span>
                            )}
                          </div>

                          {/* Email */}
                          <div className="input-group input-group-outline">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email*"
                              {...register("email")}
                            />
                          </div>
                          <div className="mb-3">
                            {errors.email && (
                              <span className="text-danger">
                                {errors.email.message}
                              </span>
                            )}
                          </div>

                          {/* Password */}
                          <div className="input-group input-group-outline">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              placeholder="Password*"
                              autoComplete="new-password"
                              {...register("password")}
                            />
                            <span
                              className="input-group-text eye-icon"
                              onClick={togglePasswordVisibility}
                            >
                              <i
                                className={`fa fa-eye${
                                  showPassword ? "-slash" : ""
                                }`}
                              ></i>
                            </span>
                          </div>
                          <div className="mb-3">
                            {errors.password && (
                              <span className="text-danger">
                                {errors.password.message}
                              </span>
                            )}
                          </div>

                          {/* Confirm password */}
                          <div className="input-group input-group-outline">
                            <input
                              type={showConfirmPassword ? "text" : "password"}
                              className="form-control"
                              placeholder="Confirm Password*"
                              autoComplete="new-password"
                              {...register("confirmPassword")}
                            />
                            <span
                              className="input-group-text eye-icon"
                              onClick={toggleConfirmPasswordVisibility}
                            >
                              <i
                                className={`fa fa-eye${
                                  showConfirmPassword ? "-slash" : ""
                                }`}
                              ></i>
                            </span>
                          </div>
                          <div className="mb-3">
                            {errors.confirmPassword && (
                              <span className="text-danger">
                                {errors.confirmPassword.message}
                              </span>
                            )}
                          </div>

                          {/* Employee ID */}
                          <div className="input-group input-group-outline">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Employee ID*"
                              {...register("employeeId")}
                            />
                          </div>
                          <div className="mb-3">
                            {errors.employeeId && (
                              <span className="text-danger">
                                {errors.employeeId.message}
                              </span>
                            )}
                          </div>

                          {/* Business  */}
                          <div className="form-group  input-group-outline">
                            {/* <select
                              className="form-select"
                              aria-label="Default Select Business Name"
                              {...register("businessUnit")}
                            >
                              <option value="">
                                ---Please Select Business Name---
                              </option>
                              {businessUnit.map((unit: Business, index) => {
                                return (
                                  <option key={unit.id} value={unit.id}>
                                    {unit.name}
                                  </option>
                                );
                              })}
                            </select> */}
                            <Controller
                              name="businessUnit"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  isMulti
                                  options={businessUnit}
                                  className="basic-multi-select"
                                  placeholder="Select Business Name"
                                  classNamePrefix="select"
                                  styles={customStyles}
                                />
                              )}
                            />
                          </div>
                          <div className="mb-3">
                            {errors.businessUnit && (
                              <span className="text-danger">
                                {errors.businessUnit.message}
                              </span>
                            )}
                          </div>

                          {/* Terms and Conditions */}
                          <div className="form-check form-check-info text-start ps-0 form_cheak">
                            <input
                              className="form-check-input cheak-box"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              {...register("agreeTerms")}
                            />
                            <label className="form-check-label">
                              I agree the
                              <a
                                href="/#"
                                className="text-dark font-weight-bolder"
                              >
                                Terms and Conditions
                              </a>
                            </label>
                          </div>
                          <div className="mb-3">
                            {errors.agreeTerms && (
                              <span className="text-danger">
                                {errors.agreeTerms.message}
                              </span>
                            )}
                          </div>

                          {/* Sign Up button */}
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0 btn1"
                              disabled={loading}
                            >
                              {loading ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                "Sign Up"
                              )}
                            </button>
                          </div>
                        </form>
                      </div>

                      {/* Sign in button */}
                      <div className="card-footer text-center pt-0 px-lg-2 px-1">
                        <p className="mb-2 text-sm mx-auto">
                          Already have an account?
                          <Link
                            to="/"
                            className="text-primary text-gradient font-weight-bold"
                          >
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </div>
                  )}

                  {/* Account creation message */}
                  {accountSuccess && (
                    <div className="card card-plain card2">
                      <div className="card-header">
                        <h4 className="font-weight-bolder">Sign Up</h4>
                        <p className="mb-0 Card-p">
                          Your Account is created successfully. Please contact
                          to administrator for account verification.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </SignupLayout>
  );
};
