import ReactECharts from "echarts-for-react";
import { MainLayout } from "../layout/main-layout";
import { selectedMenu } from "../state/atom/generic";
import { Menu, capitalizeString, fixNumber } from "../shared/generic.constant";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { Http } from "../utility/http";
import { apiHeaders } from "../utility/generic";
import { DashboardFilter } from "../component/dashboard/dashboard-filter";
import { profileDetails } from "../state/atom/user";
import { useNavigate } from "react-router-dom";
import { gisList } from "../state/atom/gis";
import {
  dashboardSummaryLoading,
  dashboardSummaryOptimized,
} from "../state/atom/dashboard";
import { validatePermission } from "../utility/permission";
import { getDashBoardSummary } from "../shared/dashboard";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ReactApexChart from "react-apexcharts";

const Dashboard = () => {
  validatePermission("menu", "dashboard");
  const navigate = useNavigate();
  const [gis, setGisList] = useRecoilState(gisList);
  const setSelectedMenu = useSetRecoilState(selectedMenu);
  setSelectedMenu(Menu.dashboard);
  const myProfileDetails = useRecoilValue(profileDetails);
  //const dashboardSummaryData = useRecoilValue(dashboardSummary);
  const [dashboardSummaryData, setDashboardSummaryData] = useRecoilState(
    dashboardSummaryOptimized
  );
  const [dashboardSummaryLoader, setDashboardSummaryLoader] = useRecoilState(
    dashboardSummaryLoading
  );
  useEffect(() => {
    const { dashboardAccess } = myProfileDetails;
    if (!dashboardAccess) {
      navigate("/home");
    }
  }, [myProfileDetails, navigate]);
  //*Bar chart options
  const [barchartoptions, setBarChartOption] = useState<any>(null);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [donutSize, setdonutSize] = useState("55%");
  const [labelSize, setLabelSize] = useState(11);
  useEffect(() => {
    if (windowSize > 1800) {
      setdonutSize("55%");
      setLabelSize(11);
    } else {
      setdonutSize("50%");
      setLabelSize(9);
      console.log("labelSize", labelSize);
    }
  }, [labelSize, windowSize]);
  const wrapText = (text: string, maxLineLength: number) => {
    let words = text.split(" ");
    let wrappedText = "";
    let line = "";

    words.forEach((word) => {
      if ((line + word).length > maxLineLength) {
        wrappedText += line + "\n";
        line = word + " ";
      } else {
        line += word + " ";
      }
    });

    wrappedText += line.trim();

    return wrappedText;
  };
  useEffect(() => {
    const labels = ["Total Conveyance Deed ", "Current Year"];
    const labelData = [
      {
        value: fixNumber(dashboardSummaryData?.saleDeedGrapData?.totalSaleDeed),
        itemStyle: {
          color: "#e97132",
        },
      },
      {
        value: fixNumber(
          dashboardSummaryData?.saleDeedGrapData?.currentYearSaleDeed
        ),
        itemStyle: {
          color: "#0f9ed5",
        },
      },
    ];

    if (dashboardSummaryData?.saleDeedGrapData?.filterdDateSaleDeed >= 0) {
      labels.push("Filter Value");
      labelData.push({
        value: fixNumber(
          dashboardSummaryData?.saleDeedGrapData?.filterdDateSaleDeed
        ),
        itemStyle: {
          color: "#4ea72e",
        },
      });
    } else {
      labels.push("Last Three Month");
      labels.push("Current Month");
      labelData.push({
        value: fixNumber(
          dashboardSummaryData?.saleDeedGrapData?.last3MonthSaleDeed
        ),
        itemStyle: {
          color: "#4ea72e",
        },
      });

      labelData.push({
        value: fixNumber(
          dashboardSummaryData?.saleDeedGrapData?.currentMonthSaleDeed
        ),
        itemStyle: {
          color: "#156082",
        },
      });
    }
    const barchartData = {
      grid: { top: 20, right: 40, bottom: 20, left: 40, containLabel: true },
      xAxis: {
        data: labels,
        axisLabel: {
          formatter: (value: any) => wrapText(value, 5),
          rotate: 360,
          color: "black",
          fontSize: labelSize,
        },
      },
      yAxis: {
        axisLabel: {
          color: "black",
        },
        axisLine: {
          lineStyle: {
            type: "dashed",
          },
        },
        //type: "log",
      },
      series: [
        {
          data: labelData,
          type: "bar",
          name: "Area in Acres",
          barWidth: "50%",
          barCategoryGap: "100%",
          color: "#1CA6EF",
        },
      ],
      tooltip: {
        trigger: "axis",
      },
    };
    setBarChartOption(barchartData);
  }, [dashboardSummaryData, labelSize]);

  const [businessSiteChart, setBusinessSiteChart] = useState<any>(null);
  const [businessSaleDeedChart, setBusinessSaleDeedChart] = useState<any>(null);
  useEffect(() => {
    console.log("dashboardSummaryData", dashboardSummaryData);
    ChartJS.register(ArcElement, Tooltip, Legend);

    let businessName = [];
    let businessCount = [];
    if (dashboardSummaryData?.activeBusinesssDashboard?.length) {
      businessName = dashboardSummaryData.activeBusinesssDashboard.map(
        (summry: any) => summry.name
      );
      businessCount = dashboardSummaryData.activeBusinesssDashboard.map(
        (summry: any) => summry.count
      );
    }
    const bdata = {
      series: businessCount,
      options: {
        chart: {
          type: "donut",
        },
        plotOptions: {
          pie: {
            donut: {
              size: donutSize,
            },
          },
        },
        labels: businessName,
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          // @ts-ignore: Unreachable code error
          formatter: function (val, opts) {
            return opts.w.globals.labels[opts.seriesIndex].length > 7
              ? opts.w.globals.labels[opts.seriesIndex].substring(0, 7) + "..."
              : opts.w.globals.labels[opts.seriesIndex];
          },
          offsetY: 30,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        legend: {
          show: false,
        },
      },
    };
    setBusinessSiteChart(bdata);

    let cbusinessName = [];
    let businessArea = [];
    if (dashboardSummaryData?.activeBusiness?.length) {
      cbusinessName = dashboardSummaryData.activeBusiness.map(
        (summry: any) => summry.name
      );
      businessArea = dashboardSummaryData.activeBusiness.map((summry: any) =>
        Number(summry.area.toFixed(2))
      );
    }
    const data = {
      series: businessArea,
      options: {
        chart: {
          type: "donut",
        },
        labels: cbusinessName,
        plotOptions: {
          pie: {
            donut: {
              size: donutSize,
            },
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          // @ts-ignore: Unreachable code error
          formatter: function (val, opts) {
            return opts.w.globals.labels[opts.seriesIndex].length > 7
              ? opts.w.globals.labels[opts.seriesIndex].substring(0, 7) + "..."
              : opts.w.globals.labels[opts.seriesIndex];
          },
          offsetY: 30,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        legend: {
          show: false,
        },
      },
    };

    setBusinessSaleDeedChart(data);
  }, [dashboardSummaryData, donutSize]);

  useEffect(() => {
    Http.request(
      "get",
      `${process.env.REACT_APP_API_URL}land-request`,
      null,
      apiHeaders()
    )
      .then((result) => {
        const dashBoardSummary = getDashBoardSummary(result.data.landRequest);
        console.log(dashBoardSummary);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    Http.request(
      "post",
      `${process.env.REACT_APP_API_URL}land-parcel/optimized`,
      null,
      apiHeaders()
    )
      .then((result) => {
        //setGisList(result);
        setDashboardSummaryData(result.data);
        setDashboardSummaryLoader(false);
      })
      .catch((error) => {});
  }, [setGisList]);

  useEffect(() => {
    document.body.classList.add("no-scroll");

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const businessPopover = (
    <Popover id="popover-positioned-right" title="Popover right">
      {/* {requestStatics.totalBusiness.join(", ")} */}
      <ul className="popover-container">
        {dashboardSummaryData?.activeBusinesssDashboard?.map(
          (business: any) => {
            return <li>{business.name}</li>;
          }
        )}
      </ul>
    </Popover>
  );

  const statePopover = (
    <Popover id="popover-positioned-right" title="Popover right">
      <ul className="popover-container">
        {dashboardSummaryData?.totalStatesDashboard?.map((state: any) => {
          return <li>{capitalizeString(state.name)}</li>;
        })}
      </ul>
    </Popover>
  );
  const sitePopover = (
    <Popover id="popover-positioned-right" title="Popover right">
      <ul className="popover-container">
        {dashboardSummaryData?.totalSitesDashboard?.map((site: any) => {
          return <li>{site.name}</li>;
        })}
      </ul>
    </Popover>
  );

  const completedSitePopover = (
    <Popover id="popover-positioned-right" title="Popover right">
      <ul className="popover-container">
        {dashboardSummaryData?.totalCompletedSite?.map((site: any) => {
          return <li>{site.name}</li>;
        })}
      </ul>
    </Popover>
  );

  return (
    <MainLayout>
      {dashboardSummaryLoader && (
        <div className="dashboard-loader-container">
          <div className="dashboard-loader"></div>
        </div>
      )}

      <div className="container-fluid main-content full-layout-content dashboard-block">
        <div className="dashboard-widget" style={{ height: "32vh" }}>
          <div className="row  mt-1 dashboard-card">
            <div className="col-xl-3 colcustom-4">
              <div className="card card-stats mb-4 mb-xl-0">
                <div className="card-body">
                  <div className="d-flex align-items-center ">
                    <div className="col widget-content">
                      <p className="card-title  text-muted mb-0">Business</p>
                      <span className="action-btn">
                        <OverlayTrigger
                          trigger="click"
                          placement="right"
                          overlay={businessPopover}
                          rootClose
                        >
                          <Button>
                            {
                              dashboardSummaryData?.activeBusinesssDashboard
                                ?.length
                            }
                          </Button>
                        </OverlayTrigger>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 colcustom-4">
              <div className="card card-stats mb-4 mb-xl-0">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="col widget-content">
                      <p className="card-title text-muted mb-0">State</p>
                      <span className="action-btn">
                        <OverlayTrigger
                          trigger="click"
                          placement="right"
                          overlay={statePopover}
                          rootClose
                        >
                          <Button>
                            {dashboardSummaryData?.totalStatesDashboard?.length}
                          </Button>
                        </OverlayTrigger>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 colcustom-4">
              <div className="card card-stats mb-4 mb-xl-0 ">
                <div className="card-body">
                  <div className="d-flex align-items-center ">
                    <div className="col widget-content">
                      <p className="card-title  text-muted mb-0">Site</p>
                      <span className="action-btn">
                        <OverlayTrigger
                          trigger="click"
                          placement="right"
                          overlay={sitePopover}
                          rootClose
                        >
                          <Button>
                            {dashboardSummaryData?.totalSitesDashboard?.length}
                          </Button>
                        </OverlayTrigger>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 colcustom-4">
              <div className="card card-stats mb-4 mb-xl-0">
                <div className="card-body">
                  <div className="d-flex align-items-center ">
                    <div className="col widget-content">
                      <p className="card-title  text-muted mb-0">
                        Completed Site
                      </p>
                      <span className="action-btn">
                        <OverlayTrigger
                          trigger="click"
                          placement="left"
                          overlay={completedSitePopover}
                          rootClose
                        >
                          <Button>
                            {dashboardSummaryData?.totalCompletedSite?.length}
                          </Button>
                        </OverlayTrigger>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-xl-3 colcustom-4 col-box">
              <div className="card card-stats  mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col widget-content">
                      <h5 className="card-title font-weight-bold text-muted mb-0">
                        Total Area Required <br></br>(Acre)
                      </h5>
                      <span className="mb-0 blue-color widget-desc">
                        {fixNumber(dashboardSummaryData.totalAreaRequired)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 colcustom-4 col-box">
              <div className="card card-stats  mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col widget-content">
                      <h5 className="card-title font-weight-bold text-muted mb-0">
                        Total Conveyance Deed <br></br> (Acre)
                      </h5>
                      <span className="mb-0 widget-desc">
                        {/* Completed:&nbsp; */}
                        <span>
                          {fixNumber(dashboardSummaryData.totalSalesDeed)}
                        </span>
                        {/* <br></br>
                          &nbsp;Pending:&nbsp;
                          <span className="blue-color">
                            {fixNumber(
                              dashboardSummaryData.totalPendingSalesDeed
                            )}
                          </span> */}
                      </span>
                      {/* <span className="mb-0">
                          Pending:&nbsp;
                          {fixNumber(
                            dashboardSummaryData.totalPendingSalesDeed
                          )}
                        </span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 colcustom-4 col-box">
              <div className="card card-stats mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col widget-content">
                      <h5 className="card-title font-weight-bold  text-muted mb-0">
                        Total Mutation <br></br> (Acre)
                      </h5>
                      <span className="mb-0 widget-desc">
                        {/* Completed:&nbsp; */}
                        <span>
                          {fixNumber(dashboardSummaryData.totalMutationStatus)}
                        </span>
                        {/* <br></br>
                          &nbsp; Pending:&nbsp;
                          <span className="blue-color">
                            {fixNumber(
                              dashboardSummaryData.totalPendingMutationStatus
                            )}
                          </span> */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 colcustom-4 col-box">
              <div className="card card-stats mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col widget-content">
                      <h5 className="card-title font-weight-bold text-muted mb-0">
                        Total NA <br></br>(Acre)
                      </h5>
                      <span className="mb-0 widget-desc">
                        {/*  Completed:&nbsp; */}
                        <span>
                          {fixNumber(dashboardSummaryData.totalNAStatus)}
                        </span>
                        <br></br>
                        {/* &nbsp;Pending:&nbsp;
                          <span className="blue-color">
                            {fixNumber(
                              dashboardSummaryData.totalPendingNAStatus
                            )}
                          </span> */}
                      </span>
                      {/* <span className="mb-0">
                          Pending:&nbsp;
                          {fixNumber(dashboardSummaryData.totalPendingNAStatus)}
                        </span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <DashboardFilter></DashboardFilter>
          </div>
        </div>
        <div
          className="row"
          style={{
            height: "calc(59vh - 20px)",
            justifyContent: "space-between",
          }}
        >
          <div className="chart-box chart-box-donut">
            <h6 className="mb-0 chart-heading">
              Businesswise Sites <span>(Sites in Nos.)</span>
            </h6>
            {businessSiteChart && (
              <>
                {businessSiteChart?.series.every((d: any) => d === 0) ? (
                  <div className="chart-wrapper">
                    <div className="donut-bg-1 no-data">
                      <p></p>
                      <p>No Data Found</p>
                      <p></p>
                    </div>
                  </div>
                ) : (
                  <div className="chart-wrapper">
                    <ReactApexChart
                      options={businessSiteChart?.options}
                      series={businessSiteChart?.series}
                      type="donut"
                    />
                    <div className="donut-bg-1">
                      <p>adani</p>
                      <p>Businesswise</p>
                      <p>Sites</p>
                      <p>ALMS</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          <div
            className="chart-box"
            style={{
              height: "100%",
              border: "solid 2px #000",
              background: "#fff",
            }}
          >
            <h6 className="mb-0 chart-heading ">Conveyance Deed Status</h6>
            {barchartoptions && (
              <ReactECharts
                option={barchartoptions}
                style={{ height: "90%", background: "#fff" }}
              />
            )}
          </div>
          <div className="chart-box chart-box-donut">
            <h6 className="mb-0 chart-heading">
              Businesswise Conveyance Deed <span>(Area in Acre)</span>
            </h6>

            {businessSaleDeedChart && (
              <>
                {businessSaleDeedChart?.series.every((d: any) => d === 0) ? (
                  <div className="chart-wrapper">
                    <div className="donut-bg-2 no-data">
                      <p></p>
                      <p>No Data Found</p>
                      <p></p>
                    </div>
                  </div>
                ) : (
                  <div className="chart-wrapper">
                    <ReactApexChart
                      options={businessSaleDeedChart?.options}
                      series={businessSaleDeedChart?.series}
                      type="donut"
                    />
                    <div className="donut-bg-2">
                      <p>adani</p>
                      <p>Businesswise</p>
                      <p>Conveyance Deed</p>
                      <p>ALMS</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Dashboard;
