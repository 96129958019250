import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { businessUnitIdState, editBusinessUnitModalToggle } from "../../../../state/atom/settings";
import { toaster } from "../../../../state/atom/toaster";
import { apiHeaders } from "../../../../utility/generic";
import { Http } from "../../../../utility/http";
import * as Yup from 'yup';

type EditBusinessUnitForm = {
  name: string;
}

export const EditBusinessUnitModal = () => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useRecoilState(editBusinessUnitModalToggle);
  const setToasterStatus = useSetRecoilState(toaster);
  const businessUnitId = useRecoilValue(businessUnitIdState);

  useEffect(() => {
    const getBusinessUnit = async () => {
      await Http.request(
        "get",
        `${process.env.REACT_APP_API_URL}business-units/${businessUnitId}`,
        apiHeaders()
      )
        .then((res: any) => {
          // console.log(res);
          const unit = res.data.businessUnit
          setValue("name", unit.name);
        })
        .catch((error: any) => {
          console.error(error);
        });
    };
    getBusinessUnit();
  }, []);

  const editBusinessUnitValidationSchema = Yup.object().shape({
    name: Yup.string().required("Business unit name is required."),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<EditBusinessUnitForm>({
    resolver: yupResolver(editBusinessUnitValidationSchema),
  });

  const onSubmitHandler = async (data: EditBusinessUnitForm) => {
    setLoading(true);
    await Http.request(
      "put",
      `${process.env.REACT_APP_API_URL}business-units/${businessUnitId}`,
      data,
      apiHeaders()
    )
      .then((result: any) => {
        console.log(result.message);
        setToasterStatus({
          status: true,
          title: "Business-Unit Updated.",
          text: result.message,
        });
        setLoading(false);
        handleClose();
      })
      .catch((error: any) => {
        setSubmitErrorMessage(error.response.data.message);
        setLoading(false);
      });
  };

  
  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header
          className="panel-title"
          style={{ marginBottom: 0 }}
          closeButton
          closeVariant="white"
        >
          <Modal.Title className="text-white">Edit Business-Unit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            {submitErrorMessage && (
              <div className="text-center py-1">
                <span className="text-danger">{submitErrorMessage}</span>
              </div>
            )}
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="d-flex flex-column">
                <div className="form-group px-3 py-2">
                  <div style={{ color: "red" }}>
                    &#42;
                    <label
                      className="form-label"
                      htmlFor="inputStype"
                      style={{ color: "black" }}
                    >
                      Name
                    </label>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    id="stateName"
                    placeholder="Enter city"
                    {...register("name")}
                  />
                  {errors.name && (
                    <span className="text-danger">{errors.name.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group px-3 py-2">
                <button
                  type="submit"
                  className="btn bg-gradient-primary-login btn1 w-100"
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
